<template>
    <div id="signup_info">
        <h2 class="myo_table_tit">My Academy</h2>
        <ul id="myo_form">
            <li class="item">
                <span class="item_title">Academy</span>
                <span class="item_content">
                    <vSelect :options="campus_list" label="cnt_name" :reduce="value => value.cnt_cd" :clearable="false" v-model="campus"></vSelect>
                </span>
            </li>
        </ul>

      <div class="myo_condition_search2">
        <button type="button" onclick="location.replace('/MyPersonInfo');"  class="reset_btn">{{ i18n.myoffice.Goback }}</button>
        <button type="button" @click="modify" class="search_btn">{{ i18n.myoffice.save }}</button>
      </div>

    </div>

</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueSimpleAlert from "vue3-simple-alert";
import modalView from "../../modalView";
import Loading from "vue3-loading-overlay";
import alert from "vue3-simple-alert";
export default {
    name: "MyMemberAcademyEdit",
    components : {
      vSelect,
      VueSimpleAlert,
      modalView,
      Loading
    },
    data: () => ({
        i18n: window.i18n,
        aproval: "",
        campus : "",
        campus_list : "",
    }),
    beforeCreate() {
      axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        this.mem = response.data;
        this.campus = response.data.cnt_cd;
        this.campus_list = response.data.campus_list;
      });
    },
    methods: {
      modify(){
        this.loading = true;
        axios.post(window.Laravel.back_url+'/api/editAcademyMemInfo', {
            cnt_cd : this.campus,
          }
          , {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
          if(response.data.result == "1"){
            VueSimpleAlert.alert(response.data.RETSTR,"","",{allowOutsideClick : false}).then((result) => {
              if(result){
                location.replace("/MyPersonInfo");
              }
            });
          }

          if(response.data.result == "0"){
            VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
              if(result){
              }
            });
          }

          this.loading = false;
        });
      },
    }
}
</script>

<style scoped>

</style>
