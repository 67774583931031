<template>
    <dl class="myo_search_list">
        <dt>{{ search_name }}</dt>
        <dd><input type="text" name="down_name" id="down_name" v-bind="down_name"  :placeholder="search_name"></dd>
    </dl>

</template>

<script>
export default {
    name: "nameForm",
    data: () => ({
        i18n: window.i18n,
    }),
    props : {
        down_name : String,
    },
    created() {
      const this_page = window.location.pathname;
      if(this_page === '/MyMemberLegBox') {
        this.search_name = i18n.myoffice.MemberId;
      } else {
        this.search_name = i18n.myoffice.memberName;
      }
    }
}
</script>

<style scoped>

</style>
