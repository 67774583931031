<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="fixed z-50 sm:relative w-full h-auto mx-auto relative">
    <!--  상단 뒤로가기 버튼  -->
    <div class="fixed lg:hidden z-[1]" :class="{ 'text-black': isLoggedIn }" v-if=" isNotHome ">
      <ArrowCircleLeftIcon class="bg-gray-400 text-white opacity-50 rounded-full block h-6 w-6 mx-1" aria-hidden="true" @click=" historyBack " />
    </div>
    <div class="w-full flex justify-center py-1 bg-gray-100 text-xs lg:text-sm" v-if=" isLoggedIn ">
      <UserCircleIcon class="block h-4 w-4 mx-1" aria-hidden="true" />
      <a href="/MyPersonInfo">{{ user }} ({{ username }}) Welcome</a>
    </div>
    <Disclosure as="nav" class="bg-gray-800 py-0" v-slot="{ open }">
      <div class="max-w-7xl mx-auto lg:px-8">
        <div class="relative flex items-center justify-between h-16">
          <div class="absolute inset-y-0 left-0 flex items-center lg:mt-4 lg:hidden">
            <!-- Mobile menu button-->
            <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div class="flex-1 items-stretch justify-start lg:mt-4 ml-10 sm:ml-4">
            <div class="flex-shrink-0 flex items-center">
              <a href="/">
                <img class="block lg:hidden h-4 w-auto" src="/images/img_logo_100.svg" alt="Workflow" />
                <img class="hidden lg:block h-6 w-auto" src="/images/img_logo.svg" alt="Workflow" />
              </a>
            </div>
          </div>

          <div class="inset-y-0 right-0 flex items-center pr-4 sm:static sm:inset-auto sm:ml-10 sm:pr-0">
            <Menu as="div" class="flex lg:relative static">
              <transition class="top-18 bg-gray-800 lg:relative" v-show=" isActiveSearch ">
                  <div class="w-full flex justify-center text-white lg:rounded-2xl lg:border-2 lg:relative lg:top-0 lg:py-0 absolute top-16 right-0 py-1.5 px-2.5">
                  <form name="search_form" method="post" class="w-full" v-on:submit.prevent=" search_product ">
                    <input type="hidden" name="_token" :value="csrf">
                    <input type="search" ref="searchInput" class="w-11/12 bg-gray-800 text-white text-sm sm:text-base sm:ml-2 pt-1.5 pb-1 border-1 border-white rounded-lg lg:border-transparent lg:rounded-0 lg:border-0 lg:rounded-none lg:w-full" name="keyword" placeholder="Input keywords for searching products">
                  </form>
                  <button @click=" search_product "><SearchIcon class="h-6 sm:h-8 w-6 sm:w-8" aria-hidden="true" /></button>
                </div>
              </transition>
              <MenuButton type="button" @click="isActiveSearch = !isActiveSearch;this.$refs.searchInput.focus();"
                          class="relative sm:block bg-gray-800 ml-8 flex text-sm rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span class="absolute text-xs mt-6 sm:mt-8 -ml-2 lg:-ml-5">Search</span>
                <SearchIcon class="h-6 sm:h-8 w-6 sm:w-8" aria-hidden="true" />
              </MenuButton>
            </Menu>
            <!-- 국가선택 - 로그인 시에만 노출됨 -->
            <div v-if=" isLoggedIn ">

            </div>
            <Menu as="div" class="relative" v-else>
              <MenuButton type="button"
                          class="sm:block bg-gray-800 ml-8 flex text-sm rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span class="absolute text-xs mt-6 sm:mt-8 -ml-2 lg:-ml-6">Country</span>
                <GlobeIcon class="h-6 sm:h-8 w-6 sm:w-8" aria-hidden="true" />
              </MenuButton>
              <transition class="z-10" enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5  z-10 focus:outline-none left-[-30px] mt-5">
                  <MenuItem v-slot="{ active }">
                    <a href="https://vcshop.gcoop.com/kr" target="_blank" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">KOREA</a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a href="https://vcshop.gcoop.com/us" target="_blank" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">USA</a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a href="https://vcshop.gcoop.com/tw" target="_blank" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">TAIWAN</a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a href="https://vcshop.gcoop.com/jp" target="_blank" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">JAPAN</a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a href="https://vcshop.gcoop.com/id" target="_blank" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">INDONESIA</a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a href="https://my.gcoop.com/" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">MALAYSIA</a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a href="https://sg.gcoop.com/" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">SINGAPORE</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>

            <button type="button" @click='announceLink'
                    class="relative hidden lg:block bg-gray-800 ml-8 flex text-sm rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
              <span class="absolute text-xs mt-6 sm:mt-8 -ml-2 lg:-ml-6">Announce</span>
              <BellIcon class="h-6 sm:h-8 w-6 sm:w-8" aria-hidden="true" />
            </button>
            <!--                    <button type="button" class="bg-gray-800 p-2 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">-->
            <!--                        <span class="sr-only">View myoffice</span>-->
            <!--                        <font-awesome-icon class="h-6 w-6" icon="fas fa-user-chart" />-->
            <!--                    </button>-->
            <button type="button" v-if="isLoggedIn" @click='cartLink'
                    class="relative sm:block bg-gray-800 ml-8 flex text-sm rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
              <!--                        <span class="sr-only">View shopping-cart</span>-->
              <span class="absolute text-xs mt-6 sm:mt-8 ml-0 lg:-ml-3">Cart</span>
              <span class="absolute w-4 lg:w-6 bg-red-500 rounded-full text-white text-xs lg:text-sm -mt-1 ml-5 lg:ml-1">{{ countCartItems }}</span>
              <ShoppingCartIcon class="h-6 sm:h-8 w-6 sm:w-8" aria-hidden="true" />
            </button>
            <button v-if="isLoggedIn" type="button" @click='myofficeLink'
                    class="relative hidden sm:block bg-gray-800 ml-8 flex text-sm rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
              <span class="absolute text-xs mt-6 sm:mt-8 -ml-2 lg:-ml-6">MyOffice</span>
              <ChartSquareBarIcon class="h-6 sm:h-8 w-6 sm:w-8" aria-hidden="true" />
            </button>

                    <!-- Profile dropdown -->
                    <Menu as="div" class="ml-8 sm:mr-5 relative">
                        <div v-if=" isLoggedIn ">
                            <MenuButton class="relative bg-gray-800 flex text-sm rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                <UserIcon class="h-6 sm:h-8 w-6 sm:w-8" aria-hidden="true" />
                              <span class="absolute text-xs mt-6 md:mt-8 -ml-2 lg:-ml-0">Profile</span>
                                <!--                                <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />-->
                            </MenuButton>
                        </div>
                        <div v-else>
                            <button v-on:click.native='login' class="bg-gray-800 flex text-sm rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                <span class="absolute text-xs mt-6 md:mt-8 -ml-2 lg:-ml-0">Login</span>
                                <LoginIcon class="h-6 sm:h-8 w-6 sm:w-8" />
                                <!--                                <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />-->
                            </button>
                        </div>
                        <transition class="z-10" enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="origin-top-right absolute z-30 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <MenuItem v-slot="{ active }">
                                    <a href="#" @click="goAutoLogin('register','')" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Join Member</a>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                    <a href="#" @click="myofficeLink" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">My Office</a>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                    <a href="#" @click="mypageLink" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">My Page</a>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                    <a href="#" @click="logout" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Log out</a>
                                </MenuItem>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </div>
            <div class="hidden rounded mt-2 sm:block sm:mx-0 bg-gray-900">
                <div class="flex">
                    <a v-for="item in navigations" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
                </div>
            </div>
        </div>

      <DisclosurePanel class="lg:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1">
          <DisclosureButton v-for="item in navigations" :key="item.name" as="a" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</DisclosureButton>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { SearchIcon, GlobeIcon, BellIcon, ShoppingCartIcon, ChartSquareBarIcon, LoginIcon, UserIcon, MenuIcon, XIcon, UserCircleIcon, ArrowCircleLeftIcon } from '@heroicons/vue/outline'
import VueSimpleAlert from "vue3-simple-alert";


export default {
  name: "LayoutTop",
  props: ['product_category'],
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    SearchIcon,
    GlobeIcon,
    BellIcon,
    ShoppingCartIcon,
    ChartSquareBarIcon,
    UserIcon,
    LoginIcon,
    MenuIcon,
    XIcon,
    UserCircleIcon,
    ArrowCircleLeftIcon,
      VueSimpleAlert
  },
  setup() {
    return {
      // navigations,
    }
  },
  data: () => ({
    product_categorys: [],
    navigations: [],
    isLoggedIn: false,
    isNotHome: false,
    i18n: window.i18n,
    isActiveSearch: false,
    countCartItems: 0,
    star_check : window.Laravel.star_check,
    spa_mall_check : window.Laravel.spa_mall_check,
    isLoggedin : window.Laravel.isLoggedin,

    csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
  }),
  created() {
    // 카테고리 메뉴
      this.product_category.forEach((value, index) => {

          if(value.name === "StarMall" && this.star_check === false) {
            return ;
          } else if(value.name === "SpaMall" && this.spa_mall_check === false) {
            return ;
          }

          // 로그인한 회원만 접근이 가능
          if ( ('isLogin' in value) && value.isLogin === true && this.isLoggedin === false){
            return ;
          }

          console.log(value);
          this.navigations.push(value)
      });

      //this.navigations = this.product_category;
    //console.log(window.Laravel.previous_url);
  },
  mounted() {
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
      this.username = window.Laravel.username;
      // 장바구니 담긴 상품수
      if(this.isLoggedIn)  this.getCountCartItems();
    }
    if(window.Laravel.current_route !== 'home'){
      this.isNotHome = true;
    }
  },
  methods: {
    // searchFormOpen: function(event) {if(isActiveSearch)},
    historyBack: function(event) {history.back();},
    cartLink: function(event) {location.href = "/cart";},
    joinMember: function(event) {location.href = "https://signup.gcoop.com/AutoLogin?xid=";},
    mypageLink: function(event) {location.href = "/Mypage";},
    myofficeLink: function(event) {location.href = "/MyofficeMain";},
    announceLink: function(event) {location.href = "/Mypage/Notice";},
    login: function(event) {location.href = "/sso/login?bz_cd="+window.Laravel.bz_cd;},
    logout: function(event) {jslogout(); location.href = "/sso/logout";},
    // 장바구니 담긴 상품수
    getCountCartItems(){
      axios.post(process.env.MIX_BACK_URL+'/api/cartCount',{
        bz_cd: window.Laravel.bz_cd
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.countCartItems = (response.data);
        });
    },
      search_product(){
          let form = document.search_form;
          if(form.keyword.value == ""){
              VueSimpleAlert.alert("Please enter a search term","","",{});
          }else{
              form._token.value = this.csrf;
              form.method = 'POST';
              form.action = '/product/search';
              form.submit();
          }
    },
      goAutoLogin(mode, url) {
          window.open("https://signup.gcoop.com/AutoLogin?xid="+encodeURIComponent(window.Laravel.xid_info)+"&token="+window.Laravel.xid_info);

          // let config = {
          //     headers: {
          //         Authorization: 'Bearer ' + window.Laravel.access_token
          //     }
          // };
          //
          // axios.post(window.Laravel.back_url +'/api/goAutoLogin',{
          //     mode: mode,
          //     url: url,
          //     bz_cd: window.Laravel.bz_cd,
          // },config).then(response => {
          //     if(response.data.href_url != ""){
          //         window.open(response.data.href_url);
          //     }
          // }).catch(e => {
          // });
      },

  },
}
</script>
