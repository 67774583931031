<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <article class="myo_search_area">
        <form name='form_search' id="form_search"  v-on:submit.prevent="submitForm" style="clear:both;">
            <section class="myo_condition_box">
                <div class="relative z-50">
<!--                    <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date" ></dateForm>-->
                  <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date" @set_date_type="set_date_type" :date_type="date_type"  :is_view = "true"></dateForm>
                </div>
                <section class="flex_box_quad">
                    <memberForm :selectid="selectid"></memberForm>
                    <stepForm></stepForm>
                </section>

                <div class="myo_accordion">
                    <div class="accordion_item">
                        <a class="accordion_tit" href="#n">{{ i18n.myoffice.myoSearchForm.searchSetting }} <span class="accordion_sub_tit">{{ i18n.myoffice.moreview }}</span>
                            <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-down"></i></span></div>
                        </a>
                        <div class="accordion_cont ">
                            <div class="w-full lg:grid lg:grid-cols-4 lg:gap-2">
                                <div class="w-full z-[14] relative">
                                    <countryForm @set_bz_cd="set_bz_cd" :bz_cd="bz_cd"></countryForm>
                                </div>
                                <div class="w-full z-[13]  relative">
                                    <orderClassification @set_ord_kind="set_ord_kind"></orderClassification>
                                </div>
                                <div class="w-full z-[12]  relative">
                                    <ordeType @set_kind_cd="set_kind_cd"></ordeType>
                                </div>
                                <div class="w-full z-[11]  relative">
                                    <transactionStatus @set_rcpt_yns="set_rcpt_yns"></transactionStatus>
                                </div>
                                <div class="z-10 col-span-4  relative">
                                    <rankForm  @setSelected="setSelected" ></rankForm>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section><!-- // myo_condition_box -->

            <div class="myo_condition_search2">
                <button type="reset" onclick="location.replace('/MyOrderLegs')" class="reset_btn">{{ i18n.myoffice.Refresh  }}</button>
                <button type="submit" class="search_btn">{{ i18n.myoffice.Search  }}</button>
            </div>
        </form>
    </article>

    <div style="height: 10px; clear:both;"></div>
    <div id="search_result">
        <div id="orders-left">
            <h4 class='order-line-name'>A {{ i18n.myoffice.dashboard.Line  }} {{ lists.length > 0 ? lists[1].username : "" }} {{ ltotal }} </h4>
            <div id="search_result"  class="list-table-wrap">
                <table class="list-table">
                    <thead>
                    <tr>
                        <th>{{ i18n.myoffice.step }}</th>
                        <th>{{ i18n.myoffice.memberName }}</th>
                        <th>{{ i18n.myoffice.Order.orderDate }}</th>
                        <th>{{ i18n.myoffice.Order.depositdate }}</th>
                        <th>{{ i18n.myoffice.Order.deposit }}</th>
                        <th>{{ i18n.myoffice.Order.amount }}</th>
                        <th>{{ i18n.myoffice.Order.bv }}</th>
                        <th>{{ i18n.myoffice.Country }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td align="center" colspan="5">{{ i18n.myoffice.total }}</td>
                        <td align="right"><span v-if="lbz_cd == 0 && lists.length != 0">{{ formatPrice(lists[lists.length-1]["amt"],2) }}</span></td>
                        <td align="right"><span v-if="lists.length != 0">{{ formatPrice(lists[lists.length-1]["pv"],1) }}</span></td>
                        <td align="center">&nbsp;</td>
                    </tr>
                    <tr v-for="(value) in lists">
                        <td v-if="value.userid" align="center">{{value.lv}}</td>
                        <td v-if="value.userid" align="center" :class="{'user_menu' : value.username != ''}" :data-enc_id="value.enc_id" :data-userid="value.userid" >{{ value.username ? value.username : '' }}<i v-show="value.username" class="fal fa-info-circle"></i></td>
                        <td v-if="value.userid" align="center">{{value.orddate ? value.orddate : i18n.myoffice.refund.subTotal }}</td>
                        <td v-if="value.userid" align="center">{{value.accdate}}</td>
                        <td v-if="value.userid" align="center">{{value.rcptyn}}</td>
                        <td v-if="value.userid" align="right">{{ formatPrice(value.amt,2) }}</td>
                        <td v-if="value.userid" align="right">{{ formatPrice(value.pv,1) }}</td>
                        <td v-if="value.userid" align="center">{{value.bzcd}}</td>
                    </tr>
                    <tr v-if="lists.length == 0">
                        <td colspan="13" style="text-align: center">
                          {{ this.list_msg }}
                        </td>
                    </tr>
                    </tbody>

                </table>
            </div>
        </div>
        <div id="orders-right">
            <h4 class='order-line-name'>B {{ i18n.myoffice.dashboard.Line  }} {{ rlists.length > 0 ? rlists[1].username : "" }} {{ rtotal }} </h4>
            <div id="search_result"  class="list-table-wrap">
                <table class="list-table">
                    <thead>
                    <tr>
                        <th>{{ i18n.myoffice.step  }}</th>
                        <th>{{ i18n.myoffice.memberName  }}</th>
                        <th>{{ i18n.myoffice.Order.orderDate  }}</th>
                        <th>{{ i18n.myoffice.Order.depositdate }}</th>
                        <th>{{ i18n.myoffice.Order.deposit }}</th>
                        <th>{{ i18n.myoffice.Order.amount }}</th>
                        <th>{{ i18n.myoffice.Order.bv }}</th>
                        <th>{{ i18n.myoffice.Country  }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td align="center" colspan="5">{{ i18n.myoffice.total }}</td>
                        <td align="right"><span v-if="rbz_cd == 0 && rlists.length != 0">{{ formatPrice(rlists[rlists.length-1]["amt"],2) }}</span></td>
                        <td align="right"><span v-if="rlists.length != 0">{{ formatPrice(rlists[rlists.length-1]["pv"],1) }}</span></td>
                        <td align="center">&nbsp;</td>
                    </tr>
                    <tr v-for="(value) in rlists" >
                        <td v-if="value.userid" align="center">{{value.lv}}</td>
                        <td v-if="value.userid" align="center" :class="{'user_menu' : value.username != ''}" :data-enc_id="value.enc_id" :data-userid="value.userid" >{{ value.username ? value.username : '' }}<i v-show="value.username" class="fal fa-info-circle"></i></td>
                        <td v-if="value.userid" align="center">{{value.orddate ? value.orddate : i18n.myoffice.refund.subTotal }}</td>
                        <td v-if="value.userid" align="center">{{value.accdate}}</td>
                        <td v-if="value.userid" align="center">{{value.rcptyn}}</td>
                        <td v-if="value.userid" align="right">{{ formatPrice(value.amt,2) }}</td>
                        <td v-if="value.userid" align="right">{{ formatPrice(value.pv,1) }}</td>
                        <td v-if="value.userid" align="center">{{value.bzcd}}</td>
                    </tr>
                    <tr v-if="rlists.length == 0">
                        <td colspan="13" style="text-align: center">
                          {{ this.list_msg }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import dateForm from '../../searchForm/dateForm.vue'
import memberForm from '../../searchForm/memberForm.vue'
import stepForm from '../../searchForm/stepForm.vue'
import divisionForm from '../../searchForm/divisionForm.vue'
import countryForm from '../../searchForm/countryForm.vue'
import orderClassification from '../../searchForm/orderClassification.vue'
import rankForm from '../../searchForm/rankForm.vue'
import ordeType from '../../searchForm/ordeType.vue'
import transactionStatus from '../../searchForm/transactionStatus.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";
import { Tooltip } from "floating-vue";
import 'floating-vue/dist/style.css';
import moment from "moment";

export default {
    name: "MyOrderLegs",
    components : {
        dateForm,
        memberForm,
        stepForm,
        divisionForm,
        countryForm,
        orderClassification,
        rankForm,
        ordeType,
        transactionStatus,
        VPagination,
        Loading,
        VTooltip : Tooltip
    },
    data: ()=>({
        i18n: window.i18n,
        lists : [],
        rlists : [],
        ltotal : "",
        rtotal : "",
        rank_cd : "",
        bz_cd : "",
        ord_kind : "",
        kind_cd : "",
        rcpt_yns : "",
        loading : false,
        fullPage : true,
        list_msg : "Click 'Search' to view the data",
        lbz_cd : 0,
        rbz_cd : 0,
        date_type : 'order',
    }),
    props:['selectid'],
    methods : {
        submitForm : function (event){
            event.preventDefault();
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getOrderLegs',{
                s_date : this.s_date,
                e_date : this.e_date,
                level : level.value,
                sid : search_id.value,
                bz_cd : this.bz_cd,
                ord_kind : this.ord_kind,
                kind_cd : this.kind_cd,
                rcpt_yns : this.rcpt_yns,
                rank_cd : this.rank_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.loading = false;
                this.lists = response.data.llist;
                this.rlists = response.data.rlist;
                this.ltotal = response.data.ltotal;
                this.rtotal = response.data.rtotal;
                this.lbz_cd = response.data.lbz_cd;
                this.rbz_cd = response.data.rbz_cd;

                if(this.ltotal == 0 && this.rtotal == 0){
                    VueSimpleAlert.alert("There are no search results","","",{});
                    this.list_msg = "No data";
                    this.sid_name = "";
                }

            });

        },
        setSelected(rank){
            this.rank_cd = "";
            rank.forEach((value, index) => {
                this.rank_cd += value.id
                if(rank.length != index+1){
                    this.rank_cd += ",";
                }
            });
        },
        updatePageHandler(page){
            //this.page = page;
        },
        notCheck(id){
            return this.order != id ? true : false;
        },
        asconCheck(id){
            return this.order == id && this.sort == "asc" ? true : false;
        },
        descCheck(id){
            return this.order == id && this.sort == "desc" ? true : false;
        },
        reSortArray(id){
            this.order = id;
            this.sort = this.sort == "asc" ? "desc" : "asc";
            $("#submit").click();

        },
        set_s_date(s_date){
          this.s_date = moment(s_date).format('YYYY-MM-DD');
        },
        set_e_date(e_date){
          this.e_date = moment(e_date).format('YYYY-MM-DD');
        },
        moment: function () {
          return moment();
        },
        set_date_type(date_type){
            this.date_type = date_type;
        },
        set_bz_cd(bz_cd){
            this.bz_cd = bz_cd;
        },
        set_ord_kind(ord_kind){
            this.ord_kind = ord_kind;
        },
        set_kind_cd(kind_cd){
            this.kind_cd = kind_cd;
        },
        set_rcpt_yns(rcpt_yns){
            this.rcpt_yns = rcpt_yns;
        },
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

    }
}
</script>

<style>
#search_result:after { content: ""; display: block; clear:both;}
#orders-left { width:calc(50% - 5px); float:left;}
#orders-right { width:calc(50% - 5px); float:right;}
.fal.fa-search {font-size: 16px; padding:3px; border:1px solid #dadada;}
@media    all and (min-width:991px ) and (max-width:1000px) {
    #orders-left { width:calc(100%); float:none; margin-bottom: 20px;}
    #orders-right { width:calc(100%); float:none;}
}

@media    all and (max-width:770px) {
    #orders-left { width:calc(100%); float:none; margin-bottom: 20px;}
    #orders-right { width:calc(100%); float:none;}
}

/*테이블 타이블 */
.order-line-name { background: #272c33; color:#fff; padding:10px; margin: 0;}

</style>
