<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="true"></loading>
    <div>
        <div style="width:100%;">
            <ul id="first_step1" v-show="step == 1">
                <li class="table w-full table-fixed border md:border-y md:border-x divide-slate-200 relative">
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-normal">{{ i18n.myoffice.refund.profile}}</span>
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700">{{ mem.userid }} ( {{ mem.username }})</span>
                </li>
                <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-normal">{{ i18n.myoffice.refund.orderNumber}}</span>
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                      {{ ord_no }} <span class="text-rose-500">{{ i18n.myoffice.Order.PurchaseDate}} {{ row.ord_date }}, {{ i18n.myoffice.Order.PastDueDate}} {{ row.diff_days }}</span>
                    </span><!-- 구매일 : 경과기일 :-->
                </li>
                <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-normal">{{ i18n.myoffice.refund.applicationType}}</span>
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                        <label class='radio_input_box' style="height: auto;">
                            <input type='radio' v-model="refund_type" v-on:click="selectRefund(1)" value='refund_return'>
                            <span class='checkmark'></span>{{ i18n.myoffice.refund.return }}
                        </label>
                        <label class='radio_input_box' style="height: auto;">
                            <input type='radio' v-model="refund_type" v-on:click="selectRefund(2)" value='refund_exchange'>
                            <span class='checkmark'></span>{{ i18n.myoffice.refund.exchange }}
                        </label>
                    </span>
                </li>
            </ul>
            <div id="first_step2" class="overflow-x-auto" v-show="step == 2">

                <h2 class="border-l-4 border-solid border-gray-400 pl-1.5 mt-7 text-base md:text-xl">Select goods to {{ this.refund_type == "refund_exchange" ? 'Exchange' : 'Return' }}</h2>

                <table class="table-auto w-full overflow-x-auto border border-slate-200 text-sm md:text-base ">
                    <thead>
                    <tr class='text-gray-700 bg-gray-50 border-b border-slate-200 font-semibold leading-normal'>
                        <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap">{{ i18n.myoffice.refund.additionalProductName }}</th>
                        <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap">{{ i18n.myoffice.refund.order }}</th>
                        <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap">-</th>
                        <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap">BV</th>
                    </tr>

                    </thead>
                    <tbody>
                    <tr v-for="(value,index) in pdt_qty" class="border-b border-slate-200">
                        <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap">{{ value.pdt_name }}</td>
                        <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap text-center">{{ value.purchase_qty }}</td>
                        <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap"><input type="number" style="width: 50%;min-width:50px;max-width:100px;" @keypress="isNumber($event)" @change="maxQty(index,value.purchase_qty)" v-model="pdt_qty[index].qty"></td>
                        <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap">Amount : {{ value.sum_amt }}<br>BV : {{ value.sum_pv }}</td>
                    </tr>
                    </tbody>
                </table>
                <div style="margin:20px 0; padding:0 15px;">
                    <div class="myo_condition_search2">
                        <button type="button" class="go_list_btn" @click="previous">Previous Step</button>
                        <button type="button" class="search_btn" v-on:click="nextStep()">Next Step</button>
                    </div>
                </div>
            </div>
            <div id="first_step3" class="items_list"  v-show="step == 3">

                <h2 class="border-l-4 border-solid border-gray-400 pl-1.5 mt-7 text-base md:text-xl">Select goods to exchange</h2>

                <input text="text" v-model="searchQuery" style="border: 1px solid;">
                <div class="overflow-y-auto max-h-72">
                    <table class="table-auto w-full overflow-x-auto overflow-y-auto border border-slate-200">
                        <thead>
                        <tr class='text-gray-700 bg-gray-50 border-b border-slate-200 font-semibold leading-normal'>
                            <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap">SKU</th>
                            <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap">{{ i18n.myoffice.refund.additionalProductName }}</th>
                            <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap min-w-28">BV</th>
                        </tr>

                        </thead>
                        <tbody>
                        <tr v-for="(value,index) in resultQuery" v-on:click="select_exchange_item(index)" class="border-b border-slate-200 even:bg-gray-50 cursor-pointer">
                            <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap">{{ value.pdt_cd }}</td>
                            <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap">{{ value.pdt_name }}</td>
                            <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap min-w-28">Amount : {{ value.amt }}<br>BV : {{ value.pv }}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
                <h2 class="border-l-4 border-solid border-gray-400 pl-1.5 mt-7 text-base md:text-xl">Selected goods</h2>
                <div class="overflow-y-auto max-h-72">
                    <table class="table-auto w-full overflow-x-auto overflow-y-auto border border-slate-200">
                        <thead>
                        <tr class='text-gray-700 bg-gray-50 border-b border-slate-200 font-semibold leading-normal'>
                            <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap">SKU</th>
                            <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap">{{ i18n.myoffice.refund.additionalProductName }}</th>
                            <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap">-</th>
                            <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap">BV</th>
                            <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap"></th>
                        </tr>

                        </thead>
                        <tbody>
                        <tr v-for="(value,index) in pdt_exchange_qty" class="border-b border-slate-200 even:bg-gray-50">
                            <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap">{{ value.pdt_cd }}</td>
                            <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap">{{ value.pdt_name }}</td>
                            <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap"><input type="number" style="width: 50%;min-width:50px;max-width:100px;" @keypress="isNumber($event)" @change="maxEQty(index,value.qty)" v-model="pdt_exchange_qty[index].qty"></td>
                            <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap">Amount : {{ value.amt }}<br>BV : {{ value.pv }}</td>
                            <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap min-w-28"><input type="button" v-on:click="delete_exchange_qty(index)" value="delete"></td>
                        </tr>
                        </tbody>

                    </table>

                </div>
                <div id="cancelOrderRefund" style="margin:20px 0; padding:0 15px;">
                    <div class="myo_condition_search2">
                        <button type="button" class="go_list_btn" @click="previous">Previous Step</button>
                        <button type="submit" class="search_btn" v-on:click="nextStep()">Next Step</button>
                    </div>
                </div>

            </div>
            <div id="first_step4" class="items_list" v-show="step == 4">
                <div class="" id="">
                    <h2 class="border-l-4 border-solid border-gray-400 pl-1.5 mt-7 text-base md:text-xl">Payment Information</h2>
                    <ul class="text-sm md:text-base">
                        <li class="table w-full table-fixed border md:border-y md:border-x divide-slate-200 relative">
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">Total return</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                                {{ paidRefund }} <!-- 원 -->
                            </span>
                        </li>
                        <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">Amount exchanged</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                                {{ paidExchange }} <!-- 원 -->
                            </span>
                        </li>
                        <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">BV to be changed</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                                {{ paidbv }} <!-- 원 -->
                            </span>
                        </li>
                        <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">Amount to be deposited/refunded</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                                {{ paid }}
                            </span><!-- 원 -->
                        </li>
                        <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">Return address</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">No 5, Jalan PPU 2A, Taman Perindustrian Puchong Utama, 47100 Puchong Selangor.</span>
                        </li>
                    </ul>
                    <p class="text-rose-500">
                        It will be refunded in the method you paid when ordering<br>
                        (Crdit/Debit Card - Transaction will be canceled / FPX/E-wallet - A refund will be made to the payment account)
                    </p>
                </div>
                <div>
                    <h2 class="border-l-4 border-solid border-gray-400 pl-1.5 mt-7 text-base md:text-xl">Return information</h2>
                    <ul class="text-sm md:text-base">
                        <li class="table w-full table-fixed border md:border-y md:border-x divide-slate-200 relative">
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">Shipping address</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">No 5, Jalan PPU 2A, Taman Perindustrian Puchong Utama, 47100 Puchong Selangor.</span>
                        </li>
                        <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">The recipient</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">GCOOP</span>
                        </li>
                        <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">Contact No.</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">03-9212-8128</span>
                        </li>
                    </ul>
                </div>
                <div style="margin:20px 0; padding:0 15px;">
                    <div class="myo_condition_search2">
                        <button type="button" class="go_list_btn" @click="previous">Previous Step</button>
                        <button type="button" class="search_btn" v-on:click="submitForm()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
    </div>
</template>

<script>
import alert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';

export default {
    name: "MyViewOrderRefund",
    props : {
        ord_no : String,
        r_type : String,
        searchType : String,
        schUserId : String,
    },
    components : {
      Loading
    },
    data: () => ({
        i18n: window.i18n,
        list: "",
        product_list: "",
        mem : "",
        row : "",
        refund_type: "",
        step : 1,
        pdt_exchange_qty : [],
        pdt_qty : [],
        searchQuery : "",
        r_address1 : "",
        r_address2 : "",
        r_name : "",
        memo : "",
        r_mobile : "",
        r_account : "",
        r_bankname : "",
        r_holder : "",
        ords : "",
        regs : "",
        ins : "",
        appends : "",
        paid : 0,
        paidbv : 0,
        paidRefund : 0,
        paidExchange : 0,
        loading : false,
    }),
    created(){
        axios.post(window.Laravel.back_url+'/api/getOrdMst',{
            ord_no : this.ord_no,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        });

        axios.post(window.Laravel.back_url+'/api/getOrdPdt',{
            ord_no : this.ord_no,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.list = response.data.list;
            this.mem = response.data.mem;
            this.row = response.data.row;
            response.data.list.forEach((value, index) => {
                if(value.pdt_cd == "000240" || value.pdt_cd == "000243"){

                }else{
                    this.pdt_qty.push({
                        pdt_cd : value.pdt_cd,
                        pdt_name : value.pdt_name,
                        sum_amt : value.sum_amt,
                        sum_pv : value.sum_pv,
                        amt : value.amt,
                        pv : value.pv,
                        purchase_qty : value.qty,
                        qty : 0,
                    });
                }
            });
        });
        axios.post(window.Laravel.back_url+'/api/getAllProduct',{
            ord_no : this.ord_no,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.product_list = response.data;
        });

    },
    computed: {
        resultQuery(){
            if(this.searchQuery){
                return this.product_list.filter((item)=>{
                    return this.searchQuery.toLowerCase().split(' ').every(v => item.pdt_name.replace(/ /gi,"").toLowerCase().includes(v))
                })
            }else{
                return this.product_list;
            }
        }
    },
    methods : {
        selectRefund(id){
            if(id == 1){
                this.step = 2;
            }
            if(id == 2){
                this.step = 2;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        maxQty(index,max){
            if(this.pdt_qty[index].qty > max){
                this.pdt_qty[index].qty = max;
            }
            var qty = this.pdt_qty[index].qty;
            if(qty != 0){
                this.pdt_qty[index].qty = qty.toString().replace(/(^0+)/, "");
            }

            this.getPaidAmount();

        },
        maxEQty(index,max){
            if(this.pdt_exchange_qty[index].qty > max){
                this.pdt_exchange_qty[index].qty = max;
            }
            var qty = this.pdt_exchange_qty[index].qty;
            this.pdt_exchange_qty[index].qty = qty.toString().replace(/(^0+)/, "");

            //this.paid -= this.pdt_exchange_qty[index].amt * this.pdt_exchange_qty[index].qty;
            this.getPaidAmount();

        },
        getPaidAmount(){
            this.paid = 0;
            this.paidRefund = 0;
            this.paidbv  = 0;
            this.paidExchange = 0;

            this.pdt_qty.forEach((value, index) => {
                this.paid = this.formatPrice(this.paid - parseFloat(this.pdt_qty[index].amt) * parseFloat(this.pdt_qty[index].qty),2);
                this.paidbv = this.formatPrice(this.paidbv - parseFloat(this.pdt_qty[index].pv) * parseFloat(this.pdt_qty[index].pv),2);
                this.paidRefund = this.formatPrice(this.paidRefund - parseFloat(this.pdt_qty[index].amt) * parseFloat(this.pdt_qty[index].qty),2);


            })
            this.pdt_exchange_qty.forEach((value, index) => {
                this.paid = this.formatPrice(this.paid + parseFloat(this.pdt_exchange_qty[index].amt) * parseFloat(this.pdt_exchange_qty[index].qty),2);
                this.paidbv = this.formatPrice(this.paidbv + parseFloat(this.pdt_exchange_qty[index].pv) * parseFloat(this.pdt_exchange_qty[index].pv),2);
                this.paidExchange = this.formatPrice(this.paidExchange + parseFloat(this.pdt_exchange_qty[index].amt) * parseFloat(this.pdt_exchange_qty[index].qty),2)
            })

        },
        nextStep(){
            if(this.refund_type == "refund_return"){
                if(this.step == "2"){
                    this.step=4;
                }
            }else{
                if(this.step == "2"){
                    this.step=3;
                }
                else if(this.step == "3"){
                    this.step=4;
                }
            }
        },
        previous(){
          if(this.refund_type == "refund_return"){
            if(this.step == "4"){
              this.step=2;
            }
          }else{
            if(this.step == "3"){
              this.step=2;
            }
            else if(this.step == "4"){
              this.step=3;
            }
          }

          if(this.step == "2"){
            this.step=1;
          }
        },

        select_exchange_item(index){
            var end = "";
            this.pdt_exchange_qty.forEach((value, indexs) => {
                if(value.pdt_cd  == this.product_list[index].pdt_cd){
                    end = "1";
                }
            });

            if(end == "1"){
                return;
            }

            this.pdt_exchange_qty.push({
                pdt_cd : this.product_list[index].pdt_cd,
                pdt_name : this.product_list[index].pdt_name,
                amt : this.product_list[index].amt,
                pv : this.product_list[index].pv,
                qty : 0,
            });
        },
        delete_exchange_qty(index){
            this.pdt_exchange_qty.splice(index,1);
        },
        submitForm : function (){
            var formData = new FormData();
            formData.append('refund_type', this.refund_type);
            for (var i = 0; i < this.pdt_exchange_qty.length; i++) {
                this.appends += this.pdt_exchange_qty[i].pdt_cd+"|"+this.pdt_exchange_qty[i].qty+"|"+this.pdt_exchange_qty[i].amt+"|"+this.pdt_exchange_qty[i].pv+"||"+this.pdt_exchange_qty[i].pdt_name+"|@|";
            }
            formData.append('append_qtys', this.appends);


            //formData.append('pdt_exchange_qty', JSON.stringify(this.pdt_exchange_qty));

            for (var i = 0; i < this.pdt_qty.length; i++) {
                this.ords += this.pdt_qty[i].pdt_cd+"|"+this.pdt_qty[i].qty+"|"+this.pdt_qty[i].amt+"|"+this.pdt_qty[i].pv+"||"+this.pdt_qty[i].pdt_name+"|@|";
                this.regs += this.pdt_qty[i].pdt_cd+"|"+this.pdt_qty[i].qty+"|@|";
            }
            formData.append('ord_qtys', this.ords);
            formData.append('reg_qtys', this.regs);
            formData.append('paid', this.paid);
            formData.append('refund_type', this.refund_type);
            formData.append('r_address1', this.r_address1);
            formData.append('r_address2', this.r_address2);
            formData.append('r_mobile', this.r_mobile);
            formData.append('r_name', this.r_name);
            formData.append('r_bankname', this.r_bankname);
            formData.append('r_account', this.r_account);
            formData.append('r_holder', this.r_holder);
            formData.append('ord_no', this.ord_no);
            formData.append('processing', 1);


            //formData.append('pdt_qty', JSON.stringify(this.pdt_qty));

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + window.Laravel.access_token
                }
            }
            axios.post(window.Laravel.back_url+'/api/orderRefund',formData,config).then(response => {
              alert.alert("Completed","","",{allowOutsideClick : false}).then(r => {
                this.$emit('close-modal');
                window.location.reload();
              });
              this.loading = false;
                // this.row = response.data.row;
                // this.list_temp_str = response.data.list_temp_str;
                // this.loading = false;
            });

        },
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return parseFloat(val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        }

    }
}
</script>

<style>
</style>
