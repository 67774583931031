<template>
  <div class="lg:hidden fixed w-full bottom-0 h-14 bg-white border-t-2 pt-1 z-10">
    <div class="flex grid grid-cols-5">
      <button type="button" @click='cartLink'>
          <ShoppingCartIcon class="h-5 w-5 mx-auto" />
          <span class="text-xs leading-3">Cart</span>
      </button> <!-- 장바구니 -->
      <button type="button" @click='mypageLink'>
        <ClipboardListIcon class="h-5 w-5 mx-auto" />
        <span class="text-xs leading-3">MyPage</span>
      </button> <!-- 마이페이지 -->
      <!--주문조회 마이페이지로 변경 20201013-->
      <!--<span class="order"><a href="/ViewOrders"><span class="mobile-menu-icon"><i class="fal fa-truck"></i></span>Order Search</a></span>  주문조회 -->
      <!-- <span class="shiping"><a href="/ViewOrders"><span><i class="fal fa-truck"></i></span>Track your delivery</a></span>--><!--배송조회 -->
      <button type="button" @click='homeLink'>
        <HomeIcon class="h-5 w-5 mx-auto" />
          <span class="text-xs leading-3">Home</span>
      </button> <!-- 쇼핑몰홈 -->
        <button type="button" v-if="isLoggedIn" @click="goAutoLogin('registerToken','')">
            <UserAddIcon class="h-5 w-5 mx-auto" />
            <span class="text-xs leading-3">Member Enrollment</span>
        </button> <!-- 회원가입-->
      <button type="button" v-if="!isLoggedIn" @click='registerLink'>
        <UserAddIcon class="h-5 w-5 mx-auto" />
          <span class="text-xs leading-3">Member Enrollment</span>
      </button> <!-- 회원가입-->

      <button type="button" @click='myofficeLink'>
        <ChartSquareBarIcon class="h-5 w-5 mx-auto" />
          <span class="text-xs leading-3">MyOffice</span>
      </button> <!-- 마이오피스 -->
    </div>
  </div>
</template>

<script>
import {
  ShoppingCartIcon,
  ChartSquareBarIcon,
  ClipboardListIcon,
  UserAddIcon,
  HomeIcon,
} from '@heroicons/vue/outline'

export default {
  name: "LayoutMobileFooterMenu",
  components: {
    ShoppingCartIcon,
    ChartSquareBarIcon,
    UserAddIcon,
    ClipboardListIcon,
    HomeIcon
  },
    data: () => ({
        product_categorys: [],
        navigations: [],
        isLoggedIn: false,
        i18n: window.i18n,
        isActiveSearch: false,
        countCartItems: 0,
        csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
        test : "test",
    }),

    mounted() {
        if (window.Laravel.isLoggedin) {
            this.isLoggedIn = true;
            this.user = window.Laravel.user;
            this.username = window.Laravel.username;
            // 장바구니 담긴 상품수
        }
    },

    methods: {
    // searchFormOpen: function(event) {if(isActiveSearch)},
    cartLink: function(event) {location.href = "/cart";},
    mypageLink: function(event) {location.href = "/Mypage";},
    myofficeLink: function(event) {location.href = "/MyofficeMain";},
    homeLink: function(event) {location.href = "/";},
    registerLink: function(event) {location.href = "https://register.gcoop.com";},
      allproduct : function (event) { location.href="/product/list/all" },
      goAutoLogin(mode, url) {
          window.open("https://signup.gcoop.com/RegToken?xid2="+window.Laravel.xid_info+"&token="+window.Laravel.xid_info);
          // console.log(mode, url);
          // let config = {
          //     headers: {
          //         Authorization: 'Bearer ' + window.Laravel.access_token
          //     }
          // };
          //
          // axios.post(window.Laravel.back_url +'/api/goAutoLogin',{
          //     mode: mode,
          //     url: url,
          //     bz_cd: window.Laravel.bz_cd,
          // },config).then(response => {
          //     this.test = response.data.href_url;
          //     if(response.data.href_url != ""){
          //         //window.open(response.data.href_url);
          //         location.href=response.data.href_url;
          //     }
          // }).catch(e => {
          // });
      }
      // 장바구니 담긴 상품수
    // getCountCartItems(){
    //   axios.post(process.env.MIX_BACK_URL+'/api/cart',{
    //     bz_cd: window.Laravel.bz_cd,
    //   },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
    //     .then(response => {
    //       console.log(this.countCartItems);
    //       this.countCartItems = (response.data.length);
    //     });
    // },
  },
}
</script>
