<template>

    <section class="container mx-auto py-6">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8">
        <div class="w-full">
          <div class="flex justify-start item-start space-y-2 flex-col">
            <h1 class="text-2xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800 mb-2 lg:mb-6">Order Summary</h1>
          </div>
          <div class="w-full px-4 lg:flex lg:justify-between">
            <div class="flex justify-start items-start flex-col space-y-4 mt-4 xl:mt-8 border-b lg:border-0 xl:border-0 pb-2">
              <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800 lg:text-lg">Delivery Information</p>
              <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">{{ request["name"] }}<br> {{ request["mobile"] }}</p>
            </div>
            <div class="flex justify-start items-start flex-col space-y-4 mt-4 xl:mt-8 border-b lg:border-0 xl:border-0 pb-2">
              <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800 lg:text-lg">Delivery Address</p>
              <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">
                {{ request["address"]}} {{ request["address2"]}}, {{ request["street"]}}, {{ request["postcode"]}}, {{ request["city"]}}, {{ request["state"]}}</p>
            </div>
            <div class="flex justify-start items-start flex-col space-y-4 mt-4 xl:mt-8 border-b lg:border-0 xl:border-0 pb-2">
              <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800 lg:text-lg">Billing Information</p>
              <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">
                  {{ order_info_list.o_name }}<br>{{ order_info_list.o_mobile }}<br>
                  {{ order_info_list.o_addr2 }} {{ order_info_list.o_addr1 }}
                </p>
            </div>
            <div class="flex justify-start items-start flex-col space-y-4 mt-4 xl:mt-8 pb-2">
              <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800 lg:text-lg">Contact Help</p>
              <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">info.my@gcoop.com</p>
            </div>
          </div>

          <div class="w-full lg:flex lg:justify-start">
            <div class="flex flex-col justify-start items-start w-ful lg:w-2/3 space-y-4 md:space-y-6 xl:space-y-8 px-2">
              <div class="flex flex-col justify-start items-start dark:bg-gray-800 bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full mt-8">
                <p class="text-lg md:text-xl dark:text-white font-semibold leading-6 xl:leading-5 text-gray-800 mb-6">Customer’s Cart</p>

                <div v-for="(product, i) in order_product_list" :key="product.seq_pd" class="flex w-full border-b border-gray-200 mb-2">
                  <div class="relative w-1/2 py-2">
                    <img class="w-full hidden md:block" v-bind:src="'http://cdn.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename "  alt="{{ product.product.pdt_name }}" />
                    <img class="w-full md:hidden" v-bind:src="'http://cdn.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename " alt="{{ product.product.pdt_name }}" />
                    <div class="sold-out-box2" v-if="product.product.status == 2">
                      <img src="/images/badge/us/pc_temp_sold_out.png" alt="sold out" />
                    </div>
                    <div class="sold-out-box2" v-if="product.product.status == 3">
                      <img src="/images/badge/us/pc_sold_out.png" alt="sold out" />
                    </div>
                    <div class="coming_box" v-if="product.product.status == 4">
                      <img src="/images/badge/us/pc_coming_soon.png" alt="sold out" />
                    </div>
                    <div class="badge_box" v-if="product.product.badges">
                      <img v-bind:src="'http://cdn.gcoop.me/' + product.product.badges.image_url" alt="sold out" />
                    </div>

                  </div>
                  <div class="flex-col flex justify-between items-start w-full pt-2 pb-8 space-y-4 md:space-y-0 pl-4">
                    <div class="w-full flex flex-col justify-start items-start space-y-8">
                      <h3 class="dark:text-white xl:text-xl font-semibold leading-6 text-gray-800">{{ product.product.pdt_name }}</h3>
                    </div>
                    <div class="text-red-500 text-xs md:text-lg" v-if="product.product.deli_date2" v-html="product.product.deli_date2"></div>
                    <div class=" w-full">
                      <p class="text-sm dark:text-white xl:text-lg">RM{{ formatPrice(product.amt,2) }} / <span class="text-red-500">{{ formatPrice(product.pv,1) }} BV</span></p>
                      <p class="text-sm dark:text-white xl:text-lg text-gray-800">Qty : {{ product.qty }}</p>
                      <p class="text-sm dark:text-white xl:text-lg font-semibold text-gray-800">RM{{ formatPrice(product.amt * product.qty,2) }} / <span class="text-red-500">{{ formatPrice(product.pv * product.qty,1) }} BV</span></p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="w-full lg:w-1/3 lg:mt-8 px-2">
                <div class="flex flex-col justify-center px-4 py-6 md:p-6 xl:p-6 w-full bg-gray-50 dark:bg-gray-800 space-y-6 ">
                    <h3 class="text-lg xl:text-xl lg:text-xl dark:text-white font-semibold leading-5 text-gray-800 pl-3">Payment Methods<br>
                    </h3>
                    <div class="w-full pay_box">
                        <div class="grid grid-cols-2 gap-2 mt-2">
                            <div class="w-full xl:text-base lg:text-base text-sm" @click="key = 1">
                                <input type="radio" id="paytype1" name="paytype" class="hidden" v-model="key" value="1" >
                                <label for="paytype1" :class="[key == 1 ? 'pay_box_select' : '']" class="bg-white text-gray-800 border border-gray-300 font-semibold px-4 py-1 cursor-pointer rounded-md block text-center h-12 flex items-center justify-center">
                                    eGHL
                                </label>
                            </div>
                            <div class="w-full xl:text-base lg:text-base text-sm" @click="key = 2">
                                <input type="radio" id="paytype2" name="paytype" class="hidden" v-model="key" value="2">
                                <label for="paytype2" :class="[key == 2 ? 'pay_box_select' : '']" class="bg-white text-gray-800 border border-gray-300 font-semibold px-4 py-1 cursor-pointer rounded-md block text-center h-12 flex items-center justify-center">
                                    payex
                                </label>
                            </div>
                            <div v-show="heart >= 20" class="w-full xl:text-base lg:text-base text-sm" @click="heartUse()">
                                <input type="radio" id="paytype3" name="paytype" class="hidden" v-model="key" value="3">
                                <label for="paytype3"  :class="[key == 3 ? 'pay_box_select' : '']"  class="bg-white text-gray-800 border border-gray-300 font-semibold px-4 py-1 cursor-pointer rounded-md block text-center h-12 flex items-center justify-center">
                                    Hearts
                                </label>
                            </div>
                            <div class="w-full xl:text-base lg:text-base text-sm" @click="key = 4">
                                <input type="radio" id="paytype4" name="paytype" class="hidden" v-model="key" value="4">
                                <label for="paytype4"  :class="[key == 4 ? 'pay_box_select' : '']"  class="bg-white text-gray-800 border border-gray-300 font-semibold px-4 py-1 cursor-pointer rounded-md block text-center h-12 flex items-center justify-center">
                                    payDollar
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="key == 3" class="flex justify-center flex-col md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                    <div class="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 dark:bg-gray-800 space-y-6">
                        <h3 class="text-xl dark:text-white font-semibold leading-5 text-gray-800">Heart</h3>
                        <div class="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                            <div class="flex justify-between w-full">
                                <p class="text-base dark:text-white leading-4 text-gray-800">Heart Point</p>
                                <p class="text-base dark:text-gray-300 leading-4 text-gray-800">RM{{ formatPrice(heart,2) }} </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex justify-center flex-col md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                <div class="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 dark:bg-gray-800 space-y-6">
                  <h3 class="text-xl dark:text-white font-semibold leading-5 text-gray-800">Payment</h3>
                  <div class="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                    <div class="flex justify-between w-full">
                      <p class="text-base dark:text-white leading-4 text-gray-800">Subtotal</p>
                      <p class="text-base dark:text-gray-300 leading-4 text-gray-800">RM{{ formatPrice(sum_item.amt-shipping_selected,2) }} <p class="text-red-500 text-right mt-2">{{ formatPrice(sum_item.bv,1) }} BV</p></p>
                    </div>
                    <div class="flex justify-between items-center w-full">
                      <p class="text-base dark:text-white leading-4 text-gray-800">Shipping</p>
                      <p class="text-base dark:text-gray-300 leading-4 text-gray-800">RM{{ formatPrice(shipping_selected,2) }}</p>
                    </div>
                      <div v-show="key == 3" class="flex justify-between items-center w-full">
                          <p class="text-base dark:text-white leading-4 text-gray-800">Heart Point</p>
                          <p class="text-base dark:text-gray-300 leading-4 text-gray-800">RM{{ formatPrice(heart_use,2) }}</p>
                      </div>

                      <div v-show="key == 3 &&  reminder_amt > 0" class="flex justify-between items-center w-full">
                          <p class="text-base dark:text-white leading-4 text-gray-800">Final Price</p>
                          <p class="text-base dark:text-gray-300 leading-4 text-gray-800">RM{{ formatPrice(reminder_amt,2) }}</p>
                      </div>

                  </div>
                  <div class="flex justify-between items-center w-full border-b pb-4">
                    <p class="text-base dark:text-white font-semibold leading-4 text-gray-800">Total</p>
                    <p class="text-base dark:text-gray-300 font-semibold leading-4 text-gray-600">RM{{  formatPrice(total_cost,2) }} <p class="text-red-500 text-right mt-2">{{ formatPrice(sum_item.bv,1) }} BV</p></p>
                  </div>
                </div>
              </div>

                <div class="w-full">
                <div class="flex justify-start w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                  <div class="flex flex-col justify-center px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 dark:bg-gray-800 space-y-6">
                    <h3 class="text-xl dark:text-white font-semibold leading-5 text-gray-800">Shipping</h3>
                    <div class="flex justify-between items-center w-full">
                      <div class="flex justify-center items-center space-x-4">
                        <div class="w-12">
                          <img class="w-full" style="width:50px;height:50px;" alt="logo" src="https://cdn.gcoop.me/shop/storage/images/my/logo_gdex_m.png" />
                        </div>
                        <div class="flex flex-col justify-start items-center">
                          <p class="text-md leading-6 dark:text-white font-semibold text-gray-800">GDEX Malaysia<!--<br /><span class="font-normal">Delivery with 24 Hours</span>--></p>
                        </div>
                      </div>
                      <!--                    <p class="text-lg font-semibold leading-6 dark:text-white text-gray-800">RM8.00</p>-->
                    </div>
                  </div>
                </div>
              </div>
                <button @click="pay_proc" v-show="key == 1 || (key == 3 && reminder_amt != 0)" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">Checkout</button>
                <button @click="payex_proc" v-show="key == 2" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">Checkout</button>
                <button @click="heart_pay_proc" v-show=" key == 3 && reminder_amt == 0" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">heart Checkout</button>
                <button @click="payDollar_proc" v-show="key == 4" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">Checkout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <form name="frmPayment">
    <input type="hidden" name="_token">
    <input type="hidden" name="TransactionType" value="SALE">
    <input type="hidden" name="PymtMethod" value="ANY"> <!-- 정기구매 첫결제 : CC , 일반결제 any -->
    <input type="hidden" name="ServiceID" value="GPO">
    <input type="hidden" name="PaymentID">
    <input type="hidden" name="OrderNumber">
    <input type="hidden" name="PaymentDesc" value="GCOOP Malaysia">
    <input type="hidden" name="MerchantName" value="GCOOP Malaysia Sdn. Bhd.">
    <input type="hidden" name="MerchantReturnURL">
    <input type="hidden" name="MerchantCallbackURL">
    <input type="hidden" name="Amount">
    <input type="hidden" name="reminder_amt">
      <input type="hidden" name="heart_use">
      <input type="hidden" name="CurrencyCode" value="MYR">
    <input type="hidden" name="CustIP">
    <input type="hidden" name="CustName">
    <input type="hidden" name="CustEmail">
    <input type="hidden" name="CustPhone">
    <input type="hidden" name="HashValue">
    <input type="hidden" name="MerchantTermsURL" value="https://cdn.gcoop.me/shop/storage/pdf/my/terms_mal.pdf">
    <input type="hidden" name="LanguageCode" value="en">
    <input type="hidden" name="PageTimeout" value="780">
  </form>
</template>
<script>
/* javascript numberFormat library */
import numeral from 'numeral';
import 'numeral/locales';
import modalView from "../modalView";
import VueSimpleAlert from "vue3-simple-alert";

export default {
  name: "Order",
    components : {
        modalView,
    },
    props: ['request','hashdata'],
  setup() {
    return {
      // products,
    }
  },
  data: () => ({
      title: [],
      order_list: [],
      order_info_list: [],
      order_pay_list: [],
      order_product_list: [],
      sum_item: [],
      // fee_shipping: [],
      shipping_selected: 0,
      csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
      is_order_possible : window.Laravel.is_order_possible,
      heart : 0,
      heart_use : 0,
      reminder_amt : 0,
      key : 1,
      heart_overlap : false,
      address_search_open : true

    //orderer: orderer
  }),
  created() {
      this.order = this.request["order_seq"];
      this.sum_item.price = 0;
      this.sum_item.vat = 0;
      this.sum_item.amt = 0;
      this.sum_item.bv = 0;
      this.shipping_selected = 0;
      this.getOrder(window.Laravel.bz_cd);
  },
  methods:{

      getOrder(bz_cd) {
          axios.post(process.env.MIX_BACK_URL + '/api/getOrder', {
              order: this.order,
              bz_cd: bz_cd,
          }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
              .then(response => {

                  if ( response.data.result === '-100') {
                    VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
                      if(result){
                        location.replace('/cart');
                      }
                    });

                  }else {

                    this.order_list = response.data;
                    this.order_info_list = response.data.info;
                    this.order_info_list.d_addr2 = this.order_info_list.d_addr2.replaceAll("|@|", ", ");
                    this.order_info_list.d_addr1 = this.order_info_list.d_addr1.replaceAll("|@|", ", ");
                    this.order_info_list.o_addr1 = this.order_info_list.o_addr1.replaceAll("|@|", ", ");
                    this.order_info_list.o_addr2 = this.order_info_list.o_addr2.replaceAll("|@|", ", ");
                    this.order_pay_list = response.data.pay;
                    this.order_product_list = response.data.product;
                    this.sum_item.price = this.order_list.ord_price;
                    this.sum_item.vat = this.order_list.ord_vat;
                    this.sum_item.amt = this.order_list.ord_amt;
                    this.sum_item.bv = this.order_list.ord_pv;
                    this.shipping_selected = this.order_list.ord_deli_amt;
                    this.heart = response.data.heart;
                    this.totalCost();
                    // this.totalCost(this.order_product_list);  //  결제상품 총액 / 배송비 포함 실결제액
                    // this.title = response.data[0].cate_name;
                  }

              })
              .catch(e => {
                  // window.history.go(-1);
              });
      },
      // 합산 금액 합산
      totalCost(){  // 토탈 금액 합산 기능
          this.total_cost = this.sum_item.amt;
          if(this.shipping_selected) this.total_cost += parseFloat(this.shipping_selected);
          if(this.tax_selected) this.total_cost += parseFloat(this.tax_selected);
      },
      heartUse(){
          if(this.key != 3){
              if(this.heart_use == 0){
                  //결제 금액 보다 하트가 많으면 금액만큼 하트를 사용
                  if(Number(this.formatPrices(this.total_cost)) < this.heart){
                      this.heart_use = this.total_cost;
                  }else{
                      //결제 금액보다 하트가 적으면 가지고 있는 하트만큼 사용
                      this.heart_use = this.heart;
                      this.reminder_amt = this.formatPrices(this.total_cost) - this.heart;
                  }
              }else{
                  this.reminder_amt = 0;
                  this.heart_use = 0;
              }

          }
          this.key = 3;

      },
    // 장바구니 가져오기
/*    getCart(bz_cd){
      axios.post(process.env.MIX_BACK_URL+'/api/cart',{
        bz_cd: bz_cd,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          // this.title = response.data[0].cate_name;
          if(response.data.length) {
            this.products = response.data;
            // console.log(this.request);
            this.shipping_selected = this.request['shipping_fee']??0; // 배송비
            this.tax_selected = this.request['tax_fee']??0; // 택스
            this.totalCost(this.products);  //  결제상품 총액 / 배송비 포함 실결제액
          }else{
            // console.log(response.data);
            window.history.go(-1);
          }
        })
        .catch(e => {
          window.history.go(-1);
        });
    },*/
    // 수량 변경
/*    decrement(e,i){
      if(this.products[i].qty > 1) {
        this.products[i].qty--;
        this.totalCost(this.products);
        this.setChangeQty(this.products[i].seq_pd,this.products[i].qty);
      }
    },
    increment(e,i){
      if(this.products[i].qty < this.max_qty) {
        this.products[i].qty++;
        this.totalCost(this.products);
        this.setChangeQty(this.products[i].seq_pd,this.products[i].qty);
      }
    },
    changeQty(e,i){
      if(this.products[i].qty < 1) {
        this.products[i].qty = 1;
        this.totalCost(this.products);
        this.setChangeQty(this.products[i].seq_pd,this.products[i].qty);
      }
      if(this.products[i].qty > this.max_qty) {
        this.products[i].qty = this.max_qty;
        this.totalCost(this.products);
        this.setChangeQty(this.products[i].seq_pd,this.products[i].qty);
      }
    },
    setChangeQty(item, qty){
      axios.post(process.env.MIX_BACK_URL+'/api/change_qty',{
        item: item,
        qty: qty,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          console.log(response.data);
          if(response.data.msg){
            alert(response.data.msg);
          }

        });
    },*/
    // 장바구니 아이템 삭제
/*    removeItem(e,i){
      axios.post(process.env.MIX_BACK_URL+'/api/remove_cart',{
        item: this.products[i].seq_pd,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          console.log(response.data);
          if(response.data.msg){
            alert(response.data.msg);
          }
          this.getCart(window.Laravel.bz_cd);
        });
    },*/
    // 쇼핑 계속하기
    prevUrl(){
      // console.log(window.history);
      window.history.go(-1);//
      // this.$router.go（-1）;
    },
    // 장바구니 금액 합산
 /*   totalCost(data){  // 토탈 금액 합산 기능
      this.sum_item.price = this.sumPrice(data);
      this.sum_item.vat = this.sumVat(data);
      this.sum_item.amt = this.sumAmt(data);
      this.sum_item.bv = this.sumBV(data);
      this.total_cost = this.sum_item.amt;
      if(this.shipping_selected) this.total_cost += parseFloat(this.shipping_selected);
      if(this.tax_selected) this.total_cost += parseFloat(this.tax_selected);
    },*/
 /*   sumPrice(data) { // 상품 금액 * 수량 합산
      return [].concat.apply([],
        data.map(el => el))
        .reduce((acc, el) => acc + +(el.prices.price*el.qty), 0);
    },
    sumVat(data) { // 상품 금액 * 수량 합산
      return [].concat.apply([],
        data.map(el => el))
        .reduce((acc, el) => acc + +(el.prices.vat*el.qty), 0);
    },
    sumAmt(data) { // 상품 금액 * 수량 합산
      return [].concat.apply([],
        data.map(el => el))
        .reduce((acc, el) => acc + +(el.prices.amt*el.qty), 0);
    },
    sumBV(data) { // 상품 금액 * 수량 합산
      return [].concat.apply([],
        data.map(el => el))
        .reduce((acc, el) => acc + +(el.prices.pv*el.qty), 0);
    },
    change_shipping_fee(){  // 배송비 합산
      this.totalCost(this.products);
      // this.total_cost = this.add_shipping_fee();
    },
    change_tax_fee(){
      this.totalCost(this.products);
    },*/
    // 숫자 포멧
    formatPrice(value,digit) {
      let val = (value/1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
      formatPrices(value,digit) {
          let val = (value/1).toFixed(digit).replace('.', '.')
          return val
      },

    pay_proc(){
        let form = document.frmPayment;
        form.action = location.protocol.concat("//").concat(location.host)+"/pay";
        form.method = "post";
        form._token.value = this.csrf;
        form.PaymentID.value = this.request["payment_id"];
        form.OrderNumber.value = this.request["order_seq"];
        form.Amount.value = this.request["pay_total_amt"];
        form.reminder_amt.value = this.reminder_amt;
        form.heart_use.value = this.heart_use;
        form.CustIP.value = window.Laravel.clientIp;
        form.CustName.value = this.request["name"]; //+' '+this.request["lastName"];
        form.CustEmail.value = this.request["email"]??$("#email").val();
        form.CustPhone.value =this.request["mobile"].replace(/-/g,""); //this.request["mobile"];
        form.HashValue.value = this.hashdata;
        form.MerchantReturnURL.value = location.protocol.concat("//").concat(location.host)+"/api/payResult";
        form.MerchantCallbackURL.value = process.env.MIX_BACK_URL+"/api/payCallBack";
        // form.MerchantCallbackURL.value = location.protocol.concat("//").concat(location.host)+"/api/payCallBack";
        form.submit();
    },
      heart_pay_proc(){
          let form = document.frmPayment;
          form.action = location.protocol.concat("//").concat(location.host)+"/api/heartpay";
          form.method = "post";
          form._token.value = this.csrf;
          form.PaymentID.value = this.request["payment_id"];
          form.OrderNumber.value = this.request["order_seq"];
          form.Amount.value = this.request["pay_total_amt"];
          form.reminder_amt.value = this.reminder_amt;
          form.heart_use.value = this.heart_use;
          form.CustIP.value = window.Laravel.clientIp;
          form.CustName.value = this.request["name"]; //+' '+this.request["lastName"];
          form.CustEmail.value = this.request["email"]??$("#email").val();
          form.CustPhone.value =this.request["mobile"].replace(/-/g,""); //this.request["mobile"];
          form.HashValue.value = this.hashdata;
          form.MerchantReturnURL.value = location.protocol.concat("//").concat(location.host)+"/api/payResult";
          form.MerchantCallbackURL.value = process.env.MIX_BACK_URL+"/api/payCallBack";
          // form.MerchantCallbackURL.value = location.protocol.concat("//").concat(location.host)+"/api/payCallBack";
          form.submit();
      },

      payex_proc(){
          let form = document.frmPayment;
          form.action = location.protocol.concat("//").concat(location.host)+"/payex";
          form.method = "post";
          form._token.value = this.csrf;
          form.PaymentID.value = this.request["payment_id"];
          form.OrderNumber.value = this.request["order_seq"];
          form.Amount.value = this.request["pay_total_amt"];
          form.reminder_amt.value = this.reminder_amt;
          form.heart_use.value = this.heart_use;
          form.CustIP.value = window.Laravel.clientIp;
          form.CustName.value = this.request["name"]; //+' '+this.request["lastName"];
          form.CustEmail.value = this.request["email"]??$("#email").val();
          form.CustPhone.value =this.request["mobile"].replace(/-/g,""); //this.request["mobile"];
          form.HashValue.value = this.hashdata;
          form.MerchantReturnURL.value = location.protocol.concat("//").concat(location.host)+"/api/payResult";
          form.MerchantCallbackURL.value = process.env.MIX_BACK_URL+"/api/payCallBack";
          // form.MerchantCallbackURL.value = location.protocol.concat("//").concat(location.host)+"/api/payCallBack";
          form.submit();
      },
      payDollar_proc(){
        console.log(this.csrf);
          let form = document.frmPayment;
          form.action = location.protocol.concat("//").concat(location.host)+"/payDollar";
          form.method = "post";
          form._token.value = this.csrf;
          form.PaymentID.value = this.request["payment_id"];
          form.OrderNumber.value = this.request["order_seq"];
          form.Amount.value = this.request["pay_total_amt"];
          form.reminder_amt.value = this.reminder_amt;
          form.heart_use.value = this.heart_use;
          form.CustIP.value = window.Laravel.clientIp;
          form.CustName.value = this.request["name"]; //+' '+this.request["lastName"];
          form.CustEmail.value = this.request["email"]??$("#email").val();
          form.CustPhone.value =this.request["mobile"].replace(/-/g,""); //this.request["mobile"];
          form.HashValue.value = this.hashdata;
          // form.MerchantCallbackURL.value = location.protocol.concat("//").concat(location.host)+"/api/payCallBack";
          form.submit();
      },
      close_address_search(){
          this.address_search_open = false;
      },

  }

}
</script>
<style>
.pay_box input[type="radio"]:checked + span {
    display: block;
}

.pay_box_select {
    background-color: rgba(79,70,229,0.1);
    border:2px solid #5e79d1;
    color:#375679;
}
.pay_box_select>span>svg {
    color:#375679;
}

.before_cont::before {
    content: "■ ";
    color:#dce0ef;
}

</style>
