<template>
    <div id="detail_member" v-show="detailshow">
        <div style="height:20px;width: 300px;">{{ i18n.myoffice.refund.profile }}
            <span class="close_detail" v-on:click="close()">[X]</span>
        </div> <!--  회원정보 -->
        <table class="relative overflow-x-auto shadow-md sm:rounded-lg text-sm">
            <tr>
                <td class="field">{{ i18n.myoffice.Country  }}</td>
                <td>{{ bzCd }}</td>
            </tr>
            <tr>
                <td class="field">{{ i18n.myoffice.MemberId }}</td>
                <td v-if="userid == login_id">{{userid}}</td>
                <td v-else="">
                  <span class='m_favorites' title='Member Favorites' v-show="fav_cnt != 0" v-on:click="favorChk(index,fav_cnt,userid)"><i class="fas fa-star fav_on" aria-hidden="true"></i>{{userid}}</span>
                  <span class='m_favorites' title='Member Favorites' v-show="fav_cnt == 0" v-on:click="favorChk(index,fav_cnt,userid)"><i class="far fa-star fav_off" aria-hidden="true"></i>{{userid}}</span>
                </td>
            </tr>
            <tr v-show="usshow">
                <td class="field">{{ i18n.myoffice.email }}</td><td>{{ e_mail }}</td>
            </tr>
            <tr>
                <td class="field"></td>
                <td><a v-on:click="goTree" style="color:red;">[{{ i18n.myoffice.View }}]</a></td></tr>
            <tr v-show="statusshow"><td class="field">{{ i18n.myoffice.Rank }}</td><td>{{ rankName }}</td></tr> <!-- 직급 -->
            <tr><td class="field">{{ i18n.myoffice.MemCount2 }}</td><td>{{ posCnt }}</td></tr>
            <tr v-show="statusshow"><td class="field">{{ i18n.myoffice.RegistrationDate }}</td><td>{{ regDate }}</td></tr> <!-- 가입일 -->
            <tr v-show="statusshow"><td class="field">{{ i18n.myoffice.BoxChart.LastOrder }}</td><td>{{ ordDate }}</td></tr>
            <tr v-show="statusshow"><td class="field">{{ i18n.myoffice.BoxChart.NoOrderDate }}</td><td>{{ dayDiff }}</td></tr>
            <tr v-show="statusshow"><td class="field">{{ i18n.shop.mypage.AutomaticReorder }} </td><td>{{ gpStatuss }}</td></tr>
            <tr><td class="field">{{ i18n.myoffice.Register.WithDrawalDate }} </td><td style="color:red;">{{ secederDate }}</td></tr><!-- 탈퇴일 -->
            <tr><td class="field">{{ i18n.myoffice.EnrollmentSponsor }}</td><td v-html="r_info"></td></tr>

            <tr v-show="jpshow"><td class="field">{{ i18n.myoffice.PlacementSponsor }}</td><td v-html="p_info"></td></tr> <!-- 우측 좌측 -->
            <tr v-show="rankshow"><td class="field">{{ i18n.menu.underMemberInfo }}</td><td><a :href="underUrl" style="color:red;">[{{ i18n.myoffice.move }}]</a></td></tr>
            <tr><td class="field">{{ i18n.menu.underMemberInfo }}</td><td><a :href="underUrl" style="color:red;">[{{ i18n.myoffice.move }}]</a></td></tr>

            <tr v-show="userkindshow">
                <td class="field">{{ i18n.myoffice.dashboard.goUrl }}</td>
                <td>
                    <span style="cursor:pointer; padding:0px 5px 5px 5px;" v-on:click="copy_register_url" >
                    <i class="fa fa-clone" aria-hidden="true"></i> {{ i18n.myoffice.Copy }}
                    </span>
                </td>
            </tr><!-- 복사 -->
        </table>
    </div>
</template>

<script>

import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "LegDetail",
    data: () => ({
        i18n: window.i18n,
        nodes : [],
        detailshow :false,
        bzCd : "",
        e_mail : "",
        userid : "",
        rankName : "",
        posCnt : "",
        regDate : "",
        ordDate : "",
        dayDiff : "",
        gpStatuss : "",
        secederDate : "",
        p_info : "",
        r_info : "",
        statusshow : false,
        usshow : false,
        userkindshow : false,
        jpshow : false,
        rankshow : false,
        xid : "",
        rankcd : false,
        enc_id : false,
        locale : window.Laravel.bz_cd,
        underUrl : "",
        fav_cnt : "",
    }),
    methods:{
        setDetailData(userid){
            axios.post(window.Laravel.back_url+'/api/leg/detail',{
                level : level.value,
                tree_node : tree_node.value,
                user_id : userid,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.detailshow = true;
                this.bzCd = response.data.bzCd;
                this.userid = response.data.userid;
                this.login_id = response.data.login_id;
                this.fav_cnt = response.data.fav_cnt;
                this.e_mail = response.data.e_mail;
                this.rankName = response.data.rankName;
                this.posCnt = response.data.posCnt;
                this.regDate = response.data.regDate;
                this.ordDate = response.data.ordDate;
                this.dayDiff = response.data.dayDiff;
                this.gpStatuss = response.data.gpStatuss;
                this.secederDate = response.data.secederDate;
                this.p_info = response.data.p_info;
                this.r_info = response.data.r_info;
                this.xid = response.data.xid;
                this.rankcd = response.data.rank_cd;
                this.underUrl = "/underMemberInfo/"+response.data.end_id;
                if(response.data.status != 0){
                    this.statusshow = true;
                }
                if(this.locale == "us"){
                    this.usshow = true;
                }
                if(response.data.user_kind != 0){
                    this.userkindshow = true;
                    if(this.locale != "jp"){
                        this.jpshow = true;
                    }
                    if((this.locale == "kr" || this.locale == "jp" || this.locale == "my") && response.data.rank_cd > 10){
                        this.rankshow = true;
                    }
                }
            });

        },
        close(){
            this.detailshow = false;
        },
        goTree(){
            this.$emit("goTree",this.userid);
        },
        copy_register_url(){
            this.detailshow = false;
            var link = 'https://signup.gcoop.com/RegToken?xid2='+encodeURIComponent(this.xid);

            var md = $.Zebra_Dialog('<textarea id="reg_url">'+link+'</textarea>', {
                width: 1000,
                title:  i18n.myoffice.myofficeAlert.RegisterURL,
                type :  false,
                modal: true,
                overlay_close: false,
                buttons:
                [
                    {caption: 'OK', callback: function() {
                        $('#reg_url').select();
                        document.execCommand('copy');
                    }}
                ]
            });
        },
        favorChk(index,fav_cnt,userid){
          this.loading = true;
          axios.post(window.Laravel.back_url+'/api/setMemberFavorites',{
            fid : userid,
          },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            if(response.data.ret == 1){
              if(fav_cnt == 0){
                this.fav_cnt = 1;
              }
              if(fav_cnt == 1){
                this.fav_cnt = 0;
              }
            }
            VueSimpleAlert.alert(response.data.msg,"","",{allowOutsideClick : false});
            this.loading = false;
          });
        }
    }
}
</script>

<style>
#detail_member  { position: absolute; top: 100px; left:10px; border:#069 5px solid; background:#FFF;padding:5px;  z-index:99999; cursor: move;}
#detail_member .close_detail { position:absolute; color:#FC0; top:0; right:4px; cursor:pointer;}
#detail_member table td { padding:3px 5px; word-break: break-word;}
#reg_url { width: 100%; border: none; height: 30px;}

</style>
