<template>
    <div class="w-full p-4 flex justify-between border-b">
        <h2 class="w-full text-2xl">GCOOP LOYALTY PLAN and conditions</h2>
        <p @click=" close_address_search " class="cursor-pointer p-2">X</p>
    </div>
    <div class="w-full p-2 mb-24 md:mb-4">
        GLP and conditions<br>
        Article 1 Application of the Terms and Conditions<br>
        Members that participate in GLP of GCOOP  by automatic purchase method of credit card (hereafter, "payment method") (hereinafter referred to as "automatic purchase") are applicable to the Terms and Conditions.<br><br>
        Article 2 Withdrawal<br>
        Automatic purchases occur every 4 weeks, and if the member is participating in GLP, the payment will be made on the member's designated payment method without further notice.<br><br>
        Article 3 Liability<br>
        If the funds of the payment method are insufficient as of the payment date, or if payment is not possible due to payment limitation, delinquency, etc., the member shall be liable. In addition, all personal and criminal legal liabilities arising from theft of cards, the use of lost cards, and payment of a card by someone else are the responsibility of the member and the company is not liable.<br><br>
        Article 5 Application and Cancellation<br>
        If a member wants to change their GLP to a product, they need to contact the Help Center or submit a product change on the 1:1 board. If you change the product in MyOffice, the change will be applied from the next shipment. If the member wants to cancel the GLP, they can do so in MyOffice.<br><br>
        Article 6 Payment Standards and Appeals<br>
        Payment for GLP is based on the rates charged by the company. This amount includes the products purchased, shipping costs, and excise taxes, which may change due to product changes or changes in shipping address.<br><br>
        Article 7 Third Parties and Personal information<br>
        The Company may provide customer information (credit card information) to third parties and related merchant services. The Company will not use the information provided for any other purposes than related business.<br><br>
        Article 8 Application of Terms<br>
        Members that register for GLP services through My Office must comply to these Terms and Conditions.<br>
    </div>
</template>

<script>

export default {
    name: "AutoShipAgreeText",
    components : {
        // address_book,
    },
    props:{
        openlayer: Boolean
    },
    data: () => ({
    }),
    methods:{
        close_address_search(){
            // this.openlayer = false;
            this.$emit("close_address_search");
        },

    }
}
</script>

<style scoped>

</style>
