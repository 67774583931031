<template>
    <div id="wrap">
        <input type="hidden" id="a_userid">
        <input type="hidden" id="b_userid">
        <section class="flex_box_quad">
            <dl class="myo_search_list">
                <dt>{{ i18n.myoffice.dashboard.MyFavorites }}</dt>
                <dd>
                    <div class="relative z-50">
                        <vSelect :options="favoriteList" label="label" :reduce="value => value.c_id"  :clearable="false" v-model="selectid"  ></vSelect>
                    </div>
                </dd>
            </dl>
        </section>

        <section class="accordion_notice_box">
            <div class="accordion_notice_item">
                <a class="accordion_notice_tit" href="#n">
                    <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                    {{ i18n.myoffice.myoSearchForm.notice }}
                    <div class="bmenu x7">
                        <span class="btop">
                            <i class="fas fa-chevron-up" aria-hidden="true"></i>
                        </span>
                    </div>
                </a>
                <div class="accordion_notice_cont">
                    <div class="myo_search_notice">
                        <p>If you fail to maintain eligibility, the accrued BV is reset to “0”</p>
                        <p>Accrued BV will be displayed as it is if you maintain eligibility</p>
                        <p>In line with our weekly performance calculation, Roll-Up will be applied every Wednesday</p>
                    </div>
                </div>
            </div>
        </section>

        <div id="content_myoffice" class="clr">
            <div id="content_dashboard">
                <div id="week" style="margin-top:30px;" >
                    <span v-on:click="dashBoardPage(uid, week_cnt2 , 'prv',userid)" style="cursor:pointer;">◀ </span>
                    <b>
                        {{ week_info_title }}
                    </b>
                    <span v-on:click="dashBoardPage(uid, week_cnt2 , 'next',userid)" style="cursor:pointer;"> ▶</span>
                </div>

<!--                <?if(session::get ( 'working_nation', 'kr' ) == "kr"){?>-->
<!--                <div id="totalamtrateDiv" style="font-size:12px; margin-top:20px;">수당 보정률 : <span id="totalamtrate"></span> %</div>-->
<!--                <?}?>-->
                <h2 style="margin: 0 0 10px 0; position: relative;"><em style="float:left;" class="dash_color">{{ i18n.myoffice.dashboard.DashBoardTitle }}</em> <span style='font-size: 14px;'>({{ i18n.myoffice.dashboard.grade  }} : BV)</span></h2><!-- 단위 -->

                <div id="updEnd" style="right: 20px; top: 103px; position: absolute;"></div>
                <div id="close_info">
                    <div class="table_box_left">
                        <div class="position_relate_box">
                            <abbr :title="i18n.myoffice.myofficeAlert.CanTablescroll2" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
                        </div>
                        <div class="table_scroll_box scrollbar_control overflow-x-auto">
                            <table border="0" class="dash_table" summary="{{ i18n.myoffice.dashboard.DashBoardTitle  }}" width="100%">
                            <colgroup>
                                <col width="50%" />
                                <col width="25%" />
                                <col width="25%" />
                            </colgroup>
                            <thead>
                            <tr>
                                <th colspan="3"><span>{{ i18n.myoffice.dashboard.myinformation }}</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>{{ i18n.myoffice.MemberId }}</th>
                                <td colspan="2" id="txt01_userid" class="center">{{ txt01_userid }}</td>
                            </tr>
                            <tr>
                                <th>{{ i18n.myoffice.memberName }}</th>
                                <td colspan="2" id="txt01_username" class="center">{{ txt01_username }}</td>
                            </tr>
                            <tr>
                                <th>{{ i18n.myoffice.dashboard.AcquiredRank }}</th>
                                <td colspan="2" id="txt01_grade" class="center">{{ txt01_grade }}</td>
                            </tr>
                            <tr>
                                <th>{{ i18n.myoffice.dashboard.PersonalQualifications }}</th>
                                <td colspan="2" id="txt01_acquire_rank" class="center">{{ txt01_acquire_rank }}</td>
                            </tr>
                            <tr>
                                <th rowspan="2">{{ i18n.myoffice.dashboard.Maintenance }}</th>
                                <td style=" text-align:center;">{{ i18n.myoffice.dashboard.Local }}</td>
                                <td id="txt01_uji_yn" class="center">{{ txt01_uji_yn }}</td>
                            </tr>
                            <tr>
                                <td style="border-left:1px solid #e9e9e9; text-align:center;">{{ i18n.myoffice.dashboard.Global }}</td>
                                <td id="txt01_uji_yn2" class="center">{{ txt01_uji_yn2 }}</td>
                            </tr>
                            <tr>
                                <th>{{ i18n.myoffice.dashboard.MaintenanceQualificationEndingDate }}</th>
                                <td colspan="2" id="txt01_end_of_uji" class="center">{{ txt01_end_of_uji }}</td>
                            </tr>
                            <tr>
                                <th class="dash_main_color">{{ i18n.myoffice.dashboard.PurchasePeriod }}</th>
                                <td class="dash_main_color center" colspan="2" id="txtUjiEbuy">{{ txtUjiEbuy }}</td>
                            </tr>
                            <tr>
                                <th>{{ i18n.myoffice.dashboard.MaintenanceQualificationEndingDateGlobal }}</th>
                                <td colspan="2" id="txt01_end_of_Guji" class="center">{{ txt01_end_of_Guji }}</td>
                            </tr>
                            <tr>
                                <th class="dash_main_color" v-html="i18n.myoffice.dashboard.PurchasePeriodGlobal"></th>
                                <td class="dash_main_color center" colspan="2" id="txtUjiGEbuy">{{ txtUjiGEbuy }}</td>
                            </tr>
                            <tr v-show="Autoship">
                                <th>{{ i18n.gp.us.AutoshipStatus }}</th>
                                <td colspan="2" id="txt01_autoship_status" class="center">{{ txt01_autoship_status }}</td>
                            </tr>
                            <tr v-show="Autoship">
                                <th>{{ i18n.gp.us.AutoshipNextPayment }}</th>
                                <td colspan="2" id="txt01_autoship_admitsday" class="center">{{ txt01_autoship_admitsday }}</td>
                            </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="table_box_right">
                        <div class="position_relate_box">
                            <abbr :title="i18n.myoffice.myofficeAlert.CanTablescroll2" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
                        </div>
                        <div class="table_scroll_box scrollbar_control overflow-x-auto">
                            <table border="0" class="dash_table" summary="마감실적" width="100%" style="overflow: auto;">
                                <colgroup>
                                    <col width="*" />
                                    <col width="11%" />
                                    <col width="22%" />
                                    <col width="11%" />
                                    <col width="22%" />
                                </colgroup>
                                <thead>
                                <tr>
                                    <th><span>{{ i18n.myoffice.dashboard.SalesInformation }}</span></th>
                                    <th colspan="2"><span>A{{ i18n.myoffice.dashboard.Line }}</span></th>
                                    <th colspan="2"><span>B{{ i18n.myoffice.dashboard.Line }}</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th rowspan="1">{{ i18n.myoffice.bottommemberCount }}</th>
                                    <td colspan="2" id="txt01_week_new_bv_A_C" class="right">{{ txt01_week_new_bv_A_C }}</td>
                                    <td colspan="2" id="txt01_week_new_bv_B_C" class="right">{{ txt01_week_new_bv_B_C }}</td>
                                </tr>
                                <tr>
                                    <th rowspan="1" v-html="i18n.myoffice.dashboard.dashConsumerT2"></th>
                                    <td colspan="2" id="txt01_week_new_bv_A_CT" class="right">{{ txt01_week_new_bv_A_CT }}</td>
                                    <td colspan="2" id="txt01_week_new_bv_B_CT" class="right">{{ txt01_week_new_bv_B_CT }}</td>
                                </tr>
                                <tr>
                                    <th rowspan="4">{{ i18n.myoffice.dashboard.NewlyOccurring }}</th>
                                    <th style="border-left:1px solid #e9e9e9;">{{ i18n.myoffice.dashboard.Local }}</th>
                                    <td id="txt01_week_new_bv_A_L" class="right">{{ txt01_week_new_bv_A_L }}</td>
                                    <th>{{ i18n.myoffice.dashboard.Local }}</th>
                                    <td id="txt01_week_new_bv_B_L" class="right">{{ txt01_week_new_bv_B_L }}</td>
                                </tr>
                                <tr>
                                    <th style="border-left:1px solid #e9e9e9;">{{ i18n.myoffice.dashboard.Global }}</th>
                                    <td id="txt01_week_new_bv_A_G" class="right">{{ txt01_week_new_bv_A_G }}</td>
                                    <th>{{ i18n.myoffice.dashboard.Global }}</th>
                                    <td id="txt01_week_new_bv_B_G" class="right">{{ txt01_week_new_bv_B_G }}</td>
                                </tr>
                                <tr>
                                    <th>{{ i18n.myoffice.dashboard.LineTotal }}</th>
                                    <td id="txt01_week_new_bv_A" class="right">{{ txt01_week_new_bv_A }}</td>
                                    <th>{{ i18n.myoffice.dashboard.LineTotal }}</th>
                                    <td id="txt01_week_new_bv_B" class="right">{{ txt01_week_new_bv_B }}</td>
                                </tr>
                                <tr>
                                    <th style="border-left:1px solid #e9e9e9;">
                                        Consumer
                                        <VTooltip>
                                            <i class="fal fa-question-circle text-[#0abab5]" aria-hidden="true"></i>
                                            <template #popper>
                                                {{ i18n.myoffice.dashboard.dashTooltip1 }}
                                            </template>
                                        </VTooltip>
                                    </th><!-- 컨슈머 -->
                                    <td id="txt01_gfesta_consumer_A" class="right">{{ txt01_gfesta_consumer_A }}</td>
                                    <th>
                                        Consumer
                                        <VTooltip>
                                            <i class="fal fa-question-circle text-[#0abab5]" aria-hidden="true"></i>
                                            <template #popper>
                                                {{ i18n.myoffice.dashboard.dashTooltip1 }}
                                            </template>
                                        </VTooltip>
                                    </th>
                                    <td id="txt01_gfesta_consumer_B" class="right">{{ txt01_gfesta_consumer_B }}</td>
                                </tr>

                                <tr>
                                    <th>{{ i18n.myoffice.dashboard.CurrentMonths }}
                                        <VTooltip>
                                            <i class="fal fa-question-circle text-[#0abab5]" aria-hidden="true"></i>
                                            <template #popper>
                                                {{ i18n.myoffice.dashboard.dashTooltip2 }}
                                            </template>
                                        </VTooltip>
                                    </th><!-- 이월 실적 -->
                                    <td colspan="2" id="txt01_foward_month_A" class="right">{{ txt01_foward_month_A }}</td>
                                    <td colspan="2" id="txt01_foward_month_B" class="right">{{ txt01_foward_month_B }}</td>
                                </tr>
                                <tr>
                                    <th>{{ i18n.myoffice.dashboard.CombinedTotal }}</th>
                                    <td colspan="2" id="txt01_total_PV_A" class="right">{{ txt01_total_PV_A }}</td>
                                    <td colspan="2" id="txt01_total_PV_B" class="right">{{ txt01_total_PV_B }}</td>
                                </tr>
                                <tr>
                                    <th>{{ i18n.myoffice.dashboard.CombinedBVoftheLast4Weeks }}</th>
                                    <td colspan="2" id="txt01_latest4week_A" class="right">{{ txt01_latest4week_A }}</td>
                                    <td colspan="2" id="txt01_latest4week_B" class="right">{{ txt01_latest4week_B }}</td>
                                </tr>
                                <tr>
                                    <th>{{ i18n.myoffice.dashboard.ResidualPromotionalBV }}</th>
                                    <td colspan="2" id="txt01_rest_rank_bv_A" class="right">{{ txt01_rest_rank_bv_A }}</td>
                                    <td colspan="2" id="txt01_rest_rank_bv_B" class="right">{{ txt01_rest_rank_bv_B }}</td>
                                </tr>
                                <tr>
                                    <th>{{ i18n.myoffice.dashboard.PersonalWeeklyPerformance }}</th>
                                    <td colspan="4" id="txt01_thisweek_PV" class="right">{{ txt01_thisweek_PV }}</td>
                                </tr>
                                <tr>
                                    <th v-html="i18n.myoffice.dashboard.DSCWeekCombinedBVTotal2"></th>
                                    <td colspan="4" id="lastRIdOrdPv" class="right">{{ lastRIdOrdPv }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                               <!-- 레이아웃 틀 변경 20210203 -->
                <div id="dadh_info">
                    <ul>
                        <li>
                            <ul>
                                <li class='name'>{{ i18n.myoffice.dashboard.HighestAchievedRank }}</li>
                                <li class='value'><span id="rankMaxName">&nbsp;{{ rankMaxName }}&nbsp;</span></li>
                            </ul>
                            <ul>
                                <li class='name'>{{ i18n.myoffice.dashboard.PersonalBVTotal }}</li>
                                <li class='value'><span id="pvAcc">&nbsp;{{ pvAcc }}&nbsp;</span></li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li class='name'>{{ i18n.myoffice.dashboard.MostRecentPurchaseDate }}
                                    <VTooltip class="inline-block">
                                        <i class="fal fa-question-circle text-[#fff]" aria-hidden="true"></i>
                                        <template #popper>
                                            {{ i18n.myoffice.dashboard.dashTooltip3 }}
                                        </template>
                                    </VTooltip>
                                </li>
                                <li class='value'><span id="lastOrdDate">&nbsp;{{ lastOrdDate }}&nbsp;</span></li>
                            </ul>
                            <ul>
                                <li class='name'>{{ i18n.myoffice.dashboard.MostRecentPurchaseBV }}</li>
                                <li class='value'><span id="lastOrdPv">&nbsp;{{ lastOrdPv }}&nbsp;</span></li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li class='name'>{{ i18n.myoffice.dashboard.DSMRecentPurchaseDate2 }}</li>
                                <li class='value'><span id="lastRIdOrdDate">&nbsp;{{ lastRIdOrdDate }}&nbsp;</span></li>
                            </ul>
                            <ul>
                                <li class='name'>{{ i18n.myoffice.dashboard.ordLastSobi }}</li>
                                <li class='value'><span id="ordLastSobi">&nbsp;{{ ordLastSobi }}&nbsp;</span></li>
                            </ul>
                        </li>
                    </ul>
                </div><!--//dadh_info-->


                <div id="recent4week" v-if="m4_flag == 'Y'">
                    <h2 style="clear:left;" class="dash_color">
                        <em>{{ i18n.myoffice.dashboard.FourWeeksPerformance }}</em>
                        <span style='font-size: 14px;'>{{ i18n.myoffice.dashboard.grade }} : BV</span><!--단위 -->
                        <span id="beforeFourWeek">{{ beforeFourWeek }}</span>
                    </h2>

                    <div class="position_relate_box2">
                        <span class="table_notice_left">{{ i18n.myoffice.dashboard.notice4 }}</span>
                        <abbr :title="i18n.myoffice.myofficeAlert.CanTablescroll2" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
                    </div>

                    <div class="dash-box">
                        <table class="dash_table" summary="최근 4주실적" width="100%"><!--최근 4주실적  -->
                            <colgroup>
                                <col width="20%"/>
                                <col width="*"/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th rowspan="2" style="border-right: 1px solid #fff;">{{ i18n.myoffice.dashboard.date }}</th>
                                <th align="center" colspan="3" style="border-bottom: 1px solid #fff; border-right: 1px solid #fff;"><span id="alinename">{{ alinename }}</span> {{ i18n.myoffice.dashboard.Line }}</th>
                                <th align="center" colspan="3" style="border-bottom: 1px solid #fff;"><span id="blinename">{{ blinename }}</span> {{ i18n.myoffice.dashboard.Line }}</th>
                            </tr>
                            <tr>
                                <th style="border-right: 1px solid #fff;">{{ i18n.myoffice.dashboard.total }}</th> <!-- 합계 -->
                                <th style="border-right: 1px solid #fff;">{{ i18n.myoffice.dashboard.local }}</th><!-- 로컬 -->
                                <th style="border-right: 1px solid #fff;">{{ i18n.myoffice.dashboard.global }}</th><!-- 글로벌 -->
                                <th style="border-right: 1px solid #fff;">{{ i18n.myoffice.dashboard.total }}</th>
                                <th style="border-right: 1px solid #fff;">{{ i18n.myoffice.dashboard.local }}</th>
                                <th>{{ i18n.myoffice.dashboard.global }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="Value in week4Data" >
                                <th>{{ Value.pay_date }}</th><!--합계 -->
                                <td style="padding-right:10px; text-align:right; font-weight: bold;"><span >{{ Value.a_week_tot }}</span></td>
                                <td style="padding-right:10px; text-align:right;"><span >{{ Value.a_week_local_data }}</span></td>
                                <td style="padding-right:10px; text-align:right;"><span >{{ Value.a_week_global_data }}</span></td>
                                <td style="padding-right:10px; text-align:right; font-weight: bold;"><span >{{ Value.b_week_tot }}</span></td>
                                <td style="padding-right:10px; text-align:right;"><span >{{ Value.b_week_local_data }}</span></td>
                                <td style="padding-right:10px; text-align:right;"><span >{{ Value.b_week_global_data }}</span></td>
                            </tr>
                            <tr >
                                <th>{{ i18n.myoffice.dashboard.LineTotal }}</th><!--합계 -->
                                <td style="padding-right:10px; text-align:right; font-weight: bold;"><span >{{ atotalsumPv }}</span></td>
                                <td style="padding-right:10px; text-align:right;"><span >{{ alocalsumPv }}</span></td>
                                <td style="padding-right:10px; text-align:right;"><span >{{ aglobalsumPv }}</span></td>
                                <td style="padding-right:10px; text-align:right; font-weight: bold;"><span >{{ btotalsumPv }}</span></td>
                                <td style="padding-right:10px; text-align:right;"><span >{{ blocalsumPv }}</span></td>
                                <td style="padding-right:10px; text-align:right;"><span >{{ bglobalsumPv }}</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div><!--//dash-box-->
                </div>
            </div>
        </div>
    </div><!-- //wrap -->
</template>

<script>
import vSelect from "vue-select";
import VueSimpleAlert from "vue3-simple-alert";
import 'floating-vue/dist/style.css';
import { Tooltip } from "floating-vue";

export default {

    name: "MyofficeDashBoard",
    components: {
        vSelect,
        VTooltip : Tooltip
    },
    data: () => ({
        i18n: window.i18n,
        txt01_userid : "",
        txt01_username : "",
        txt01_grade : "",
        txt01_acquire_rank : "",
        txt01_uji_yn : "",
        txt01_uji_yn2 : "",
        txt01_end_of_uji : "",
        txtUjiEbuy : "",
        txt01_end_of_Guji : "",
        txtUjiGEbuy : "",
        txt01_autoship_status : "",
        txt01_autoship_admitsday : "",

        txt01_week_new_bv_A_C : "",
        txt01_week_new_bv_B_C : "",
        txt01_week_new_bv_A_CT : "",
        txt01_week_new_bv_B_CT : "",
        txt01_week_new_bv_A_L : "",
        txt01_week_new_bv_B_L : "",
        txt01_week_new_bv_A_G : "",
        txt01_week_new_bv_B_G : "",
        txt01_week_new_bv_A : "",
        txt01_week_new_bv_B : "",

        txt01_foward_month_A : "",
        txt01_foward_month_B : "",
        txt01_total_PV_A : "",
        txt01_total_PV_B : "",
        txt01_latest4week_A : "",
        txt01_latest4week_B : "",
        txt01_rest_rank_bv_A : "",
        txt01_rest_rank_bv_B : "",
        rankMaxName : "",
        pvAcc : "",
        lastOrdDate : "",
        lastOrdPv : "",
        lastRIdOrdDate : "",
        txt01_thisweek_PV : "",
        lastRIdOrdPv : "",
        ordLastSobi : "",
        alinename : "",
        blinename : "",
        atotalsumPv : "",
        alocalsumPv : "",
        aglobalsumPv : "",
        btotalsumPv : "",
        bglobalsumPv : "",
        blocalsumPv : "",
        txt01_gfesta_consumer_A : "",
        txt01_gfesta_consumer_B : "",
        recent4week : "",
        a_userid : "",
        b_userid : "",
        week4Data : [],
        tag2 : "",
        week_info_title : "",
        uid : "",
        week_cnt2: "",
        maxWeekCnt : "",
        maxWeekFirst : 0,
        favoriteList : [],
        userid : login_userid,
        Autoship : true,
        m4_flag : "",

    }),
    beforeCreate() {
        axios.post(window.Laravel.back_url+'/api/getMemberFavor',{
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.getFabourRiteData(response.data.list.data);
        });
    },
    created : function (){
        if(this.selectid == "fail"){
            VueSimpleAlert.alert("잘못된 접근 입니다.","","",{allowOutsideClick : false}).then((result) => {
                if(result){
                    location.replace("/MyofficeMain");
                }
            });
        }
        this.dashBoardPage('','','');
    },
    props : {
        selectid : "",
        no : ""
    },
    watch: {
        userid : function (val){
            this.dashBoardPage('','','');
        },
        selectid : function (val){
            console.log("test");
            this.dashBoardPage('','','');
        }
    },
    methods: {
        getFabourRiteData($list) {
            this.favoriteList.push({
                userid: "",
                c_id: "",
                c_name: "",
                label: "me",
            });

            $list.forEach((value, index) => {
                this.favoriteList.push({
                    userid: value.userid,
                    c_id: value.c_id,
                    c_name: value.c_name,
                    label: value.c_name+"("+value.c_id+")",
                });
            });

        },
        get4WeekData($list) {
            var i = 3;
            this.week4Data  = [];
            for (i; i >= 0; i--) {
                if (i == 3) {
                    var pay_date = $list.A_WEEK_TOT4.d_remark;
                    var a_week_tot_data = $list.A_WEEK_TOT4.d_val;
                    var a_week_local_data = $list.A_WEEK_LOCAL4.d_val;
                    var a_week_global_data = $list.A_WEEK_GLOBAL4.d_val;
                    var b_week_tot_data = $list.B_WEEK_TOT4.d_val;
                    var b_week_local_data = $list.B_WEEK_LOCAL4.d_val;
                    var b_week_global_data = $list.B_WEEK_GLOBAL4.d_val;
                    var d_remark_data = $list.A_WEEK_TOT4.d_remark;
                }
                if (i == 2) {
                    var pay_date = $list.A_WEEK_TOT3.d_remark;
                    var a_week_tot_data = $list.A_WEEK_TOT3.d_val;
                    var a_week_local_data = $list.A_WEEK_LOCAL3.d_val;
                    var a_week_global_data = $list.A_WEEK_GLOBAL3.d_val;
                    var b_week_tot_data = $list.B_WEEK_TOT3.d_val;
                    var b_week_local_data = $list.B_WEEK_LOCAL3.d_val;
                    var b_week_global_data = $list.B_WEEK_GLOBAL3.d_val;
                    var d_remark_data = $list.A_WEEK_TOT4.d_remark;
                }
                if (i == 1) {
                    var pay_date = $list.A_WEEK_TOT2.d_remark;
                    var a_week_tot_data = $list.A_WEEK_TOT2.d_val;
                    var a_week_local_data = $list.A_WEEK_LOCAL2.d_val;
                    var a_week_global_data = $list.A_WEEK_GLOBAL2.d_val;
                    var b_week_tot_data = $list.B_WEEK_TOT2.d_val;
                    var b_week_local_data = $list.B_WEEK_LOCAL2.d_val;
                    var b_week_global_data = $list.B_WEEK_GLOBAL2.d_val;
                    var d_remark_data = $list.A_WEEK_TOT4.d_remark;
                }
                if (i == 0) {
                    var pay_date = $list.A_WEEK_TOT1.d_remark;
                    var a_week_tot_data = $list.A_WEEK_TOT1.d_val;
                    var a_week_local_data = $list.A_WEEK_LOCAL1.d_val;
                    var a_week_global_data = $list.A_WEEK_GLOBAL1.d_val;
                    var b_week_tot_data = $list.B_WEEK_TOT1.d_val;
                    var b_week_local_data = $list.B_WEEK_LOCAL1.d_val;
                    var b_week_global_data = $list.B_WEEK_GLOBAL1.d_val;
                    var d_remark_data = $list.A_WEEK_TOT4.d_remark;
                }
                this.week4Data.push({
                    pay_date: pay_date,
                    remark: d_remark_data,
                    a_week_tot: a_week_tot_data,
                    b_week_tot: b_week_tot_data,
                    a_week_local_data: a_week_local_data,
                    a_week_global_data: a_week_global_data,
                    b_week_local_data: b_week_local_data,
                    b_week_global_data: b_week_global_data,

                });
            }

            this.weekATotal = $list.A_WEEK_TOT.d_val;
            this.weekBTotal = $list.B_WEEK_TOT.d_val;

        },
        setTitleWeek($list) {
            //data.title = $list.week_info.title.replace("주차",i18n.myoffice.dashboard.Week);
            this.week_info_title = $list.week_info.title.replace("주차",i18n.myoffice.dashboard.Week);
            if(this.maxWeekFirst == "0"){
                this.maxWeekFirst = 1;
                this. maxWeekCnt = $list.week_info.week_cnt2;
            }
            this.week_cnt2 = $list.week_info.week_cnt2;

            //this.uid = $list.week_info.uid;
        },
        dashBoardPage: function(uid,weekCnt,prv){
            if(prv == "next"){
                if(this.maxWeekCnt > this.week_cnt2){
                    weekCnt++;
                }
            }else if(prv == "prv"){
                weekCnt--;
            }
            axios.post(window.Laravel.back_url+'/api/dashboard', {
                uid : uid, weekCnt : weekCnt, selectId : this.selectid,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.title = response.data.B_BV.d_val;
                this.txt01_userid = response.data.mem_info.userid;
                this.txt01_username = response.data.mem_info.username;
                this.txt01_grade = response.data.USER_KIND.d_val;
                this.txt01_acquire_rank = response.data.RANK_NEW.d_val;
                this.txt01_uji_yn = response.data.UJI_LOCAL.d_code == "Y" ? i18n.myoffice.active : i18n.myoffice.inactive ;
                this.txt01_uji_yn2 = response.data.UJI_GLOBAL.d_code == "Y" ? i18n.myoffice.active : i18n.myoffice.inactive;
                this.txtUjiEbuy = response.data.UJI_LOCAL_SALE_DATE.d_val;
                this.txt01_end_of_uji = response.data.UJI_LOCAL_EDATE.d_val;
                this.txt01_end_of_Guji = response.data.UJI_GLOBAL_EDATE.d_val;
                this.txtUjiGEbuy = response.data.UJI_GLOBAL_SALE_DATE.d_code + "~" + response.data.UJI_GLOBAL_SALE_DATE.d_val ;
                this.txt01_autoship_status = response.data.AS_ADMIT_DAY_KIND.d_code == "Y" ? i18n.myoffice.active : i18n.myoffice.inactive ;
                this.txt01_autoship_admitsday = response.data.AS_ADMIT_DAY.d_val;

                this.txt01_week_new_bv_A_C = response.data.A_CNT.d_val;
                this.txt01_week_new_bv_B_C = response.data.B_CNT.d_val;
                this.txt01_week_new_bv_A_CT = response.data.A_CNT_T.d_val;
                this.txt01_week_new_bv_B_CT = response.data.B_CNT_T.d_val;

                this.txt01_week_new_bv_A_L = response.data.A_PV_LOCAL.d_val;
                this.txt01_week_new_bv_B_L = response.data.B_BV_LOCAL.d_val;
                this.txt01_week_new_bv_A_G = response.data.A_BV_GLOBAL.d_val;
                this.txt01_week_new_bv_B_G = response.data.B_BV_GLOBAL.d_val;
                this.txt01_week_new_bv_A = response.data.A_BV.d_val;
                this.txt01_week_new_bv_B = response.data.B_BV.d_val;

                this.txt01_foward_month_A = response.data.A_BV_BE.d_val;
                this.txt01_foward_month_B = response.data.B_BV_BE.d_val;
                this.txt01_total_PV_A = response.data.A_BV_CALC.d_val;
                this.txt01_total_PV_B = response.data.B_BV_CALC.d_val;
                this.txt01_latest4week_A = response.data.A_PV_RANK.d_val;
                this.txt01_latest4week_B = response.data.B_PV_RANK.d_val;
                this.txt01_rest_rank_bv_A = response.data.A_NEED_PV.d_val;
                this.txt01_rest_rank_bv_B = response.data.B_NEED_PV.d_val;

                this.rankMaxName = response.data.RANK_MAX.d_val;
                this.pvAcc = response.data.ORD_BV_ACC.d_val;
                this.lastOrdDate = response.data.ORD_MAX_DATE.d_val;
                this.lastOrdPv = response.data.ORD_BV.d_val;
                this.lastRIdOrdDate = response.data.ORD_MAX_DATE_R.d_val;
                this.txt01_thisweek_PV = response.data.ORD_BV_WEEK.d_val;
                this.lastRIdOrdPv = response.data.MY_SOBI.d_val;
                this.ordLastSobi = response.data.ORD_LAST_SOBI.d_val;

                this.alinename = response.data.A_USERID.d_code+"("+response.data.A_USERID.d_val+")";
                this.blinename = response.data.B_USERID.d_code+"("+response.data.B_USERID.d_val+")";
                this.atotalsumPv = response.data.A_WEEK_TOT.d_val;
                this.alocalsumPv = response.data.A_WEEK_LOCAL_TOT.d_val;
                this.aglobalsumPv = response.data.A_WEEK_GLOBAL_TOT.d_val;
                this.btotalsumPv = response.data.B_WEEK_TOT.d_val;
                this.blocalsumPv = response.data.B_WEEK_LOCAL_TOT.d_val;
                this.bglobalsumPv = response.data.B_WEEK_GLOBAL_TOT.d_val;
                this.txt01_gfesta_consumer_A = response.data.A_PV_CG.d_val;
                this.txt01_gfesta_consumer_B = response.data.B_PV_CG.d_val;
                this.m4_flag = response.data.m4_flag;

                this.get4WeekData(response.data);
                this.setTitleWeek(response.data);
            });
        }
    }
}
</script>
<style >
.v-popper--theme-tooltip {
   max-width: 400px !important;
}
.vs--single .vs__selected{
  line-height: 1.5;
}
</style>
