<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <modalView class="z-50" v-if="address_book_open" @close-modal="address_book_open = false" :harf="true"  >
        <address_book @get_info_address_book="get_info_address_book" @close_address_book="close_address_book" />
    </modalView>
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
        <address_search @set_address="set_address" @close_address_search="close_address_search" />
    </modalView>

    <!--    <div>-->
    <!--      <address_book @close_address_book="close_address_book" @get_info_address_book="get_info_address_book" v-if="address_book_open" />-->
    <!--  </div>-->
    <section class="container mx-auto py-6">
        <div class="bg-white">
            <div class="flex justify-between pb-8">
                <button @click="link('/cart')"  class="bg-white font-semibold border hover:bg-gray-300 py-3 text-sm text-black uppercase w-full"><a >Cart({{ other_products.length }})</a></button>
                <button @click="link('/AsCart')"  class="bg-indigo-500 font-semibold  hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full"><a >GCOOP LOYALTY PLAN({{ products.length }})</a></button>
            </div>

            <div class="max-w-8xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
                <div class="flex flex-wrap">
                    <div class="w-full sm:w-3/4 bg-white sm:px-10">
                        <div class="flex justify-between pb-8">
                            <h1 class="font-semibold text-2xl">Shopping Cart</h1>
                            <h2 class="font-semibold text-lg md:text-xl">{{ products.length }} Items</h2>
                        </div>
                        <div class="flex justify-end border-b pb-2 "><button class="mt-1 p-1 text-sm text-white bg-indigo-400 rounded" @click="removeSelect">Remove selected items</button></div>
                        <div class="mt-6 mb-5 hidden lg:flex" >
                            <h3 class="font-semibold text-gray-600 text-xs uppercase w-full sm:w-3/6">Product Details</h3>
                            <h3 class="font-semibold text-center text-gray-600 text-xs uppercase w-1/3 sm:w-1/6 text-center">Quantity</h3>
                            <h3 class="font-semibold text-center text-gray-600 text-xs uppercase w-1/3 sm:w-1/6 text-center">Price</h3>
                            <h3 class="font-semibold text-center text-gray-600 text-xs uppercase w-1/3 sm:w-1/6 text-center">Total</h3>
                        </div>
                        <!--// 장바구니 제품 목록-->
                        <div v-for="(product, i) in products" :key="product.product.seq_pd" v-if="isEnterProduct" class="sm:flex sm:items-center  px-2 py-5 border-b border-slate-200 last:border-b-0">
                            <input type="checkbox" :name="product.product.seq_pd" :value="product.product.seq_pd" v-model="selectedItems">
                            <div class="flex w-full sm:w-1/2"> <!-- product -->
                                <a :href="'/product/item/'+product.product.seq_pd">
                                    <div class="relative w-24 aspect-w-1 aspect-h-1">
                                        <img class="w-24 h-24 max-w-none	" v-bind:src="'http://cdn.gcoop.me/public' + product.product.images[0].path + '/' + product.product.images[0].filename" v-bind:alt=" product.product.images[0].filename " alt="">
                                    </div>
                                </a>
                                <div class="flex flex-col justify-between ml-4 flex-grow relative">
                                    <a :href="'/product/item/'+product.seq_pd" class="block h-full">
                                        <span class="font-bold text-base">{{ product.product.pdt_name }}</span>
                                        <div class="text-red-500 text-xs lg:text-sm" v-if="product.product.deli_date2" v-html="product.product.deli_date2"></div>
                                        <div class="text-right absolute bottom-0 right-0 z-[5]">
                                            <a href="#" v-on:click.prevent="removeItem($event,i)" class="font-semibold hover:text-red-500 text-gray-500 text-xs border border-slate-400 inline-block p-1">Remove</a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="flex w-full sm:w-1/2 pt-3 sm:pt-0">
                                <div class="flex justify-center w-1/3">
                                    <div class="inline-flex items-center px-4 sm:px-3 font-semibold font-heading text-gray-500 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md">
                                        <button @click="decrement($event,i)" class="py-2 sm:py-1 hover:text-gray-700">
                                            <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-zinc-600 text-blue-400"><g opacity="0.35"><rect x="12" width="2" height="12" transform="rotate(90 12 0)" fill="currentColor"></rect></g></svg>
                                        </button>
                                        <input type="number" name="qty" v-model="product.qty" @input="changeQty($event,i)" class="w-12 m-0 px-2 py-2 sm:py-1 text-center border-0 focus:ring-transparent focus:outline-none rounded-md" id="custom-input-number">
                                        <button @click="increment($event,i)" class="py-2 sm:py-1 hover:text-gray-700">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-zinc-600 text-blue-400"><g opacity="0.35"><rect x="5" width="2" height="12" fill="currentColor"></rect><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="currentColor"></rect></g></svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="text-center w-1/3 font-semibold text-sm">
                                    <p class="text-sm lg:text-lg">RM{{ formatPrice(product.product.prices.amt,2) }}</p>
                                    <p class="text-cyan-400">{{ formatPrice(product.product.prices.pv,1) }}BV</p>
                                </div>
                                <div class="text-center w-1/3 font-semibold text-sm">
                                    <p class="text-sm lg:text-lg">RM{{ formatPrice(product.product.prices.amt * product.qty,2) }}</p>
                                    <p class="text-cyan-400">{{ formatPrice(product.product.prices.pv * product.qty,1) }}BV</p>
                                </div>
                            </div>

                        </div>
                        <!--장바구니 제품 목록 //-->
                        <div class="flex justify-end pt-2"><button class="mt-1 p-1 text-sm text-white bg-indigo-400 rounded" @click="removeSelect">Remove selected items</button></div>

                        <div class="w-full text-center mb-12 pt-3 " v-if="isEnterShipping && isEnterProduct==false">
                            <button @click="isEnterProduct = !isEnterProduct" class="bg-indigo-400 text-white px-10 py-2 rounded-xl">View Product Details</button>
                        </div>
                        <div class="flex justify-between">
                            <a href="/public" v-on:click.prevent="prevUrl" class="flex font-semibold text-indigo-600 text-sm my-10">
                                <svg class="fill-current mr-2 text-indigo-600 w-4" viewBox="0 0 448 512"><path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"/></svg>
                                Continue Shopping
                            </a>
                        </div>


                        <div class="w-full text-center mb-12">
                            <button v-if="!isEnterShipping" @click="isEnterShipping = !isEnterShipping, isEnterProduct = !isEnterProduct" class="bg-indigo-400 text-white px-10 py-2 rounded-xl">Enter shipping information</button>
                            <button v-if="isEnterShipping" @click="isEnterShipping = !isEnterShipping" class="bg-gray-200 text-white px-10 py-2 rounded-xl">Close shipping information</button>
                        </div>
                        <div class="mb-12" v-if="isEnterShipping">
                            <div class="flex justify-between border-b pb-8">
                                <h1 class="font-semibold text-2xl">Shipping Address</h1>
                            </div>
                            <form name="shipping_info" class="justify-center w-full mx-auto" method="post">
                                <input type="hidden" name="_token" :value="csrf">
                                <input type="hidden" name="order_seq">
                                <input type="hidden" name="shipping_fee">
                                <input type="hidden" name="tax_fee">
                                <input type="hidden" name="pay_total_amt">
                                <input type="hidden" name="street" :value='this.shipping_info["d_street"]'>
                                <input type="hidden" name="postcode" :value='this.shipping_info["d_post"]'>
                                <input type="hidden" name="city" :value='this.shipping_info["d_city"]'>
                                <input type="hidden" name="state" :value='this.shipping_info["d_state"]'>
                                <div class="mt-10">

                                    <div class="space-x-0 sm:flex lg:space-x-4">
                                        <div class="w-full flex sm:w-1/3" v-show="false" >
                                            <input name="order-info" id="order-info1" ref="order-info1" type="radio" class="mt-0.5" value="1" @click="change_shipping('Same')" >
                                            <label for="order-info1" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                Same as My information</label>
                                        </div>
                                        <div class="w-full flex sm:w-1/3">
                                            <input name="order-info" id="order-info2" type="radio" class="mt-0.5" value="2" @click="change_shipping('Enroll')" checked>
                                            <label for="order-info2" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                Enroll shipping addresses</label>
                                        </div>
                                        <div class="w-full flex sm:w-1/3" >
                                            <input name="order-info" id="order-info3" type="radio" class="mt-0.5" value="3" @click="change_shipping('Manage')">
                                            <label for="order-info3" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                Manage shipping addresses</label>
                                        </div>
                                        <div class="w-full flex sm:w-1/3" v-show="false" >
                                            <input name="order-info" id="order-info4" type="radio" class="mt-0.5" value="4" @click="change_shipping('Academi')">
                                            <label for="order-info4" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                아카데미 배송</label>
                                        </div>

                                    </div>
                                    <div class="relative pt-3 xl:pt-6"  v-show="false">
                        <span v-show="is_academi_order == true">
                            매주 목요일에 배송됩니다.<br>
                             설명1<br>
                             설명2
                        </span>
                                        <vSelect name="state" id="center" ref="center" :options="center_list" label="cnt_name" :reduce="value => value.cnt_cd" v-model='cnt_cd' :clearable="false"
                                                 @option:selected="center_sel" v-show="mem_center == '0600' && is_academi_order == true"
                                                 class="block w-full text-gray-600 text-sm"></vSelect>

                                    </div>
                                    <div class="mt-4 space-x-0 lg:flex lg:space-x-4">
                                        <div class="w-full lg:w-1/2">
                                            <label for="firstName" class="block mb-3 text-sm font-semibold text-gray-500">Reciever</label>
                                            <input name="name" id="firstName" ref="d_name" type="text" placeholder="First Name" maxlength="45" v-model='this.shipping_info["d_name"]'
                                                   class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                        </div>
                                        <div class="w-full lg:w-1/2 mt-2 lg:mt-0 ">
                                            <label for="mobile" class="block mb-3 text-sm font-semibold text-gray-500">Mobile</label>
                                            <input name="mobile" id="mobile" ref="d_mobile" type="text" @keyup="regNumber('d_mobile')" placeholder="Mobile" v-model='this.shipping_info["d_mobile"]'
                                                   class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                            <!--                      <label for="lastName" class="block mb-3 text-sm font-semibold text-gray-500">Last Name</label>-->
                                            <!--                      <input name="lastName" id="lastName" ref="d_name2" type="text" placeholder="Last Name" v-model='this.shipping_info["d_name2"]'-->
                                            <!--                             class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">-->
                                        </div>
                                    </div>
                                    <!--                  <div class="mt-4">-->
                                    <!--                    <div class="w-full">-->
                                    <!--                      <label for="mobile" class="block mb-3 text-sm font-semibold text-gray-500">Mobile</label>-->
                                    <!--                      <input name="mobile" id="mobile" ref="d_mobile" type="text" placeholder="Mobile" v-model='this.shipping_info["d_mobile"]'-->
                                    <!--                             class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">-->
                                    <!--                    </div>-->
                                    <!--                  </div>-->

                                    <div class="mt-4 space-x-0 lg:flex lg:space-x-4">
                                        <div class="relative z-[14] w-full bg-white lg:w         -1/2">
                                            <label for="state" class="block mb-3 text-sm font-semibold text-gray-500">State</label>
                                            <!--                      <vSelect name="state" id="state" ref="d_state" :options="state_list" v-model='this.shipping_info["d_state"]' :clearable="false" @option:selected="onChangeState"-->
                                            <!--                               class="block w-full text-gray-600 text-sm"></vSelect>-->
                                            <input name="address" id="state" ref="d_state" type="text" placeholder="" v-model='this.shipping_info["d_state"]' readonly @click="search_address"
                                                   class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="40">

                                        </div>
                                        <div class="relative z-[13] lg:mt-0 mt-2 w-full bg-white lg:w-1/2 ">
                                            <label for="city" class="block mb-3 text-sm font-semibold text-gray-500">City</label>
                                            <!--                      <vSelect name="city" id="city" ref="d_city" :options="city_list" v-model='this.shipping_info["d_city"]' :clearable="false" @option:selected="onChangeCity"-->
                                            <!--                               class="block w-full text-gray-600 text-sm"></vSelect>-->
                                            <input name="city" id="city" ref="d_city" type="text" placeholder="" v-model='this.shipping_info["d_city"]' readonly @click="search_address"
                                                   class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="40">

                                        </div>
                                    </div>
                                    <div class="mt-4 space-x-0 lg:flex lg:space-x-4">
                                        <div class="relative z-[12] w-full bg-white lg:w-1/2">
                                            <label for="street" class="block mb-3 text-sm font-semibold text-gray-500">Street</label>
                                            <!--                      <vSelect label="street" name="street" id="street" ref="d_street" :options="street_list" v-model='this.shipping_info["d_street"]' :clearable="false" @option:selected="onChangeStreet"-->
                                            <!--                               class="block w-full text-gray-600 text-sm"></vSelect>-->
                                            <input name="street" id="street" ref="d_street" type="text" placeholder="Please enter up to 40 characters." :readonly="is_academi_order == true" v-model='this.shipping_info["d_street"]'
                                                   @keyup="regText('d_street')" class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="40">

                                        </div>
                                        <div class="relative z-[11] lg:mt-0 mt-2 w-full bg-white lg:w-1/2 ">
                                            <label for="postcode" class="block mb-3 text-sm font-semibold text-gray-500">Postcode</label>
                                            <input name="postcode" id="postcode" ref="postcode" type="text" placeholder="" v-model='this.shipping_info["d_post"]' readonly @click="search_address"
                                                   class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 cursor-pointer" maxlength="40">
                                        </div>
                                    </div>
                                    <div class="relative pt-3 xl:pt-6" v-if="msg_post !== ''">
                                        <div class="text-red-500">{{ this.msg_post }}</div>
                                    </div>
                                    <div class="mt-4 space-x-0 lg:flex lg:space-x-4">
                                        <div class="w-full bg-white lg:w-1/2">
                                            <label for="address" class="block mb-3 text-sm font-semibold text-gray-500">House Number / Block Number</label>
                                            <input name="address" id="address" ref="d_address" type="text" placeholder="Please enter up to 40 characters." :readonly="is_academi_order == true" v-model='this.shipping_info["d_address"]'
                                                   @keyup="regText('d_address')"  class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="40">
                                        </div>
                                        <div class="lg:mt-0 mt-2 w-full bg-white lg:w-1/2">
                                            <label for="address2" class="block mb-3 text-sm font-semibold text-gray-500">Name of building / Name of neighborhood</label>
                                            <input name="address2" id="address2" ref="d_address2" type="text" placeholder="Please enter up to 40 characters." :readonly="is_academi_order == true" v-model='this.shipping_info["d_address2"]'
                                                   @keyup="regText('d_address2')"  class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="40">
                                        </div>
                                    </div>
                                    <div class="relative pt-3 xl:pt-6" style="display: none;">
                                        <label for="note" class="block mb-3 text-sm font-semibold text-gray-500"> Notes (Optional)</label>
                                        <textarea name="note" id="note" rows="4" placeholder="" class="flex items-center w-full px-4 py-3 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-600"></textarea>
                                    </div>
                                    <div class="text-red-500 font-semibold text-xs md:text-base pt-4">※ Please do not enter duplicate address information in the two fields below(Ex. State, City, Street, Post code). Only detailed information should be entered in the two fields below(Building, House etc).</div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div id="summary" class="w-full sm:w-1/4 px-8">
                        <h1 class="font-semibold text-2xl border-b pb-8">Order Summary</h1>
                        <div class="flex justify-between mt-10 mb-5">
                            <label class="font-semibold uppercase">Items {{ products.length }}</label>
                            <span class="font-semibold">
                                <p class="hidden text-lg">RM {{ formatPrice(sum_item.price,2) }}</p>
                                <p class="hidden text-orange-700 text-sm">RM{{ formatPrice(sum_item.vat,1) }}</p>
                                <p class="text-lg">RM {{ formatPrice(sum_item.amt,2) }}</p>
                                <p class="text-orange-700 text-sm">{{ formatPrice(sum_item.bv,1) }} BV</p>
                                <p class="text-gray-700 text-sm">{{ formatPrice(total_weight,3) }} KG</p>
                              </span>
                        </div>

                        <div class="flex justify-between mt-10 mb-5"  v-if="fee_shipping.length">
                            <label class="font-semibold uppercase">Shipping</label>
                            <span class="font-semibold">
                            <p class="text-lg">RM {{ formatPrice(fee_shipping[0].value,2) }}</p>
                            <p class="text-gray-700 text-sm">{{ fee_shipping[0].text }}</p>
                            </span>
                        </div>

                        <!--            <div v-if="fee_shipping.length">
                                      <label class="font-medium inline-block my-3 text-sm uppercase">Shipping</label>
                                      <select name="select_shipping_fee" v-model="shipping_selected" @change="change_shipping_fee"
                                              class="form-select appearance-none block p-2 text-gray-600 w-full text-sm rounded">
                                        <option v-for="option in fee_shipping" :value="option.value">{{ option.text }} - RM {{ option.value }}</option>
                                      </select>
                                    </div>-->

                        <div v-if="fee_tax.length">
                            <label class="font-medium inline-block my-3 text-sm uppercase">TAX</label>
                            <select name="select_tax_fee" v-model="tax_selected" @change="change_tax_fee"
                                    class="form-select appearance-none block p-2 text-gray-600 w-full text-sm rounded">
                                <option v-for="option in fee_tax" :value="option.value">{{ option.text }} - {{ option.value }}</option>
                            </select>
                        </div>
                        <div class="border-t mt-8">
                            <div class="flex justify-between py-6">
                                <label class="font-semibold uppercase">Total cost</label>
                                <span class="font-semibold">
                                <p class="text-lg">RM {{ formatPrice(total_cost,2) }}</p>
                                <p class="text-orange-700 text-sm">{{ formatPrice(sum_item.bv,1) }} BV</p>
                                </span>
                            </div>
                            <button @click="order" v-show="is_payment" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">Checkout</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
/* javascript numberFormat library */
import numeral from 'numeral';
import 'numeral/locales';
import vSelect from "vue-select";
import alert from "vue3-simple-alert";
import modalView from "../modalView";
import VueSimpleAlert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import Swal from 'sweetalert2'


export default {
    name: "AsCart",
    components : {
        // address_book,
        vSelect,
        alert,
        modalView,
        Loading,
        Swal
    },
    props: ['orderer','fail_message','mem_center'],
    setup() {
        return {
            // products,
        }
    },
    watch: {
        shipping_info : function(){

        }
    },
    data: () => ({
        title: [],
        products: [],
        max_qty: 99,
        sum_item: [],
        total_cost: 0,
        total_weight: 0,
        fee_shipping: [],
        shipping_selected: 0,
        fee_tax: [],
        tax_selected: 0,
        isEnterShipping: false,
        isEnterProduct: true,
        shipping_info: [],
        csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
        selectedItems: [],
        state_list: [],
        city_list: [],
        street_list: [],
        post_list: [],
        center_list: [],
        cnt_cd : "",
        msg_post:'',
        is_academi_order : false,
        address_book_open: Boolean,  // 주소록 레이어 오픈
        loading:false,
        address_search_open:false,
        isMatchStateCity:false,
        is_order_possible : window.Laravel.is_order_possible,
        is_payment : false,
        is_first : false,
        is_manual : false,
        other_products : [],
        result_limit_chk : false,
    }),
    created() {
        this.cnt_cd = this.mem_center != "0600" ? this.mem_center : "Select Center" ;
        this.address_book_open = false;
        this.change_shipping('');
        this.sum_item.price = 0;
        this.sum_item.vat = 0;
        this.sum_item.amt = 0;
        this.sum_item.bv = 0;
        // 장바구니 가져오기
        this.getCart();
        this.getPostState();
        this.getCenterList();
        this.getCheck4WeekOrder();
        if(this.fail_message != ""){
            alert.alert(this.fail_message,"","",{returnFocus : false,  didClose: () => {
                    this.$refs.d_name.focus();
                }, didDestroy: ()=>{
                    this.$refs.d_name.focus();
                }
            }).then((result) => {
                if(result){
                    this.$refs.d_name.focus();
                }
            });
        }

    },
    methods:{
        regText(colum){
            var reg = /'/gi;
            var text = this.shipping_info[colum];
            text = text.replace( reg, "");
            this.shipping_info[colum] = text;
        },
        regNumber(colum){
            var reg = /'/gi;
            var text = this.shipping_info[colum];
            text = text.replace(/[^0-9]/g,'');
            this.shipping_info[colum] = text;
        },
        // 장바구니 가져오기
        getCart(){
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+'/api/getAsCartList',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.products = response.data;
                    if(this.products != []) { // 상품이 있으면 배송비, 택스 처리
                        this.totalCost(this.products);  //  결제상품 총액 / 배송비 포함 실결제액
                        //this.getWeight(window.Laravel.bz_cd);
                    }
                    this.loading = false;   // this.getCartList(response.data);
                })
                .catch(e => {
                });
            axios.post(process.env.MIX_BACK_URL+'/api/cart',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.other_products = response.data;
                    this.loading = false;   // this.getCartList(response.data);
                })
                .catch(e => {
                });

        },
        getCenterList(){
            axios.post(process.env.MIX_BACK_URL+'/api/getCenter',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    // this.shipping_info["d_state"] = value;
                    this.center_list = response.data;

                    // console.log(this.state_list);
                })
                .catch(e => {
                });

        },
        getCheck4WeekOrder(){
            axios.post(process.env.MIX_BACK_URL+'/api/getCheck4WeekOrder',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    // this.shipping_info["d_state"] = value;
                    this.is_payment = response.data.is_payment;
                    this.is_first = response.data.is_first;
                    this.is_manual = response.data.is_manual;

                    // console.log(this.state_list);
                })
                .catch(e => {
                });

        },


        // 우편번호  가져오기
        getPostState(){   // 주(State) 목록 가져오기
            axios.post(process.env.MIX_BACK_URL+'/api/getState',{

            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    // this.shipping_info["d_state"] = value;
                    this.state_list = response.data;

                    // console.log(this.state_list);
                })
                .catch(e => {
                });
        },
        onChangeState(value) {   // 주(State) 선택

            axios.post(process.env.MIX_BACK_URL+'/api/getCity',{
                state: value,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.shipping_info["d_city"] = "";
                    this.shipping_info["d_street"] = "";
                    this.shipping_info["d_post"] = response.data.postcode;
                    this.city_list = response.data;
                    // console.log(this.city_list);
                    this.totalCost(this.products);
                    this.getWeight(window.Laravel.bz_cd);
                })
                .catch(e => {
                });
        },
        onChangeCity(value) {  // 도시(City) 선택
            // console.log(event.target.value)
            axios.post(process.env.MIX_BACK_URL+'/api/getStreetForDelivery',{
                state: this.shipping_info["d_state"],
                city: value,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.shipping_info["d_street"] = "";
                    this.shipping_info["d_post"] = "";
                    this.street_list = response.data;
                    // console.log(response.data);
                })
                .catch(e => {
                });
        },
        onChangeStreet(value) {  // 도로(Street) 선택
            axios.post(process.env.MIX_BACK_URL+'/api/getPostCodeForDelivery',{
                state: this.shipping_info["d_state"],
                city: this.shipping_info["d_city"],
                street: value,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.shipping_info["d_post"] =  response.data.postcode;
                    this.post_list = response.data.postcode;
                    if(response.data.freqtype === 'ODA' || response.data.freqtype === 'NSA') {
                        this.msg_post = "The selected delivery address is an area that takes longer to deliver.";
                    }
                })
                .catch(e => {
                });
        },
        get_info_address_book(id)
        {
            axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_item',{
                seq:id
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.shipping_info["d_name"] = response.data.r_name;
                    this.shipping_info["d_name1"] = '';
                    this.shipping_info["d_name2"] = '';
                    this.shipping_info["d_state"] = (response.data.r_addr1).split("|@|")[3];
                    this.shipping_info["d_city"] = (response.data.r_addr1).split("|@|")[2];
                    this.shipping_info["d_street"] = (response.data.r_addr1).split("|@|")[0];
                    this.shipping_info["d_post"] = (response.data.r_addr1).split("|@|")[1];
                    this.shipping_info["d_telephone"] = response.data.r_tel;
                    this.shipping_info["d_mobile"] = response.data.r_mobile;

                    if(((response.data.r_addr2).split("|@|")[0]).length <= 35){
                        this.shipping_info["d_address"] = (response.data.r_addr2).split("|@|")[0];
                    }else{
                        this.shipping_info["d_address"] = "";
                    }

                    if(((response.data.r_addr2).split("|@|")[1]).length <= 35){
                        this.shipping_info["d_address2"] = (response.data.r_addr2).split("|@|")[1];
                    }else{
                        this.shipping_info["d_address2"] = "";
                    }
                    this.address_book_open = false;
                    this.totalCost(this.products);
                    this.getWeight(window.Laravel.bz_cd);
                })
        },
        // 수량 변경
        decrement(e,i){
            if(this.products[i].qty > 1) {
                this.products[i].qty--;
                this.setChangeQty(this.products[i].pdt_cd,this.products[i].qty, this.products[i].product.seq_pd);
                this.totalCost(this.products);
                this.getWeight(window.Laravel.bz_cd);
            }
        },
      async increment(e,i){
          if(this.products[i].qty < this.max_qty) {
            let before_qty = this.products[i].qty;
            await this.chkDayOrdLimit(this.products[i].product.seq_pd,1, before_qty).then(() => {
              if(this.result_limit_chk){
                  this.products[i].qty++;
                  this.totalCost(this.products);
                  this.getWeight(window.Laravel.bz_cd);
                  if(this.sum_item.bv > 500){
                    this.products[i].qty--;
                    this.totalCost(this.products);
                    this.getWeight(window.Laravel.bz_cd);
                    alert.alert("Autoship allows to purchase by only 500BV");
                  }else{
                    this.setChangeQty(this.products[i].pdt_cd,this.products[i].qty, this.products[i].product.seq_pd);
                  }
              }
            });
          }
        },
      async changeQty(e,i){
            if(this.products[i].qty < 1) {
                this.products[i].qty = 1;
            }
            else if(this.products[i].qty > this.max_qty) {
                this.products[i].qty = this.max_qty;
            }
            await this.chkDayOrdLimit(this.products[i].product.seq_pd,this.products[i].qty, 0).then(() => {
                if(this.result_limit_chk){
                    this.setChangeQty(this.products[i].pdt_cd,this.products[i].qty, this.products[i].product.seq_pd);
                    this.totalCost(this.products);
                    this.getWeight(window.Laravel.bz_cd);
                }
            });

        },
        setChangeQty(pdt_cd, qty, item = ''){
            axios.post(process.env.MIX_BACK_URL+'/api/insertAsCartPdt',{
                item   : item,
                pdt_cd : pdt_cd,
                qty    : qty,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    // console.log(response.data);
                    if(response.data.msg){
                        alert(response.data.msg);
                    }

                });
        },
        async chkDayOrdLimit(item, qty, before_qty = 0) {
          this.loading = true;
          //일일,주문건당 구매수량제한 먼저 검사
          await axios.post(process.env.MIX_BACK_URL + '/api/chk_day_ord_qty_limit_ascart', {
            item    : item,
            add_qty : qty,
            cart_qty: before_qty,
          }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
            .then(response => {
              this.loading = false;
              if (response.data != "") {
                alert.alert(response.data);
                this.result_limit_chk = false;
              }else{
                this.result_limit_chk = true;
              }
            });
        },
        // 장바구니 아이템 삭제
        removeItem(e,i){

            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+'/api/removeProduct',{
                item: this.products[i].pdt_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.loading = false;
                    // console.log(response.data);
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                    this.getCart(window.Laravel.bz_cd);
                });
        },
        // 선택 아이템 삭제
        removeSelect(){

            if(this.selectedItems.length === 0){
                alert.alert("No selected items");
                return false;
            }
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+'/api/remove_cart',{
                item: this.selectedItems,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.loading = false;
                    // console.log(response.data);
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                    this.selectedItems = [];
                    this.getCart(window.Laravel.bz_cd);
                });
        },
        // 쇼핑 계속하기
        prevUrl(){
            // console.log(window.history);
            window.history.go(-1);//
            // this.$router.go（-1）;
        },
        // 주문하기
        async order(){
            //사전 오픈 50BV 제한
            if(this.sum_item.bv < 20){
                alert.alert('Please order over 20BV.');
                return false;
            }
            if(!this.isEnterShipping){
                this.isEnterShipping = true;
                this.isEnterProduct = false;

                alert.alert('Please enter your shipping information',"","",{returnFocus:false,focusConfirm:false,
                    didOpen: () => {
                    },
                    didDestroy: (toast) => {
                        this.$refs.d_name.focus();
                    }
                }).then((result) => {
                    if(result){
                        //this.$refs.d_name.focus();

                    }
                });
                return true;
            }

            if(this.sum_item.amt == 0){
                alert.alert('The configuration for the shopping basket cannot be found.');
                return false;
            }

            if(!this.shipping_info["d_name"]){
                alert.alert('Please enter reciever name');
                return false;
            }
            // if(!this.shipping_info["d_name2"]){
            //   alert.alert('Please enter last name');
            //   return false;
            // }
            if(!this.shipping_info["d_mobile"]){
                alert.alert('Please enter mobile No.');
                return false;
            }
            if(!this.shipping_info["d_state"]){
                alert.alert('Please select shipping State information');
                return false;
            }
            if(!this.shipping_info["d_city"]){
                alert.alert('Please select shipping City information');
                return false;
            }
            if(!this.shipping_info["d_street"]){
                alert.alert('Please select shipping Street information');
                return false;
            }

            if(!this.shipping_info["d_address"]){
                alert.alert('Please enter detail address');
                $("#address").focus();
                return false;
            }
            /* 우편번호로 상세주소내 State/City 정보 유무 여부 체크 */
            this.getStateCity(this.shipping_info["d_post"]);
            /* 상세주소내 5자리 숫자로 상세주소내 State/City 정보 유무 여부 체크 */
            if(!this.isMatchStateCity) {
                if (this.shipping_info["d_address"].match(/\d{5}/g)) {
                    let result = this.shipping_info["d_address"].match(/\d{5}/g);
                    if (result.length > 0) {
                        result.forEach(this.getStateCity);
                    }
                }
            }

            this.loading = true;
            await this.sleep(5000);
            if(this.isMatchStateCity){
                this.isMatchStateCity = false;
                this.loading = false;
                return false;
            }

            let formdata = new FormData();
            formdata.append("_token", this.csrf);
            formdata.append("bz_cd", window.Laravel.bz_cd);
            formdata.append("client_ip", window.Laravel.clientIp);
            formdata.append("orderHost", location.host);
            formdata.append("orderNation", window.Laravel.bz_cd);
            formdata.append("orderPath", '일반주문');
            formdata.append("sumPrice", this.sum_item.price);
            formdata.append("sumVat", this.sum_item.vat);
            formdata.append("sumAmt", this.sum_item.amt);
            formdata.append("sumBv", this.sum_item.bv);
            formdata.append("shippingFee", this.shipping_selected);
            formdata.append("taxFee", this.tax_selected);
            formdata.append("payTotalAmt", this.total_cost);
            formdata.append("name", this.shipping_info.d_name);
            formdata.append("firstName", this.shipping_info.d_name1);
            formdata.append("lastName", this.shipping_info.d_name2);
            formdata.append("mobile", this.shipping_info.d_mobile);
            formdata.append("address", this.shipping_info.d_address+'|@|'+this.shipping_info.d_address2);
            formdata.append("street", this.shipping_info.d_street);
            formdata.append("post", this.shipping_info.d_post);
            formdata.append("city", this.shipping_info.d_city);
            formdata.append("state", this.shipping_info.d_state);
            formdata.append("note", document.shipping_info.note.value);
            if(this.is_academi_order == true){
                formdata.append("cnt_cd", this.cnt_cd);
            }else{
                formdata.append("cnt_cd", "");
            }
            formdata.append("_method", "PUT");
            axios.post(process.env.MIX_BACK_URL+'/api/asorder', formdata,
                {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.loading = false;

                    // console.log(response.data);
                    if(response.data.msg){
                        alert.alert(response.data.msg);
                    }
                    if(response.data.result == 'Success'){
                        let form = document.shipping_info;
                        form.action = '/asorder';
                        form.order_seq.value = response.data.order_seq;
                        form.shipping_fee.value = this.shipping_selected;
                        form.tax_fee.value = this.tax_selected;
                        form.pay_total_amt.value = this.total_cost;
                        form.submit();
                    }
                    // this.getCart(window.Laravel.bz_cd);
                });

            // this.$router.go（-1）;
        },
        getStateCity(itme){
            axios.post(process.env.MIX_BACK_URL+'/api/getStateCity',{
                bz_cd: window.Laravel.bz_cd,
                postcode: itme,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    if(response.data){
                        let list = response.data;
                        list.forEach(this.matchStateCity);
                    }
                });
        },
        matchStateCity(item){
            let state = item.state;
            let city = item.district;
            if((this.shipping_info["d_address"].toUpperCase().match(state.toUpperCase())) && (this.shipping_info["d_address"].toUpperCase().match(city.toUpperCase()))){
                alert.alert('Please enter State/City/Postcode only in Search Address.');
                this.isMatchStateCity = true;
                return false;
            }
        },sleep(ms) {
            return new Promise((r) => setTimeout(r, ms));
        },
        getWeight(bz_cd){
            axios.post(process.env.MIX_BACK_URL+'/api/getWeight',{
                bz_cd: bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.total_weight = response.data.result;
                    this.getShippingFee();
                });
        },
        getShippingFee(){
            /* 배송 기본료  체크 */
            const my_east = ['SABAH', 'SARAWAK']; // 동부 기준 state
            const basic_weight = 3; // 기본 무게
            /* 서부를 디폴트로 */
            let basic_fee = 10;
            let add_fee = 2;
            let free_fee_term = 280;
            if (my_east.includes(this.shipping_info["d_state"])) {  // 동부인 경우
                basic_fee = 20;
                add_fee = 8;
                free_fee_term = 400;
            }
            /* 무료 배송 조건 체크 */
            if(this.sum_item.amt >= free_fee_term) { // 무료배송 기준 이상인 경우
                /* 배송비 */
                this.fee_shipping = [{
                    text: 'FREE Shipping',
                    value: 0
                }];
                this.shipping_selected = 0; // 배송비
            }else{  // 무료배송 기준 이하인 경우
                let value = basic_fee;  // 기본 배송비 (3kg 이내)
                /* 기본 배송 조건 체크 */
                if(parseFloat(this.total_weight) > parseFloat(basic_weight)){ // 기본 무게 초과인 경우
                    value = (parseFloat(basic_fee) + Math.ceil((parseFloat(this.total_weight) - parseFloat(basic_weight)) * add_fee)).toFixed(0);
                }
                /* 배송비 */
                this.fee_shipping = [{
                    text: parseFloat(this.total_weight).toFixed(3) + 'KG',
                    value:value
                }];
                this.shipping_selected = value; // 배송비
            }

            /* 아카데미 주문일 경우 무료 배송 */
            if(this.is_academi_order == true){
                console.log("test");
                /* 배송비 */
                this.fee_shipping = [{
                    text: 'FREE Shipping',
                    value: 0
                }];
                this.shipping_selected = 0; // 배송비
                this.total_cost = this.sum_item.amt + parseFloat(this.shipping_selected);
            }

            if (this.shipping_selected) this.total_cost = this.sum_item.amt + parseFloat(this.shipping_selected);  // 합산금액 변경

        },
        // 장바구니 금액 합산
        totalCost(data){  // 토탈 금액 합산 기능

            this.sum_item.price = this.sumPrice(data);
            this.sum_item.vat = this.sumVat(data);
            this.sum_item.amt = this.sumAmt(data);
            this.sum_item.bv = this.sumBV(data);
            this.total_cost = this.sum_item.amt;
            if (this.tax_selected) this.total_cost += parseFloat(this.tax_selected);

        },
        sumPrice(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.product.prices.price*el.qty), 0);
        },
        sumVat(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.product.prices.vat*el.qty), 0);
        },
        sumAmt(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.product.prices.amt*el.qty), 0);
        },
        sumBV(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.product.prices.pv*el.qty), 0);
        },
        change_shipping_fee(){  // 배송비 합산
            this.totalCost(this.products);
            // this.total_cost = this.add_shipping_fee();
        },
        change_tax_fee(){
            this.totalCost(this.products);
        },
        change_shipping(data){  // 배송지 변경
            this.msg_post = "";
            if(data=='Same') {
                this.shipping_info["d_name"] = this.orderer["o_name"];
                this.shipping_info["d_name1"] = this.orderer["o_name1"];
                this.shipping_info["d_name2"] = this.orderer["o_name2"];
                this.shipping_info["d_state"] = this.orderer["o_state"];
                this.shipping_info["d_city"] = this.orderer["o_city"];
                this.shipping_info["d_street"] = this.orderer["o_street"];
                this.shipping_info["d_post"] = this.orderer["o_post"];
                if(this.orderer["o_address"].split("|@|")[0].length <= 40){
                    this.shipping_info["d_address"] = this.orderer["o_address"].split("|@|")[0];
                }else{
                    this.shipping_info["d_address"] = "";
                }

                this.shipping_info["d_address2"] = this.orderer["o_address"].split("|@|")[1] ?? "";
                this.shipping_info["d_telephone"] = this.orderer["o_telephone"];
                this.shipping_info["d_mobile"] = this.orderer["o_mobile"];
            }else if(data=='Academi') {
                this.shipping_info["d_name"] = this.orderer["o_name"];
                this.shipping_info["d_name1"] = this.orderer["o_name1"];
                this.shipping_info["d_name2"] = this.orderer["o_name2"];
                this.shipping_info["d_state"] = '';
                this.shipping_info["d_city"] = '';
                this.shipping_info["d_street"] = '';
                this.shipping_info["d_post"] = '';
                this.shipping_info["d_address"] = '';
                this.shipping_info["d_address2"] = '';
                this.shipping_info["d_telephone"] = '';
                this.shipping_info["d_mobile"] = this.orderer["o_mobile"];

                this.center_sel();
            }else{

                this.shipping_info["d_name"] = '';
                this.shipping_info["d_name1"] = '';
                this.shipping_info["d_name2"] = '';
                this.shipping_info["d_state"] = '';
                this.shipping_info["d_city"] = '';
                this.shipping_info["d_street"] = '';
                this.shipping_info["d_post"] = '';
                this.shipping_info["d_address"] = '';
                this.shipping_info["d_address2"] = '';
                this.shipping_info["d_telephone"] = '';
                this.shipping_info["d_mobile"] = '';
            }
            if (data=='Manage'){
                this.address_book_open = true;
            }else{
                // this.address_book_open = false;
            }
            if(data == 'Academi'){
                this.is_academi_order = true;
                this.getShippingFee();
                console.log(this.shipping_selected);
            }else{
                this.is_academi_order = false;
                this.getShippingFee();
            }
        },
        // 주소록 레이어 오픈
        close_address_book(){
            this.address_book_open = false;
        },
        // 숫자 포멧
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        search_address(){
            if(this.is_academi_order == false){
                this.address_search_open = true;
            }
        },
        set_address(value){
            this.address_search_open = false;
            this.shipping_info["d_state"] =value.state;
            this.shipping_info["d_city"] = value.district;
            this.shipping_info["d_post"] = value.postcode;
            this.totalCost(this.products);
            this.getWeight(window.Laravel.bz_cd);

        },
        close_address_search(){
            this.address_search_open = false;
        },
        center_sel(value){
            console.log(this.cnt_cd);
            this.center_list.forEach((value, index) => {

                if(value.cnt_cd == this.cnt_cd){
                    this.shipping_info["d_post"] = value.post;
                    this.shipping_info["d_state"] = value.state;
                    this.shipping_info["d_city"] = value.city;
                    this.shipping_info["d_street"] = value.street;
                    this.shipping_info["d_address"] = value.address;
                    this.shipping_info["d_address2"] = value.address1;

                }
            });

        },
        link(value){
            location.href = value;
        }
    }
}
</script>
<style>
.vs__search {
    font-size: 1.5em;
    font-weight: 500;
    padding: 0.05rem;
    border: none;
    /* margin-top: 35px; */
}
.badge_box{position: absolute; top: 0; right: 0; width: 100%; }
.badge_box>img {width: 25% !important; top:8px; right: 8px; position: absolute; z-index: 1; background:transparent !important;}

.coming_box{position: absolute; top: 0; right: 0; width: 100%; }
.coming_box>img{width:25% !important;  position: absolute; right:8px; top: 8px;  z-index: 1; background:transparent !important;}

.sold-out-box2{position: absolute; top: 0; right: 0; width: 100%; }
.sold-out-box2>img {width: 100% !important; top:0px; right: 0px; position: absolute;  z-index: 1; background:transparent !important;}

</style>
