<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>

  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-4 sm:px-6 lg:max-w-7xl lg:px-8 bg-slate-100 lg:bg-white">
        <div class="w-full lg:flex lg:justify-between">
          <div class="flex justify-start items-start flex-col space-y-4 mt-4 lg:mt-8">
            <div>
              <div class="inline-block pl-2">
                <p class="text-2xl p-0 m-0 leading-5">Online Academy</p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full pt-10 lg:flex lg:justify-start">
          <AcademySide></AcademySide>
          <div class="w-full">
            <div class="max-w-8xl mx-auto py-8 px-4 sm:py-6 sm:pl-6 lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="flex justify-between text-center">
                <h1 class="text-lg lg:text-xl font-semibold border-zinc-700 border-t-2 border-b w-full pb-5 pt-5">{{ row.title }}</h1>
              </div>
              <div v-show="row.url" class="w-full text-center border-t-1 pt-2.5">
                <iframe class="md:w-9/12 w-full h-72 text-center" :src="row.url" frameborder="0" allowfullscreen="" mozallowfullscreen="" webkitallowfullscreen=""></iframe>
              </div>
              <div class="pb-2.5 pt-2.5" v-html="row.info"></div>
              <h1 class="tborder-zinc-700 border-t-2 w-full"> </h1>
                <div class="history-list-wrap" v-show="row.file_name">
                    <h4>FILE</h4>
                    <div class="add_f view-add-file" >
                        <a class="attch_link" :href="'https://cdn3.gcooperp.com/public/video_contents/MYS/'+row.file_name" target="_blank">{{ row.ori_file_name }}</a>
                    </div>
                </div>

            </div>
            <div class="text-center"><a class="w-auto px-3.5 py-2 bg-black text-white cursor-pointer" onclick="history.back();">List</a></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import AcademySide from './AcademySide.vue'
import Loading from 'vue3-loading-overlay';



export default {
  name: "mypage",
  props:['no'],
  components : {
    VueSimpleAlert,
      ShoppingCartIcon,
      AcademySide,
      Loading,
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    limit: 4,
    no_search_messsage: '',
    isLoggedIn: false,
    max_qty : 99,
    loading : false,
    row : '',
  }),
  created() {
  },
  mounted() {
    this.getOnlineAcademyContent();
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
    }
  },
  methods:{
    getOnlineAcademyCateogry(){
      let config = {
        headers: {
          Authorization: 'Bearer ' + window.Laravel.access_token
        }
      };

      axios.post(window.Laravel.back_url+'/api/getOnlineAcademyCateogry',{
        no : this.no,
        bz_cd : window.Laravel.bz_cd
      },config)
        .then(response => {
          this.cate_top_list =response.data.list;
        });
    },
    list_url(link){
      location.href="/product/list/"+link;
    },
    getOnlineAcademyContent(){
      let config = {
        headers: {
          Authorization: 'Bearer ' + window.Laravel.access_token
        }
      };

      this.loading = true;
      axios.post(window.Laravel.back_url+'/api/getOnlineAcademyContent',{
        no : this.no,
        bz_cd : window.Laravel.bz_cd,
      },config)
      .then(response => {
        console.log(response.data.contents);
        this.loading = false;
        if(response.data.contents){
          this.row = response.data.contents;
        }else{
          VueSimpleAlert.alert("조회할 수 없는 컨텐츠 입니다.","","",{allowOutsideClick : false}).then((result) => {
            if(result){
              location.replace("/MypageAcademy");
            }
          });
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
