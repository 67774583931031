<template>
    <loading :active="false"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <article class="myo_search_area">
        <form name='form_search'  v-on:submit.prevent="submitForm" style="clear:both;">
            <section class="myo_condition_box">
                <dl class="myo_search_list">
                    <dt>{{ i18n.myoffice.Commission.Classfication }}</dt>
                    <dd>
                        <span class="radiobox_icon">
                            <input type="radio" v-model="radioCondition" name="radioCondition" id="radioCondition" value="1" >
                            <label for="radioCondition"><i class="far fa-dot-circle"></i> {{ i18n.myoffice.Commission.Monthly }}</label>
                        </span>
                        <span class="radiobox_icon">
                            <input type="radio" v-model="radioCondition" name="radioCondition" id="radioCondition2" value="2">
                            <label for="radioCondition2"><i class="far fa-dot-circle"></i> {{ i18n.myoffice.Commission.Annually }}</label>
                        </span>
                    </dd>
                    <dd>
                        <div class="myo_date_select2 mgb_8 relative z-30">
                            <div class="flex_box">
                                <vSelect :options="years" label="value" :reduce="value => value.value"  :clearable="false" v-model="s_year"  ></vSelect>
                            </div>
                            <span class="hyphen_icon">/</span>
                            <div class="month_box">
                                <vSelect :options="months" label="value" :reduce="value => value.title" :clearable="false" v-model="s_month"  ></vSelect>
                            </div>
                        </div>
                        <div class="myo_date_select2 mgb_8 relative z-20">
                            <div class="">
                                <vSelect :options="years" label="value" :reduce="value => value.value"  :clearable="false" v-model="e_year"  ></vSelect>
                            </div>
                            <span class="hyphen_icon">/</span>
                            <div class="month_box">
                                <vSelect :options="months" label="value" :reduce="value => value.title" :clearable="false" v-model="e_month"  ></vSelect>
                            </div>
                        </div>

                    </dd>
                    <dd>
                    </dd>
                </dl>
            </section>


            <div class="myo_condition_search">
                <button type="button" class="page_reset_btn" onclick="location.reload();">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>
        </form>
    </article>
    <section class="accordion_notice_box">
        <div class="accordion_notice_item">
            <a class="accordion_notice_tit" href="#n">
                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                {{ i18n.myoffice.myoSearchForm.notice }}
                <div class="bmenu x7">
                    <span class="btop">
                        <i class="fas fa-chevron-up" aria-hidden="true"></i>
                    </span>
                </div>
            </a>
            <div class="accordion_notice_cont">
                <div class="myo_search_notice">
                    <p>{{ i18n.myoffice.myofficeAlert.bonusListViewAlert2 }}</p>
                </div>
            </div>
        </div>
    </section>

    <div style="height: 10px;"></div>
    <div class="summary position_relate_box">
        <strong>Total</strong> <em>{{ total_count }}</em>
        <abbr title="@lang('myoffice.myofficeAlert.CanTablescroll2')" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
    </div>
    <div class="list-table-wrap">
        <table class="list-table">
            <thead>
                <tr>
                    <th>No</th>
                    <th>{{ i18n.myoffice.Commission.DateofBalance }}</th>
                    <th>{{ i18n.myoffice.Commission.Position }}</th>
                    <th>{{ i18n.myoffice.Commission.OrderBV }} </th>
                    <th class="px-6 py-3 whitespace-nowrap" v-for="Value in name_list">{{ Value.pay_name }}</th>
                    <th>{{ i18n.myoffice.Commission.TotalAmount }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(Value,index) in List" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td class="ltit text-center" style="height:27px;">{{ Value.rn }}</td>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.pay_date }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.rank_new_name }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.ord_pv,1) }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt1,2) }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt2,2) }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt4,2) }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt5,2) }}</th>
                    <th class="px-6 py-3 whitespace-nowrap" v-show="cnt_top_chk != 0">{{ formatPrice(Value.amt6,2) }}</th>
                  <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt7,2) }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.total_amt,2) }}</th>
                </tr>
            </tbody>
        </table>
        <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
            <v-pagination
                v-model="page"
                :pages=total_page
                :range-size="1"
                active-color="#DCEDFF">
            </v-pagination>
        </div>
    </div>


</template>

<script>
import vSelect from "vue-select";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
    name: "MyBenefitTotalSearch",
    components : {
        vSelect,
        VPagination,
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        title: [],
        products: [],
        page : 1,
        total_page : 1,
        total_count : 1,
        s_date: date_half_month,
        e_date: date_today,
        checkCondition : false,
        name_list : [],
        List : [],
        radioCondition : 1,
        months : [{title : "01", value : i18n.myoffice.jan},
            {title : "02", value : i18n.myoffice.feb},
            {title : "03", value : i18n.myoffice.mar},
            {title : "04", value : i18n.myoffice.apr},
            {title : "05", value : i18n.myoffice.may},
            {title : "06", value : i18n.myoffice.jun},
            {title : "07", value : i18n.myoffice.jul},
            {title : "08", value : i18n.myoffice.aug},
            {title : "09", value : i18n.myoffice.sep},
            {title : "10", value : i18n.myoffice.oct},
            {title : "11", value : i18n.myoffice.nov},
            {title : "12", value : i18n.myoffice.dec}],
        years : [],
        s_year : "",
        s_month : "",
        e_year : "",
        e_month : "",
        loading : false,
        cnt_top_chk : 0
    }),
    watch: {
        page : function (){
            this.submitForm();
        }
    },
    created : function () {
        var year = "2015";
        for (year; year <= new Date().getFullYear(); year++) {
            this.years.push(
                {value: year}
            );
        }
        axios.post(window.Laravel.back_url+'/api/getNameList',{
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.name_list = response.data;
        });
        this.submitForm();

    },methods : {
        submitForm : function (){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/benefitTotalSearch',{
                s_year : this.s_year,
                s_month : this.s_month,
                e_year : this.e_year,
                e_month : this.e_month,
                radioCondition : this.radioCondition,
                page: this.page,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.List = response.data.list;
                this.total_count = response.data.total_count;
                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
                this.s_year = response.data.s_year;
                this.s_month = response.data.s_month;
                this.e_year = response.data.e_year;
                this.e_month = response.data.e_month;
                this.cnt_top_chk = response.data.cnt_top_chk;
            });
        },
        formatPrice(value,digit) {
          // �ڵ� ���� add by hskim :: 2023-07-21 ���� 2:46
          value = isNaN(value) ? 0 : value;
          let val = (value/1).toFixed(digit).replace('.', '.')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

    }
}
</script>

<style scoped>

</style>
