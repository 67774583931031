<template>
    <div class="w-full p-4 flex justify-between border-b">
        <h2 class="w-full text-2xl">Delivery Address</h2>
        <p @click=" close_address_search " class="cursor-pointer p-2">X</p>
    </div>
    <div class="w-full p-2 mb-24 md:mb-4">
        <div class="w-full text-gray-300 py-2 border-b">Search the address with Post code</div>
        <ul class="flex py-2">
            <li class="flex-1 mr-2" @click="currentTab = 1">
                <a class="text-center block border rounded py-2 px-4"
                   :class="currentTab === 1 ? 'border-blue-500  bg-blue-500 hover:bg-blue-700 text-white' : 'border-white hover:border-gray-200 text-blue-500 hover:bg-gray-200'" href="#">
                    Search by Post code</a>
            </li>
            <li class="flex-1 mr-2" v-show="false" @click=" currentTab = 2 ">
                <a class="text-center block border rounded py-2 px-4"
                   :class="currentTab === 2 ? 'border-blue-500  bg-blue-500 hover:bg-blue-700 text-white' : 'border-white hover:border-gray-200 text-blue-500 hover:bg-gray-200'"  href="#">
                    스트리트로 검색하기
                </a>
            </li>
        </ul>

        <div class="" v-if="currentTab === 1">
          <div class="w-full p-4 justify-between border-b">
              <form v-on:submit.prevent=" post_Search ">
                  <label class="block mb-3 text-sm font-semibold text-gray-500">Post code</label>
                  <div class="flex">
                      <input type="text" v-model="post" class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                      <input type="submit" value="Search" @click="post_Search" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full cursor-pointer">
                  </div>
              </form>
            </div>
            <div class="w-full p-4 overflow-auto	">
                <label class="block mb-3 text-sm font-semibold text-gray-500">{{ post_label }}</label>
<!--                <vSelect name="state" ref="d_state" :options="post_list" :reduce="value => value.id" v-model='post_select' :clearable="false"-->
<!--                @option:selected="onChangePost" class="block w-full text-gray-600 text-sm"></vSelect>-->
                <table class="w-full">
                    <tr class="p-2 border border-gray-200 w-full hover:bg-slate-100	" v-for="(value,index) in post_list">
                        <td class="p-2" @click="onChangePost(value.id)">{{ value.label }}</td>
                    </tr>
                </table>
            </div>
        </div>

        <div v-if="currentTab === 2">
          <div class="w-full p-4 justify-between border-b">
              <h4 class="block mb-3 text-sm font-semibold text-gray-500">Steet</h4>
              <div class="flex">
                <input type="text" v-model="street" class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                <input type="button" value="Search" @click="street_Search" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full cursor-pointer">
              </div>
            </div>
            <div class="w-full p-4">
                <label class="block mb-3 text-sm font-semibold text-gray-500">{{ street_label }}</label>
                <vSelect name="state" ref="d_state" :options="steet_list" :reduce="value => value.id" v-model='post_select' :clearable="false"
                @option:selected="onChangePost" class="block w-full text-gray-600 text-sm"></vSelect>
            </div>
        </div>

    </div>
</template>

<script>
import vSelect from "vue-select";

export default {
    name: "addressSearch",
    components : {
        // address_book,
        vSelect,
    },
    props:{
        openlayer: Boolean
    },
    data: () => ({
        title: [],
        products: [],
        currentTab: 1,
        post : "",
        street : "",
        post_list : [],
        steet_list : [],
        steet_select : "",
        //post_select : "",
        post_label : "",
        street_label : "",
    }),
    methods:{
        post_Search(){
            axios.post(process.env.MIX_BACK_URL+'/api/getPostSearchList',{
                post: this.post,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.post_label = "";
                    if(response.data.length != 0){
                        this.post_label = "Please select your address";
                    }else{
                        this.post_label = "No addresses found";
                    }
                    this.post_list = [];
                    response.data.forEach((value, index) => {
                        this.post_list.push({
                            label :value.district+ " "+value.postcode+" "+value.state,
                            id : {"district" : value.district,"postcode" : value.postcode , "state" : value.state}
                        });
                    });

                })
                .catch(e => {
                });

        },
        street_Search(){
            axios.post(process.env.MIX_BACK_URL+'/api/getStreetSearchList',{
                location: this.street,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.street_label = "";
                    if(response.data.length != 0){
                        this.street_label = "Please select your address";
                    }else{
                        this.street_label = "No addresses found";
                    }
                    this.steet_list = [];

                    response.data.forEach((value, index) => {
                        this.steet_list.push({
                            label : value.location+" "+value.postcode+" "+value.district+" "+value.state,
                            id : value.seq
                        });
                    });

                })
                .catch(e => {
                });

        },
        onChangePost(post_select){
            this.$emit("set_address",post_select);
        },
        onChangeStreet(){

        },
        close_address_search(){
            // this.openlayer = false;
            this.$emit("close_address_search");
        },

    }
}
</script>

<style scoped>

</style>
