<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>
  <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
    <address_search @set_address="set_address" @close_address_search="close_address_search" />
  </modalView>

  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
        <MypageTop :laravel="laravel"></MypageTop>
        <div class="w-full lg:flex lg:justify-start">
          <div class="hidden lg:block xl:block">
            <SideMenu></SideMenu>
          </div>
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start flex-col">
                  <div class="flex justify-between">
                    <h1 class="text-lg dark:text-white lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800 mb-2">{{ i18n.menu.MyPersonInfo}}</h1>
                  </div>
<!--                  <div style="width:100%; background:black; height:2px;"></div>-->

                  <div class="w-full border-y-2 border-black divide-y bg-white lg:p-2">
                    <div class="py-2 border-gray-400">
                      <div class="flex justify-between text-lg mb-4">
                        <h2 class="font-semibold">{{ i18n.myoffice.BusinessInfomation}}</h2>
                        <div class="flex justify-end">
                          <MinusSmIcon class="h-6 w-6" aria-hidden="true" />
                          <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                        </div>
                      </div>
                      <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.MemberId }}</div>
                        <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ mem.userid }}</div>
                      </div>
                      <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1 break-words">{{ i18n.myoffice.RegistrationDate }}</div>
                        <div class="w-4/6 lg:w-5/6 xl:w-5/6  text-sm lg:text-base xl:text-base">{{ mem.reg_date }}</div>
                      </div>
                      <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.EnrollmentSponsor }}</div>
                        <div class="w-4/6 lg:w-5/6 xl:w-5/6  text-sm lg:text-base xl:text-base">{{ mem.r_name }} ({{ mem.r_id }})</div>
                      </div>
                      <!-- 요청에 따른 히든 처리 -->
                      <!--
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-2/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.PlacementSponsor }}</div>
                        <div class="w-4/6 text-sm lg:text-base xl:text-base">{{ mem.p_name }} ({{ mem.p_id }})</div>
                      </div>
                      -->
                    </div>


                    <div class="py-2 border-b border-dashed border-gray-400" id="info">
                      <div class="flex justify-between text-lg mb-4">
                        <h2 class="font-semibold">{{i18n.myoffice.MemberInfomation}}</h2>
                        <div class="flex justify-end">
                          <MinusSmIcon class="h-6 w-6" aria-hidden="true" />
                          <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                        </div>
                      </div>
                      <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.memberName }}</div>
                        <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ mem.username }}</div>
                      </div>
                      <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.birthday }}</div>
                        <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ mem.birthday }}</div>
                      </div>
                      <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.handphone }}</div>
                        <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ mem.mobile }}</div>
                      </div>
                      <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.email }}</div>
                        <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ mem.e_mail }}</div>
                      </div>
                      <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Address }}</div>
                        <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ mem.addr2 }} <br> {{ mem.addr1 }}</div>
                      </div>
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                            <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">Academy</div>
                            <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ mem.cnt_name }}</div>
                        </div>
                    </div>
                  </div>

                  <div class="flex"><button class="my-2 py-2 px-4 leading-6 text-white bg-gray-900 w-full xl:w-1/6 lg:2-1/6 rounded" @click="show_modify">Edit</button></div>

                  <div class="w-full border-t-2 border-black divide-y bg-white lg:p-2" id="infomodify" style="display: none">
                    <div class="py-2 border-b-2 border-black">
                      <div class="flex justify-between text-lg mb-4">
                        <h2 class="font-semibold">{{i18n.myoffice.MemberInfomation}}</h2>
                        <div class="flex justify-end">
                          <MinusSmIcon class="h-6 w-6" aria-hidden="true" />
                          <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                        </div>
                      </div>
                      <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.memberName }}</div>
                        <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ mem.username }}</div>
                      </div>
                      <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.birthday }}</div>
                        <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ mem.birthday }}</div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Password }}</div>
                        <div class="w-full lg:w-5/6"><input type='password' v-model="pass1" autocomplete="new-password" class="w-full xl:w-80 lg:w-80"></div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Passwordcheck }}</div>
                        <div class="w-full lg:w-5/6"><input type='password' v-model="pass2" autocomplete="off" class="w-full xl:w-80 lg:w-80"></div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.handphone }}</div>
                        <div class="w-full lg:w-5/6">
                          <input type="text" v-model="mobile" id="mobile" maxlength="11" class="type_number h-10 w-full xl:w-80 lg:w-80">
                          <input type="button" class="h-10 w-full xl:w-1/6 lg:w-1/6 px-4 leading-6 text-white bg-gray-900 cursor-pointer mt-2 lg:mt-0 xl:mt-0" id="mobileCheck" v-on:click="checkMobile"  value="Check"/><br>
                          <div id="mobileCheckTxt" class="mgt_8 mgb_8"></div>
                          <input type="hidden" id="mobileCheckYn" name="mobileCheckYn" value="N"/>
                          <input type="hidden" id="vertifyMobile" name="vertifyMobile" value="N">
                        </div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.email }}</div>
                        <div class="w-full lg:w-5/6">
                          <input name="e_mail" id="e_mail" class="type_email h-10 w-full xl:w-80 lg:w-80" v-model="e_mail" style="ime-mode:disabled;" type="text">
                          <input type="button" class="h-10 w-full  xl:w-1/6 lg:w-1/6 px-4 leading-6 text-white bg-gray-900 cursor-pointer mt-2 lg:mt-0 xl:mt-0" id="emailCheck" v-on:click="checkEmail" value="Check"  >
                          <input type="hidden" id="emailCheckYn" name="emailCheckYn" value="N"/>
                        </div>
                      </div>

                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">Postcode</div>
                        <div class="w-full lg:w-5/6">
                          <input name="post" id="post" class="type_number h-10 w-full xl:w-80 lg:w-80" @click="search_address" v-model="post" maxlength='5' type="text" readonly>
                          <input type="button" @click="search_address"  class="h-10 w-full xl:w-1/6 lg:w-1/6 leading-6 text-white bg-gray-900 cursor-pointer mt-2 lg:mt-0 xl:mt-0" value="search address"/>
                        </div>
                      </div>

                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">State</div>
                        <div class="w-full lg:w-5/6">
                          <input type='text' name="address" id="state" v-model='selectState' readonly @click="search_address" maxlength="40" class="h-10 w-full xl:w-80 lg:w-80">
                        </div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">City</div>
                        <div class="w-full lg:w-5/6">
                          <input type='text'  name="address" id="city" v-model='city' readonly @click="search_address" maxlength="40" class="h-10 w-full xl:w-80 lg:w-80">
                        </div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">Street</div>
                        <div class="w-full lg:w-5/6">
                          <input type='text' name="address" id="street" v-model='Street' maxlength="40" class="h-10 w-full xl:w-80 lg:w-80">
                        </div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">Detail Address</div>
                        <div class="w-full lg:w-5/6">
                          <input name="addr2_1" id="addr2_1" class=" add-input1 h-10 w-full xl:w-80 lg:w-80" v-model="addr2_1" type="text"> <br> <span class="text-sm lg:text-base xl:text-base">House Number / Block Number</span><br>
                          <input name="addr2_2" id="addr2_2" class=" add-input1 h-10 w-full xl:w-80 lg:w-80" v-model="addr2_2" type="text" placeholder="Please enter up to 40 characters."><br> <span class="text-sm lg:text-base xl:text-base">Name of building / Name of neighborhood</span>
                        </div>
                      </div>
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                            <div class="w-full lg:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">Academy</div>
                            <div class="w-full lg:w-5/6">
                                <vSelect :options="campus_list" label="cnt_name" :reduce="value => value.cnt_cd" v-model="campus" :clearable="false"  :disabled="campus != '0000' && campus != '0600'"  ></vSelect>
                            </div>
                        </div>



                        <!-- 요청에 따른 히든 처리 -->
                      <!--
                      <div class="w-full lg:py-2 lg:items-center mb-2" id="flag1">
                        <div class="w-full text-md font-semibold mb-1">{{ i18n.myoffice.LookallowuplineList }}</div>
                        <div class="w-full">
                          <input type="radio" id="us-flag1" name="us-flag" v-model="flag_4week" value="Y" onclick="" :checked="myconfig.flag_4week == 'Y'" > {{ i18n.myoffice.MyMember.allow }}
                          <input type="radio" id="us-flag2" name="us-flag" v-model="flag_4week" value="N" onclick="" :checked="myconfig.flag_4week == 'N'" > {{ i18n.myoffice.MyMember.block }}
                        </div>
                      </div>
                      <div class="w-full lg:py-2 lg:items-center mb-2" id="flag2">
                        <div class="w-full text-md font-semibold mb-1">{{ i18n.myoffice.LookmyofficeList }}</div>
                        <div class="w-full">
                          <input type="radio" id="us-myflag1" name="us-myflag" v-model="flag_profit" value="Y" onclick="" :checked="myconfig.flag_profit == 'Y'" >
                          {{ i18n.myoffice.MyMember.allow }}
                          <input type="radio" id="us-myflag2" name="us-myflag" v-model="flag_profit" value="N" onclick=""  :checked="myconfig.flag_profit == 'N'" >
                          {{ i18n.myoffice.MyMember.block }}
                        </div>
                      </div>
                      -->
                    </div>
                    <div class="flex space-x-2">
                      <button type="button" v-on:click="show_modify" class="my-2 py-2 px-4 leading-6 text-white bg-gray-500 w-24 text-center w-1/2 xl:1/5 lg:w-1/5 rounded">Cancel</button>
                      <button type="button" v-on:click="submitForm"  class="my-2 py-2 px-4 leading-6 text-white bg-gray-900 w-24 text-center w-1/2 xl:1/5 lg:w-1/5 rounded">{{ i18n.myoffice.save }}</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

  import numeral from 'numeral';
  import 'numeral/locales';
  import VueSimpleAlert from "vue3-simple-alert";
  import {  ShoppingCartIcon} from '@heroicons/vue/outline'
  import MypageTop from '../MypageTop.vue';
  import SideMenu from '../SideMenu.vue';
  import Loading from 'vue3-loading-overlay';
  import alert from "vue3-simple-alert";
  import {createPopper} from "@popperjs/core";
  import modalView from "../../modalView";
  import vSelect from "vue-select";

  export default {
    name: "memberinfo",
    components : {
      VueSimpleAlert,
      ShoppingCartIcon,
      MypageTop,
      SideMenu,
      Loading,
      modalView,
        vSelect,
    },
    data: () => ({
      i18n: window.i18n,
      laravel: window.Laravel,
      no_search_messsage: '',
      isLoggedIn: false,

      loading : false,
      mem: "",
      myconfig:"",
      state : "",
      //flag_4week : "",
      //flag_profit : "",
      tel : "",
      mobile : "",
      e_mail : "",
      pass1 : "",
      pass2 : "",
      addr2_1 : "",
      addr2_2 : "",
      Street : "",
      post : "",
      city : "",
      selectState : "",
      depositor : "",
      bank : "",
      bank_key_list : "",
      accInfoCk : "",
      account : "",
      routingNo : "",
      acc_type: "",
      doc_void : "",
      doc_w9 : "",
      mobileCheckYn : "N",
      emailCheckYn : "N",
      selectCity : "",
      fullPage : true,
      state_list : "",
      city_list : "",
      street_list : "",
      accountCheck : {"check_yn" : "N","memo" : "","file_name" : ""},
      address_search_open:false,
      isMatchStateCity:false,
        campus_list : "",
        campus : ""
    }),
    beforeCreate() {
      axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        this.mem = response.data;
        this.addr2_1 = response.data.addr2_1;
        this.addr2_2 = response.data.addr2_2;
        this.Street = response.data.street;
        this.city = response.data.city;
        this.selectState = response.data.state;
        this.post = response.data.post;
        this.campus = response.data.cnt_cd;

        this.mobile = response.data.mobile;
        this.tel = response.data.tel != "null" ? response.data.tel : '';
        this.e_mail = response.data.e_mail;

        this.depositor = response.data.depositor;
        this.account = response.data.account != "null" ? response.data.account : "";
        this.bank = response.data.bank_cd;
        this.campus_list = response.data.campus_list;

        if(response.data.rank_cd!="00")
        {
          $("#r_id_chk").hide();
          $("#flag1").hide();
          $("#flag2").hide();

        }
      });
      axios.post(window.Laravel.back_url + '/api/getMyConfig', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        this.myconfig = response.data;

      });

    },
    created() {

    },
    mounted() {
      if (window.Laravel.isLoggedin) {
        this.isLoggedIn = true;
        this.user = window.Laravel.user;
      }
    },
    methods:{
      submitForm: function (event) {
        let formData = new FormData();
        alert("123");
        if(!this.addr2_1 || this.addr2_1.length >= 40){
          alert.alert('You can enter up to 40 characters for the detailed address.');
          $("#addr2_1").focus();
          return false;
        }

        if(!this.Street){
          alert.alert('Please enter Street');
          $("#street").focus();
          return false;
        }

        formData.append('addr2', this.addr2_1 + '|@|' + this.addr2_2);
        formData.append('street', this.Street);
        formData.append('city', this.city);
        formData.append('selectState', this.selectState);
        formData.append('post', this.post);
        formData.append('mobile', this.mobile);
        formData.append('tel', this.tel);
        formData.append('e_mail', this.e_mail);
        formData.append('depositor', this.depositor);
        formData.append('account', this.account);
        formData.append('bank', this.bank);
        formData.append('acc_type', this.acc_type);
        formData.append('routingNo', this.routingNo);
        formData.append('mobileCheckYn', this.mobileCheckYn);
        formData.append('emailCheckYn', this.emailCheckYn);
        formData.append('pass1', this.pass1);
        formData.append('pass2', this.pass2);
        formData.append('flag_4week', this.flag_4week);
        formData.append('flag_profit', this.flag_profit);
          formData.append('cnt_cd', this.campus);

        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + window.Laravel.access_token
          }
        }

        this.loading = true;
        axios.post(window.Laravel.back_url+'/api/memberModify',
          formData
          , config).then(response => {
          if(response.data.result == "1"){
            VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
              if(result){
                location.replace("/Mypage/memberInfo/Y");
              }
            });

          }
          if(response.data.result == "0"){
            VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
              if(result){
              }
            });
          }

          this.loading = false;
        });

      },
      checkEmail(){
        axios.post(window.Laravel.back_url+'/api/getComparisonEmail', {
          email : this.e_mail,
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
          if (response.data.chk.checker == "Y") {
            $("#e_mail").attr("readonly", true);
            $("#e_mail").css("background", "#d4d4d4");

            $("#emailCheckYn").val("Y");
            this.emailCheckYn = "Y";
            $("#emailCheck").hide();
            $("#emailCheckTxt").text(i18n.join.join.alert_emailchk4); /* 사용할 수 있는 핸드폰 번호입니다. */
            $(".btn_emailChk").css("display","none"); // 휴대폰 번호 체크 후에는 버튼 영역도 숨김 처리

          } else {
            $("#emailCheckYn").val("N");
            this.emailCheckYn = "N";
            $("#emailCheck").show();
            alert(i18n.join.join.alert_emailchk1);
            $("#emailCheckTxt").text(i18n.join.join.alert_emailchk1); /* 사용할 수 없는 핸드폰 번호입니다. */
            $(".btn_emailChk").css("display","none"); // 휴대폰 번호 체크 후에는 버튼 영역도 숨김 처리

          }
        });
      },
      checkMobile(){
        if(this.mobile.length < 10) {
          alert("Please enter the correct phone number.");
          return false;
        }
        axios.post(window.Laravel.back_url+'/api/getComparisonMobile', {
          mobile : this.mobile,
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
          if (response.data.chk.checker == "0") {
            $("#mobile").attr("readonly", true);
            $("#mobile").css("background", "#eaeaea");

            $("input[id='mobileCheckYn']").val("Y");
            this.mobileCheckYn = "Y";
            $("#vertifyMobile").val("Y");
            $("#mobileCheck").hide();
            $(".btn_mobileChk").css("display","none"); // 휴대폰 번호 체크 후에는 버튼 영역도 숨김 처리
            $("#mobileCheckTxt").text(i18n.myoffice.myofficeAlert.alert20); /* 사용할 수 있는 핸드폰 번호입니다. */
          } else {
            $("input[id='mobileCheckYn']").val("N");
            this.mobileCheckYn = "N";
            $("#vertifyMobile").val("N");
            $("#mobileCheck").show();
            $(".btn_mobileChk").css("display","block");
            alert(i18n.join.join.memformtext27);
            $("#mobileCheckTxt").text(i18n.myoffice.myofficeAlert.alert21); /* 사용할 수 없는 핸드폰 번호입니다. */
          }
        });

      },
      searchAddress(){
        axios.post(window.Laravel.back_url+'/api/getUsZipcode', {
          post_code : this.post,
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
          if( response.data.address.length > 0 ){
            this.selectState = response.data.address[0]['state'];
            this.city = response.data.address[0]['city'];

            //$('#post').val(seq);
            $("#selectCity").html("");
            var option = "";
            var postcode_localities = response.data.address;
            if (postcode_localities !== undefined && postcode_localities.length > 1) {
              option += "<option>select</option>";
              for (var i = 0; i < postcode_localities.length; i++) {
                option += "<option value='"+postcode_localities[i]["city"]+"'>"
                  + postcode_localities[i]["city"]
                  + "</option>";
              }
              $("#selectCity").html(option);

            }
          }else {
            $('#post').val($('#r_post').val());
            alert("Please enter a valid 5 digit zipcode.");
          }
        });

      },
      // 우편번호  가져오기
      getPostState(){   // 주(State) 목록 가져오기
        axios.post(process.env.MIX_BACK_URL+'/api/getState',{

        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
          .then(response => {
            // this.shipping_info["d_state"] = value;
            this.state_list = response.data;

            // console.log(this.state_list);
          })
          .catch(e => {
          });
      },
      onChangeState(value) {   // 주(State) 선택

        axios.post(process.env.MIX_BACK_URL+'/api/getCity',{
          state: value,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
          .then(response => {
            this.city = "";
            this.Street = "";
            this.post = "";
            this.city_list = response.data;
            // console.log(this.city_list);
          })
          .catch(e => {
          });
      },
      onChangeCity(value) {  // 도시(City) 선택
        // console.log(event.target.value)
        axios.post(process.env.MIX_BACK_URL+'/api/getStreet',{
          state: this.selectState,
          city: value,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
          .then(response => {
            this.Street = "";
            this.post = "";
            this.street_list = response.data;
            // console.log(response.data);
          })
          .catch(e => {
          });
      },
      onChangeStreet(value) {  // 도로(Street) 선택
        axios.post(process.env.MIX_BACK_URL+'/api/getPostCodeForDelivery',{
          state: this.selectState,
          city: this.city,
          street: value,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
          .then(response => {
            this.post =  response.data.postcode;
            this.post_list = response.data;
            // console.log(response.data);
          })
          .catch(e => {
          });
      },
      withPopper(dropdownList, component, { width }) {
        /**
         * We need to explicitly define the dropdown width since
         * it is usually inherited from the parent with CSS.
         */
        dropdownList.style.width = width

        /**
         * Here we position the dropdownList relative to the $refs.toggle Element.
         *
         * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
         * the dropdownList overlap by 1 pixel.
         *
         * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
         * wrapper so that we can set some styles for when the dropdown is placed
         * above.
         */
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: "top",
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1],
              },
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn({ state }) {
                component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top'
                )
              },
            },
          ],
        })

        /**
         * To prevent memory leaks Popper needs to be destroyed.
         * If you return function, it will be called just before dropdown is removed from DOM.
         */
        return () => popper.destroy()
      },
      // 숫자 포멧
      formatPrice(value,digit) {
        let val = (value/1).toFixed(digit).replace('.', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      search_address(){
        this.address_search_open = true;
      },
      set_address(value){
        this.address_search_open = false;
        this.selectState =value.state;
        this.city = value.district;
        this.Street = "";
        this.post = value.postcode;
      },
      close_address_search(){
        this.address_search_open = false;
      },
      show_modify(){
        if($("#infomodify").css("display") == "none")
        {
          $("#infomodify").show();
          $("#info").hide();

        }
        else
        {
          $("#infomodify").hide();
          $("#info").show();
        }
      }
      },

  }

</script>

<style scoped>

</style>
