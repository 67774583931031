<template>
    <dl id="admin_page">

        <article class="myo_search_area">
            <form name='form_search' style="clear:both;"  v-on:submit.prevent="submitForm">
                <section class="myo_condition_box">
                    <dl class="myo_search_list">
                        <dt>{{ i18n.search.find }}</dt><!-- 검색 -->
                        <dd class="">
                            <section class="flex_box">
                                <div class="myo_select_box">
                                    <vSelect :options="list_temp_str" label="value"
                                              :reduce="value => value.title" :clearable="false" v-model="grp_main_cd"  ></vSelect>
                                </div>
                              <div class="myo_select_box">
                                <vSelect :options="listSubCategory" label="value" :reduce="value => value.title" v-model="grp_cd" :clearable="false"  ></vSelect>
                              </div>
                                <div class="myo_search_box">
                                    <input type="text" class="myo_search_input" name="search_text" v-model="search_text">
                                </div>
                            </section>
                        </dd>
                    </dl>
                </section><!-- // myo_condition_box -->

                <div class="myo_condition_search2">
                    <button type="reset" onclick="location.replace('/MyInQuriy')" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                    <button type="submit" class="search_btn btn-eft-none">{{ i18n.myoffice.Search }}</button>
                </div>
            </form>
        </article>

        <div class="position_relate_box">
            <abbr title="{{ i18n.myoffice.myofficeAlert.CanTablescroll2 }}" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
        </div>

        <div class="list-table-wrap">
            <table class="list-table notice_list_box">
                <thead>
                <tr>
                    <th data-priority="1">{{ i18n.inquriy.inquriy.no }}</th>
                    <th data-priority="2">{{ i18n.inquriy.inquriy.status }}</th>
                    <th data-priority="3">{{ i18n.inquriy.inquriy.category }}</th>
                    <th data-priority="4">{{ i18n.inquriy.inquriy.title }}</th>
                    <th data-priority="5">{{ i18n.inquriy.inquriy.questionDate }}</th>
                    <th data-priority="6">{{ i18n.inquriy.inquriy.questioner }}</th>
                    <th data-priority="7">{{ i18n.inquriy.inquriy.answerDate }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(Value,index) in List" class="list_hover "   style='cursor:pointer' v-bind:style="Value.notice_yn == 'Y' ? 'background:#f7f7f7' : ''" @click="modify(Value.href)">

                    <th class="px-6 py-3 whitespace-nowrap">{{ total_count - ((page-1)*20 + index) }}</th><!-- 무실적경과일 -->
                    <td align="center">{{ Value.flags }}</td>
                    <td align="center">{{ Value.cate_name }}</td>
                    <td align="left">{{Value.title}}<em v-show="Value.reply_cnt" class="inquriy badge">{{ Value.reply_cnt }}</em></td>
                    <td style="overflow: hidden;">{{  Value.reg_date  }}</td>
                    <td align="center">{{  Value.reply_username  }}</td>
                    <td align="center">{{  Value.reply_regdate  }}</td>

                </tr>
                <tr class="h-2" v-if="total_count == 0">
                    <td class="text-left md:text-center pl-5 py-5" colspan="13">No Data</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="myo_condition_search2" style="margin-top:20px;">
            <button type="button" class="srh faq_write_btn" @click="goWrite">
            <span class="btn-eft-none">
                {{ i18n.inquriy.inquriy.writing }}
            </span>
            </button>
        </div>

        <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
            <v-pagination
                v-model="page"
                :pages=total_page
                :range-size="1"
                active-color="#DCEDFF">
            </v-pagination>
        </div>
    </dl>
</template>

<script>
import vSelect from "vue-select";
import VPagination from "@hennge/vue3-pagination";
import Loading from "vue3-loading-overlay";
import VueSimpleAlert from "vue3-simple-alert";
import alert from "vue3-simple-alert";

export default {
    name: "MyInQuriy",
    components : {
        VPagination,
        Loading,
        vSelect,
    },
    props:['mid_mypage'],
    data: () => ({
        isInit:true,
        i18n: window.i18n,
        List : [],
        total_count : 0,
        page : 1,
        total_page : 1,
        mid : window.location.pathname,
        grp_main_cd : "Category",
        search_text : "",
        list_temp_str : [],
        list_sub_category : [],
        listSubCategory : [],
        grp_cd : "Sub Category"
    }),
    watch : {
        page : function(){
            this.submitForm();
        },
        grp_main_cd: function (value) {
          console.log(value)
          this.listSubCategory = this.list_sub_category[value]
          this.grp_cd = ''
        }
    },
    created : function (){
        this.submitForm();

    },
    methods : {
        submitForm : function (){

            if(this.isInit === false) {
              if (this.grp_main_cd !== '' && this.grp_main_cd !== undefined && this.grp_cd === '') {
                alert.alert(i18n.inquriy.inquriy.noSubcategory, "", "", {
                  returnFocus: false, didClose: () => {
                  }
                });
                return;
              }
            }

            axios.post(window.Laravel.back_url+'/api/InQuiry',{
                page : this.page,
                page_size : 20,
                mid : this.mid_mypage ? this.mid_mypage : this.mid,
                search_text : this.search_text,
                category : (this.isInit===true) ? '' : this.grp_cd
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.List = response.data.list.data;
                this.list_temp_str = response.data.list_temp_str;
                this.list_sub_category = response.data.list_sub_category;
                this.total_count = response.data.total_count;
                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
                this.isInit = false

            });

        },
        goWrite(){
            if(this.mid_mypage){
              location.href='/Mypage/InQuiryModify/0';
            }else{
              location.href='/MyInQuiryModify/0';
            }

        },
        modify(link){
          location.href=link;
        }
    }

}
</script>

<style scoped>

</style>
