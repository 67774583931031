<template>
    <loading :active="false"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>
    <div class="member-search">
      <dd class="accordion_cont w-full block lg:grid lg:grid-cols-4 lg:gap-2">
        <div  class="z-[12] lg:mb-0 mb-2">
          <vSelect :options="date_type_list"
                   label="title" placeholder="Select year"
                   :clearable="false" @update:modelValue="setSelect"></vSelect>
        </div>
      </dd>
    </div>

    <div class="summary" style="clear: both;"><strong>Total</strong> <em>{{ total_count }}</em></div>
    <input type=hidden name=exec_mode value=''>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">

        <table id="member_list" class="relative overflow-x-auto shadow-md sm:rounded-lg" >
            <thead>
            <tr class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
              <th class="py-3 pr-4 min-w-[100px] whitespace-nowrap">Commission Year</th>
              <th class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">Team Bonus</th>
              <th class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">Matching Bonus</th>
              <th class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">Builder Bonus</th>
              <th class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">Promotion Bonus</th>
              <th class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">Academy Bonus</th>
              <th class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">Rank Maintenance Bonus</th>
              <th class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">Deduction For Return</th>
              <th class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">Others</th>
              <th class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">Total Amount (RM)</th>
              <th class="py-3 min-w-[100px] whitespace-nowrap">Download</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(Value,index) in cp58 ">
              <td class="py-3 pr-4 min-w-[100px] text-center whitespace-nowrap">{{Value.years}}</td>
              <td class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">{{formatPrice(Value.team_bonus)}}</td>
              <td class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">{{formatPrice(Value.matching_bonus)}}</td>
              <td class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">{{formatPrice(Value.builder_bonus)}}</td>
              <td class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">{{formatPrice(Value.promotion_bonus)}}</td>
              <td class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">{{formatPrice(Value.academy_bonus)}}</td>
              <td class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">{{formatPrice(Value.rank_maintenance_bonus)}}</td>
              <td class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">{{formatPrice(Value.deduction_for_return)}}</td>
              <td class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">{{formatPrice(Value.reduction_other)}}</td>
              <td class="py-3 pr-4 min-w-[100px] text-right whitespace-nowrap">{{formatPrice(Value.actual_amount)}}</td>
              <td class="py-3 min-w-[100px] text-center whitespace-nowrap"><button v-on:click="popup_cp58(Value.years);" class="p-2 m-0 text-sm text-white bg-gray-500 rounded">View CP58</button></td>
            </tr>
            </tbody>
        </table>
    </div>
    <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
        <v-pagination
            v-model="page"
            :pages=total_page
            :range-size="1"
            active-color="#DCEDFF">
        </v-pagination>
    </div>
</template>

<script>

import dateForm from '../../searchForm/dateForm.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";
import vSelect from "vue-select";

export default {
    name: "Mycp58",
    components: {
        dateForm,
        VPagination,
        Loading,
        vSelect
    },setup() {
        //
    },
    data: () => ({
        i18n: window.i18n,
        title: [],
        products: [],
        page : 1,
        total_page : 1,
        total_count : 0,
        years: [],
        s_year: "",

        checkCondition : false,
        name_list : [],
        List : [],
        loading : false,
        list_msg : "Click 'Search' to view the data",
        cnt_top_chk : 0,
        cp58: "",
        date_type_list : [],
    }),
    beforeCreate() {


    },

    created() {
        // 배너
        this.loading = true;
        this.setCpList();
        this.setSelOpt();
    },
    methods:{
        setSelOpt(){
          this.date_type_list =[{title : "All", value : ""}];//선택 옵션값 초기화
          const year = new Date().getFullYear()
          this.years = Array.from({length: year - 2022}, (value, index) => 2022 + index)
          for(let i = 0; i<this.years.length;i++){
            this.date_type_list.push({title:this.years[i],value:this.years[i]});
          }
        },
        setCpList(){
          axios.post(window.Laravel.back_url + '/api/getCommissionYearly', {year : this.s_year,}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.cp58 = response.data;
            this.total_count = response.data.length;
          });
        },
        formatPrice(value) {
          let val = (value/1).toFixed(2).replace(',', '.')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        setSelect(year){
           //년도 변경시 자동 출력
            this.s_year = year.value;
            this.setCpList();
        },
        popup_cp58(year) {
          window.open(window.Laravel.back_url+"/api/getCp58?userid="+window.Laravel.user+"&year="+year);
        }

    },
    watch : {
      // s_year(){
      //   this.setCpList();
      // }
    }
}
</script>

<style scoped>

</style>
