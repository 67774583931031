<template>
    <div class="modal" v-if="open_modal">
        <div class="overlay" @click="open_modal = false"></div>
        <div class="modal-harf-card2">
            <popup @set_modal_flag="set_modal_flag" />
            <div class="popup-btn">
                <button  v-on:click="set_close_cookie" class="popup-close-btn1">{{ i18n.shop.popup.noOpen }}</button> <!--하루동안 안보기-->
                <button v-on:click=" close " class="popup-close-btn2 popup-close-btn">{{ i18n.shop.popup.close }}</button> <!--닫기-->
            </div>
        </div>
    </div>
</template>

<script>

import popup from './popup';
import VueCookies from "vue-cookies";

export default {
    name: "modalViewPopup",
    props : ['harf'],
    data: () => ({
        open_modal :true,
        i18n: window.i18n,

    }),
  created() {

  },
    components : {
        popup,
        VueCookies
    },
    methods : {
      set_close_cookie() {
        $cookies.set("popup_timer", "1", "1d");
        this.open_modal = false;
      },
      close() {
        this.open_modal = false;
      },
      set_modal_flag(open_modal){
        this.open_modal = open_modal;//3
      },

    },
  watch:{

  }
}
</script>

<style >
/* Modal */
.modal,
.overlay {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 998;
}
.overlay {
    opacity: 0.5;
    background-color: black;
}

.modal-harf-card2 {
    position: relative;
    max-width: 500px;
    min-width: 360px;
    margin: auto;
    margin-top: 30px;
    padding: 20px;
    z-index: 999;
    opacity: 1;
    overflow-y: auto;
}
.popup-btn{display: table; table-layout: fixed; width:100%; max-width:500px; margin-top:-4px; margin:0 auto; background:#0abab5;}
.popup-btn button{ display:inline-block; width:50%; color:#fff; padding:15px 0; font-size:1.0em;}
/*#main-popup-layer .popup-btn{display: table; table-layout: fixed; width: 100%; margin-top:-4px;}
#main-popup-layer .popup-btn button{ display:table-cell; width:50%; color:#fff; padding:15px 0; font-size:1.2em;}*/
.popup-close-btn1{background:#0edad6; border-right:1px solid #fff;}
/*#main-popup-layer .popup-close-btn2{background:#0abab5; border-left:1px solid #fff;}
/*슬릭 슬라이드 시작 멈춤*/
/*#main-popup-layer .slick-play-btn2{position:absolute; bottom:90px; right:10px; display:block;}*/
.slick-play-btn2{position:absolute; bottom:90px; right:10px; display:none;}

</style>
