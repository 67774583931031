<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage">
    </loading>
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
        <address_search @set_address="set_address" @close_address_search="close_address_search" />
    </modalView>

    <section class="container mx-auto lg:py-6 md:p-0">
        <div class="bg-white">
            <div class="max-w-8xl mx-auto py-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
                <MypageTop :laravel="laravel"></MypageTop>
                <div class="w-full lg:flex lg:justify-start">
                    <div class="hidden lg:block xl:block">
                        <SideMenu></SideMenu>
                    </div>
                    <div class="w-full">
                        <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
                            <div class="w-full pb-10">
                                <div class="flex justify-start item-start flex-col">
                                    <div class="flex justify-between">
                                        <h1 class="text-lg dark:text-white lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800 mb-2">{{ i18n.menu.MyAutoshipWithdrawal}}</h1>
                                    </div>

                                    <div class="w-full border-t-2 border-black divide-y bg-white lg:p-2" id="infomodify">
                                        <div class="py-2 border-b-2 border-black">
                                            <div class="flex justify-between text-lg mb-4">
                                                <h2 class="font-semibold">{{i18n.menu.MyAutoshipWithdrawal}}</h2>
                                                <div class="flex justify-end">
                                                    <MinusSmIcon class="h-6 w-6" aria-hidden="true" />
                                                    <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                                                </div>
                                            </div>
                                        <div class="flex space-x-2">
                                            <button type="button" v-on:click="show_modify" class="my-2 py-2 px-4 leading-6 text-white bg-gray-500 w-24 text-center w-1/2 xl:1/5 lg:w-1/5 rounded">Cancel</button>
                                            <button type="button" v-on:click="submitForm"  class="my-2 py-2 px-4 leading-6 text-white bg-gray-900 w-24 text-center w-1/2 xl:1/5 lg:w-1/5 rounded">{{ i18n.shop.autoshipse2.tab_as_cancel }}</button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block lg:hidden xl:hidden mt-4">
                    <SideMenu></SideMenu>
                </div>
            </div>
            </div>
            </div>
        </div>
    </section>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from '../MypageTop.vue';
import SideMenu from '../SideMenu.vue';
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import {createPopper} from "@popperjs/core";
import modalView from "../../modalView";
import vSelect from "vue-select";

export default {
    name: "AutoShipWithdrawal",
    components : {
        VueSimpleAlert,
        ShoppingCartIcon,
        MypageTop,
        SideMenu,
        Loading,
        modalView,
        vSelect,
    },
    data: () => ({
        i18n: window.i18n,
        laravel: window.Laravel,
        no_search_messsage: '',
        isLoggedIn: false,
        loading : false,
        mem: "",
        addr2_1 : "",
        addr2_2 : "",
        Street : "",
        post : "",
        city : "",
        selectState : "",
        address_search_open:false,
        autoship : "",
    }),
    beforeCreate() {

    },
    created() {
        this.loading = true;
        axios.post(process.env.MIX_BACK_URL+'/api/getNormalAsMst',{
            bz_cd: window.Laravel.bz_cd,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
            .then(response => {
                this.autoship = response.data;
                if(response.data == ""){
                    VueSimpleAlert.alert("Sign up for GLP","","",{allowOutsideClick : false}).then((result) => {
                        if(result){
                            location.replace("/");
                        }
                    });
                }
                this.post = response.data.post;
                this.Street = response.data.street;
                this.city = response.data.city;
                this.selectState = response.data.state;
                this.addr2_1 = response.data.addr2_1;
                this.addr2_2 = response.data.addr2_2;
                this.loading = false;   // this.getCartList(response.data);
            })

    },
    mounted() {
    },
    methods:{
        submitForm: function (event) {
            let formData = new FormData();

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + window.Laravel.access_token
                }
            }

            VueSimpleAlert.confirm("Are you sure you want to cancel?","","",{allowOutsideClick : false}).then((result) => {
                if(result){
                    this.loading = true;
                    axios.post(window.Laravel.back_url+'/api/autoShipAddressWithdrawal',formData
                        , config).then(response => {
                        if(response.data.result == "1"){
                            VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
                                if(result){
                                    location.replace("/");
                                }
                            });

                        }
                        this.loading = false;
                    });
                }
            });

        },
        search_address(){
            this.address_search_open = true;
        },
        set_address(value){
            this.address_search_open = false;
            this.selectState =value.state;
            this.city = value.district;
            this.Street = "";
            this.post = value.postcode;
        },
        close_address_search(){
            this.address_search_open = false;
        },
    },

}

</script>

<style scoped>

</style>
