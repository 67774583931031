<template>
    <div class="member-search">
        <loading :active="loading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"></loading>

        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <input type="hidden" name="order" id="order">
            <input type="hidden" name="sort" id="sort">
            <section class="myo_condition_box">
                <div style="z-index: 50; position: relative">
                  <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date" @set_date_type="set_date_type" :date_type="date_type"  :is_view = "true"></dateForm>
                </div>

                <section class="flex_box_quad" style="z-index: 0">
                    <memberForm :selectid="selectid"></memberForm>
                    <dl class="myo_search_list">
                        <dt>{{ i18n.myoffice.myoSearchForm.Line }}</dt>
                        <dd>
                            <vSelect :options="leg_cds" :reduce="value => value.id" v-model="type_leg"   ></vSelect>
                        </dd>
                    </dl>
                    <stepForm></stepForm>
                </section>

                <div class="myo_accordion">
                    <div class="accordion_item">
                            <a class="accordion_tit" href="#n">{{ i18n.myoffice.myoSearchForm.searchSetting }} <span class="accordion_sub_tit">{{ i18n.myoffice.moreview }}</span>
                                <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-down"></i></span></div>
                            </a>

                            <div class="accordion_cont w-full">
                              <div class="lg:grid lg:grid-cols-4 lg:gap-2">
                                <div class="w-full z-[14] relative">
                                  <countryForm @set_bz_cd="set_bz_cd" :bz_cd="bz_cd"></countryForm>
                                </div>
                                <div class="w-full z-[13] relative">
                                  <orderClassification @set_ord_kind="set_ord_kind"></orderClassification>
                                </div>
                                <div class="w-full z-[12] relative">
                                  <ordeType @set_kind_cd="set_kind_cd"></ordeType>
                                </div>
                                <div class="w-full z-[11] relative">
                                  <transactionStatus @set_rcpt_yns="set_rcpt_yns"></transactionStatus>
                                </div>
                                <div class="z-10 col-span-4  relative">
                                  <rankForm  @setSelected="setSelected" ></rankForm>
                                </div>
                              </div>
                          </div>

                    </div><!--// accordion_item -->
                </div><!--// myo_accordion -->

            </section>

            <div class="myo_condition_search">
                <button type="reset" onclick="location.replace('/MyOrderDown')" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>
        </form>
    </div>

    <p class="p_alert">{{ i18n.myoffice.myofficeAlert.ClickStarButtonNotice }}</p><!-- 아래 리스트 중 회원번호 옆 별을 클릭(터치)하여 회원을 즐겨찾기에 등록 및 삭제가 가능합니다. -->

    <div class="summary position_relate_box mt-6" style="clear: both;"><strong>Total</strong> <em>{{ total_count }}</em></div>

    <input type=hidden name=exec_mode value=''>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg z-0">

        <table id="order_list" class="relative overflow-x-auto shadow-md sm:rounded-lg w-full" >
            <thead>
            <tr class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
                <th class="px-6 py-3 whitespace-nowrap">No</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.ordKind}}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.ordno}}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.MemberId}}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.memberName}}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.orderDate}}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.depositdate}}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.amount}}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.bv}}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.type}}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.country1}}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.depositamount}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(Value,index) in orderList" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ total_count - ((page-1)*page_size + index) }}</td>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.ord_kinds }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap"><a class="text-blue-400 underline underline-offset-1" :href="Value.href">{{ Value.ord_no }}</a></th>
                <th class="px-6 py-3 w-3 whitespace-nowrap"><div class="user_menu" :data-enc_id="Value.enc_id" :data-userid="Value.userid" >{{ Value.userid }}</div></th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.username }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.ord_date2 }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.acc_date2 }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ formatPrice(Value.ord_amt,2) }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ formatPrice(Value.ord_pv,1) }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.ord_kinds }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.bz_cd }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.rcpt_yns }}</th>
            </tr>
            <tr v-if="total_count == 0">
                <td colspan="13" style="text-align: center">
                  {{ this.list_msg }}
                </td>
            </tr>

            </tbody>
        </table>
    </div>
    <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
        <v-pagination
            v-model="page"
            :pages=total_page
            :range-size="1"
            active-color="#DCEDFF">
        </v-pagination>
    </div>
</template>

<script>
import dateForm from '../../searchForm/dateForm.vue'
import memberForm from '../../searchForm/memberForm.vue'
import stepForm from '../../searchForm/stepForm.vue'
import divisionForm from '../../searchForm/divisionForm.vue'
import countryForm from '../../searchForm/countryForm.vue'
import orderClassification from '../../searchForm/orderClassification.vue'
import rankForm from '../../searchForm/rankForm.vue'
import ordeType from '../../searchForm/ordeType.vue'
import transactionStatus from '../../searchForm/transactionStatus.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment/moment";

export default {
    name: "MyOrderDown",
    components : {
        dateForm,
        memberForm,
        stepForm,
        divisionForm,
        countryForm,
        orderClassification,
        rankForm,
        ordeType,
        transactionStatus,
        VPagination,
        Loading,
        vSelect
    },
    data: () => ({
        i18n: window.i18n,
        rank_cd : "",
        orderList : [],
        total_count : 0,
        page : 1,
        page_size  : 20,
        total_page : 1,
        loading : false,
        fullPage : true,
        size: '45px',
        order : 'level',
        sort: 'desc',
        s_date: '',
        e_date: '',
        date_type : 'order',
        bz_cd: "",
        ord_kind : "",
        kind_cd : "",
        rcpt_yns : "",
        list_msg : "Click 'Search' to view the data",
        type_leg : "",
        leg_cds : [
            { "id": "P", "label": i18n.myoffice.Supportline },
            { "id": "PL", "label": i18n.myoffice.SupportlineLeft },
            { "id": "PR", "label": i18n.myoffice.SupportlineRight },
            { "id": "R", "label": i18n.myoffice.Recommendline } ]
    }),
    computed : {

    },
    props:['level','search_id','sid_name','selectid'],
    watch : {
        page: function(){
            $("#submit").click();
        }
    },
    created : function (){
        $("#submit").click();
    },
    methods : {
        submitForm : function (event){
            event.preventDefault();
            this.loading = true;
            //console.log(this.date_type);
            axios.post(window.Laravel.back_url+'/api/getMyOrderDown',{
                s_date     : this.s_date,
                e_date     : this.e_date,
                level      : level.value,
                sid        : search_id.value,
                bz_cd      : this.bz_cd,
                ord_kind   : this.ord_kind,
                diff       : this.diff,
                kind_cd    : this.kind_cd,
                rcpt_yns   : this.rcpt_yns,
                page       : this.page,
                order      : this.order,
                sort       : this.sort,
                date_type  : this.date_type,
                type_leg   : this.type_leg,
                page_size  : this.page_size,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.orderList = response.data.list.data;
                this.total_count = response.data.total_count;
                this.total_page = parseInt(this.total_count/this.page_size)+parseInt(this.total_count%this.page_size > 0 ? 1 : 0);
                this.loading = false;
                if(this.total_count == 0){
                    VueSimpleAlert.alert("There are no search results","","",{});
                    this.list_msg = "No data";
                    this.sid_name = "";
                }

            });

        },
        setSelected(rank){
            this.rank_cd = "";
            rank.forEach((value, index) => {
                this.rank_cd += value.id
                if(rank.length != index+1){
                    this.rank_cd += ",";
                }
            });
        },
        updatePageHandler(page){
            //this.page = page;
        },
        notCheck(id){
            return this.order != id ? true : false;
        },
        asconCheck(id){
            return this.order == id && this.sort == "asc" ? true : false;
        },
        descCheck(id){
            return this.order == id && this.sort == "desc" ? true : false;
        },
        reSortArray(id){
            this.order = id;
            this.sort = this.sort == "asc" ? "desc" : "asc";
            $("#submit").click();

        },
        set_s_date(s_date){
          this.s_date = moment(s_date).format('YYYY-MM-DD');
        },
        set_e_date(e_date){
          this.e_date = moment(e_date).format('YYYY-MM-DD');
        },
        moment: function () {
          return moment();
        },
        set_date_type(date_type){
            this.date_type = date_type;
        },
        set_bz_cd(bz_cd){
            this.bz_cd = bz_cd;
        },
        set_ord_kind(ord_kind){
            this.ord_kind = ord_kind;
        },
        set_kind_cd(kind_cd){
            this.kind_cd = kind_cd;
        },
        set_rcpt_yns(rcpt_yns){
            this.rcpt_yns = rcpt_yns;
        },
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

    }
}
</script>

<style scoped>

</style>
