<template>
    <div class="member-search">
        <loading :active="loading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage">
        </loading>
        <article class="myo_search_area">
          <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
              <input type="hidden" name="order" id="order">
              <input type="hidden" name="sort" id="sort">
              <section class="myo_condition_box">
                  <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date"></dateForm>
              </section>
              <div class="myo_condition_search">
                  <button type="reset" onclick="" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                  <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
              </div>
          </form>
        </article>
    </div>

    <p class="p_alert">{{ i18n.myoffice.myofficeAlert.ClickStarButtonNotice }}</p><!-- 아래 리스트 중 회원번호 옆 별을 클릭(터치)하여 회원을 즐겨찾기에 등록 및 삭제가 가능합니다. -->

    <div class="summary position_relate_box mt-6" style="clear: both;"><strong>Total</strong> <em>{{ total_count }}</em></div>

        <input type=hidden name=exec_mode value=''>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">

        <table id="order_list" class="relative overflow-x-auto shadow-md sm:rounded-lg w-full" >
            <thead>
            <tr class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.orderDate }}</th><!-- 주문일 -->
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.shop.shopping.orderInfomation }}</th><!-- {{ i18n.shop.shopping.OrderNum") -->
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.depositdivision }}</th><!-- 구분 -->
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.type }}</th><!-- 구분 -->
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.shop.etc.OrderStatus }}</th><!-- 구분 -->
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.refund.apply }}</th><!--배송여부 -->
            </tr>
            </thead>
            <tbody>
            <tr v-for="(Value,index) in orderList" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.ord_date }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap text-left">
                    <div><strong>[{{ i18n.shop.shopping.OrderNum }}]</strong>&nbsp;<span class="ord-no bold high-lighter" >{{ Value.ord_no}}</span></div>
                    <div><strong>[{{ i18n.shop.shopping.Price }}]</strong>&nbsp;<span class="ord-pay bold">{{Value.ord_amt}}</span></div>
                    <div><strong>[BV]</strong>&nbsp;<span class="ord-bv bold">{{Value.ord_pv}}</span></div>
                </th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.money_kind??'' }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.kind_name }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.deli_finish }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap" v-show="Value.r_type" v-on:click="ViewModal(Value.ord_no,Value.r_type)"><span class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer"> {{ Value.r_type.length > 2 ? (Value.r_status + ' : ' + Value.r_type) : Value.btn_name }}</span></th>
            </tr>
            <tr class="h-2" v-if="total_count == 0">
                <td class="text-left md:text-center pl-5 py-5" colspan="13">{{ this.list_msg }}</td>
            </tr>

            </tbody>
        </table>
        </div>
  <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
    <v-pagination
      v-model="page"
      :pages=total_page
      :range-size="1"
      active-color="#DCEDFF">
    </v-pagination>
  </div>
    <modalView v-if="showRModal" @close-modal="showRModal = false" :harf="false" >
        <MyViewOrderRefund :ord_no="ord_no" :r_type="r_type" ref="MyViewOrderRefund"></MyViewOrderRefund>
    </modalView>
    <modalView v-if="showModal" @close-modal="showModal = false"  :harf="false" >
        <MyViewOrderCancel :ord_no="ord_no" :r_type="r_type" ref="MyViewOrderCancel"></MyViewOrderCancel>
    </modalView>

</template>
<script>
import dateForm from '../../searchForm/dateForm.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import modalView from "../../modalView.vue";
import MyViewOrderCancel from './MyViewOrderCancel.vue'
import MyViewOrderRefund from './MyViewOrderRefund.vue'
import VueSimpleAlert from "vue3-simple-alert";


export default {
    name: "MyViewOrder",
    components : {
        dateForm,
        VPagination,
        Loading,
        modalView,
        MyViewOrderCancel,
        MyViewOrderRefund,
    },
    data: () => ({
        i18n: window.i18n,
        rank_cd : "",
        orderList : [],
        total_count : 0,
        page : 1,
        total_page : 1,
        loading : false,
        fullPage : true,
        size: '45px',
        order : 'level',
        sort: 'desc',
        s_date: date_half_month,
        e_date: date_today,
        ord_no : '',
        showModal : false,
        showRModal : false,
        r_type : '',
        list_msg : "Click 'Search' to view the data",
    }),
    computed : {

    },
    props:['level','search_id','sid_name','diff','down_name','reg_type','gpstatus','end_date'],
    watch : {
        page: function(){
            $("#submit").click();
        }
    },
    created : function (){
        this.submitForm();
    },
    methods : {
        submitForm : function (event){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getOrderCRList',{
                s_date : this.s_date,
                e_date : this.e_date,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.orderList = response.data.list.data;
                //console.log(response.data.list.data);
                this.total_count = response.data.total_count;
                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
                if(this.total_count == 0){
                    VueSimpleAlert.alert("There are no search results","","",{});
                    this.list_msg = "No data";
                    this.sid_name = "";
                }

            });

        },
        setSelected(rank){
            this.rank_cd = "";
            rank.forEach((value, index) => {
                this.rank_cd += value.id
                if(rank.length != index+1){
                    this.rank_cd += ",";
                }
            });
        },
        updatePageHandler(page){
            //this.page = page;
        },
        notCheck(id){
            return this.order != id ? true : false;
        },
        asconCheck(id){
            return this.order == id && this.sort == "asc" ? true : false;
        },
        descCheck(id){
            return this.order == id && this.sort == "desc" ? true : false;
        },
        reSortArray(id){
            this.order = id;
            this.sort = this.sort == "asc" ? "desc" : "asc";
            $("#submit").click();

        },
        set_s_date(s_date){
            this.s_date = s_date;
        },
        set_e_date(e_date){
            this.e_date = e_date;
        },
        ViewModal(ord_no,r_type){
            this.ord_no = ord_no;
            this.r_type = r_type;
            if(r_type == "C"){
                this.showModal = true;
            }
            if(r_type == "R" || r_type == "R2"){
                this.showRModal = true;
            }
        }

    }
}

$(document).ready( function () {
});
</script>
