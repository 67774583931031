<template>
    <div class="modal">
        <div class="overlay" @click="$emit('close-modal')"></div>
        <div :class="{'modal-card' : harf == false,'modal-harf-card' : harf == true}">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "modalView",
    props : ['harf'],
}
</script>

<style >
/* Modal */
.modal,
.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
}
.overlay {
    opacity: 0.5;
    background-color: black;
}
.modal-card {
    position: relative;
    max-width: 90%;
    margin: auto;
    margin-top: 30px;
    padding: 20px;
    background-color: white;
    min-height: 600px;
    z-index: 999;
    opacity: 1;
    overflow-y: auto;
    max-height: 600px;
    border-radius: 20px;
}
.modal-harf-card {
    position: relative;
    max-width: 50%;
    min-width: 360px;
    margin: auto;
    margin-top: 30px;
    padding: 20px;
    background-color: white;
    min-height: 600px;
    z-index: 999;
    opacity: 1;
    overflow-y: auto;
    max-height: 800px;
}
</style>
