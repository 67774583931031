<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="true"></loading>
  <form name="uni_form" method="post" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
    <div class="ctn-box">
      <div class="board-inner">
        <div class="myoffice_page">

          <section class="accordion_notice_box">
            <div class="accordion_notice_item">
              <a class="accordion_notice_tit" href="#n">
                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                {{ i18n.myoffice.myoSearchForm.notice }}
                <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>
              </a>
              <div class="accordion_notice_cont">
                <div class="myo_search_notice">
                  <p>{{ i18n.myoffice.myofficeAlert.uploadFileLimitedAlert }}</p>
                </div>
              </div>
            </div>
          </section>

          <ul class="fileUpWRap clr upload-box ">
            <li class="upload-list">
              <h5>Bank Information</h5>
              <ul id="myo_form">
                <li>
                  <span class="item_title">{{ i18n.join.join.bank }}</span>
                  <span class="item_content" style="text-align: left;">
                    <div class="relative z-10">
                      <vSelect :options="bank_key_list" label="bank_name" :reduce="value => value.bank_cd" v-model="bank" :clearable="false" :disabled="accountCheck.check_yn == 'Y'" ></vSelect>
                    </div>
                  </span>
                </li>
                <li>
                  <span class="item_title">{{ i18n.join.join.account }}</span>
                  <span class="item_content" style="text-align: left;"><input type="text" v-model="account" class="type_number" :readonly="accountCheck.check_yn == 'Y'" ></span>
                </li>
                <li>
                  <span class="item_title">{{ i18n.myoffice.AccountHolder }}</span>
                  <span class="item_content" style="text-align: left;">
                    <input type="text" v-model="depositor" :readonly="accountCheck.check_yn == 'Y'" >
                  </span>
                </li>
              </ul> <!-- 테이블1 -->
              <dl>
                <dt>
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img1" width="100%" height="100%" style="vertical-align:middle;" :src="accountCheck.fileurl" alt="">
                      <div v-if="accountCheck.check_yn == 'N' && accountCheck.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="accountCheck.check_yn == 'N'" type="file" name="PPFile" id="PPFile" @change="readURL">
                    <div v-if="accountCheck.check_yn == 'N' && accountCheck.memo == '' && accountCheck.fileurl != ''" class="res-depending"><span class="upload-state1">Proceeding</span></div>
                    <div v-else-if="accountCheck.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="accountCheck.check_yn == 'N' && accountCheck.memo != ''" class="res-memo">{{ accountCheck.memo }}</div>
                  </div>
                  <div class="item_content_notice" style="color:#444">
                    Please upload a scanned copy of one of the following:<br>
                    (Account information verifiable)<br>
                    - Bank Account Information Page<br>
                    - Passbook Front Page<br><br>
                    If you are uploading a capture of an online bank site that is not physical, you must upload an image with the following information:<br>
                    - Account holder name, Bank name, Branch name, Account number

                  </div>
                </dd>
              </dl>
            </li>

            <li class="upload-list">
              <!--START: 마이넘버 ------------------------------------->
              <h5>{{ pin_types }}</h5> <!-- 마이넘버 DD 앞면 -->
              <ul id="myo_form">

                <li>
                  <span class="item_title">{{ pin_types }}</span>
                  <span class="item_content" style="text-align: left;" v-if="isForigner == false">
                    <input type="text" v-model="jumin_no1" :class="{ 'jumin_check_y' : juminCheck.check_yn == 'Y' }" maxlength="6" style="width:80px" readonly> -
                    <input type="text" v-model="jumin_no2" :class="{ 'jumin_check_y' : juminCheck.check_yn == 'Y' }" maxlength="2" style="width:40px" class="w-1" readonly> -
                    <input type="text" v-model="jumin_no3" :class="{ 'jumin_check_y' : juminCheck.check_yn == 'Y' }" maxlength="4" style="width:60px" class="w-2" readonly>
                  </span>
                  <span class="item_content" style="text-align: left;"  v-if="isForigner == true">
                    <input type="text" v-model="forigner_jumin_no" :class="{ 'jumin_check_y' : juminCheck.check_yn == 'Y' }" maxlength="9" style="" readonly>
                  </span>
                </li>
              </ul>  <!-- 테이블2 -->
              <dl>
                <dt>
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img2" width="100%" height="100%" style="vertical-align:middle;" :src="juminCheck.fileurl" alt="">
                      <div v-if="juminCheck.check_yn == 'N' && juminCheck.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="juminCheck.check_yn == 'N'" type="file" name="DDFile" id="DDFile" @change="readURL">
                    <div v-if="juminCheck.check_yn == 'N' && juminCheck.memo == '' && juminCheck.fileurl != ''" class="res-depending"><span class="upload-state1">Proceeding</span></div>
                    <div v-else-if="juminCheck.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="juminCheck.check_yn == 'N' && juminCheck.memo != ''" class="res-memo">{{ juminCheck.memo }}</div>
                  </div>
                  <div class="item_content_notice mt-3" v-if="isForigner == false" style="color:#444">
                    Please upload a scanned copy of one of the following:<br>
                    Scan copy of IC(Identity Card)<br>
<!--                    - Scan copy of other Identity card with NRIC on it.-->
                  </div>
                  <div class="item_content_notice mt-3" v-if="isForigner == true" style="color:#444">
                    Please upload a scanned copy of one of the following: <br>
                    - Scan copy of PASSPORT
                  </div>
                </dd>
              </dl>
            </li>

          </ul>


          <ul class="fileUpWRap clr upload-box" v-if="isForigner == true">
            <li class="upload-list">
              <h5>Tax</h5>
              <ul id="myo_form">

                <li>
                  <span class="item_title">Tax Code</span>
                  <span class="item_content" style="text-align: left;">
                    <input type="text" id="bankkatacode" name="bankkatacode" v-model="bankkatacode" :class="{ 'jumin_check_y' : taxCheck.check_yn == 'Y' }"  style="ime-mode:inactive;" :readonly="taxCheck.check_yn == 'Y'">
                  </span>
                </li>
              </ul>  <!-- 테이블3 -->
              <dl>
                <dt>
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img1" width="100%" height="100%" style="vertical-align:middle;" :src="taxCheck.fileurl" alt="">
                      <div v-if="taxCheck.check_yn == 'N' && taxCheck.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="taxCheck.check_yn =='N'" type="file" name="TXFile" id="TXFile" @change="readURL">
                    <div v-if="taxCheck.check_yn == 'N' && taxCheck.memo == '' && taxCheck.fileurl != ''" class="res-depending"><span class="upload-state1">Proceeding</span></div>
                    <div v-else-if="taxCheck.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="taxCheck.check_yn == 'N' && taxCheck.memo != ''" class="res-memo">{{ taxCheck.memo }}</div>
                  </div>
                  <div class="item_content_notice" style="color:#444">
                    Please upload a scanned copy or file of the following:<br>
                    - The front page of BE Form with your information and tax code
                  </div>
                </dd>
              </dl>
            </li>

            <li class="upload-list"  v-if="isForigner == true">
              <!--START: 마이넘버 ------------------------------------->
              <h5>Visa</h5> <!-- 마이넘버 DD 앞면 -->
              <ul id="myo_form">

                <li>
                  <span class="item_title">Visa</span>
                  <span class="item_content" style="text-align: left;">
                    <input type="text" id="bankhiracode" name="bankhiracode" v-model="bankhiracode" :class="{ 'jumin_check_y' : visaCheck.check_yn == 'Y' }" maxlength="14" :readonly="visaCheck.check_yn == 'Y'" style="ime-mode:inactive;">
                  </span>
                </li>
              </ul>  <!-- 테이블4 -->

              <dl>
                <dt>
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img2" width="100%" height="100%" style="vertical-align:middle;" :src="visaCheck.fileurl" alt="">
                      <div v-if="visaCheck.check_yn == 'N' && visaCheck.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="visaCheck.check_yn == 'N'" type="file" name="VIFile" id="VIFile" @change="readURL">
                    <div v-if="visaCheck.check_yn == 'N' && visaCheck.memo == ''  && visaCheck.fileurl != ''" class="res-depending"><span class="upload-state1">Proceeding</span></div>
                    <div v-else-if="visaCheck.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="visaCheck.check_yn == 'N' && visaCheck.memo != ''" class="res-memo">{{ visaCheck.memo }}</div>
                  </div>
                  <div class="item_content_notice" style="color:#444">
                    Please upload a scanned copy of the following:<br>
                    - Scan copy of your Visa<br>
                    (Work Permit Level 1 or 2 / MM2H / PR(Permanent Residence)
                  </div>
                </dd>
              </dl>
            </li>

          </ul>
        </div>

        <div class="myo_condition_search" style="margin-top:20px;">
          <!--<button type="button" onclick="history.back()"  class="go_list_btn">{{ i18n.shop.board.Contents }}</button>-->
          <button type="button" onclick="location.href='/MyofficeMain'"  class="go_list_btn">{{ i18n.shop.board.Contents }}</button>
          <button type="submit" id="btn_submit" class="search_btn">{{ i18n.myoffice.save }}</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import vSelect from "vue-select";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";

export default {
  name: "MyforinerUpload",
  components : {
    vSelect,
    Loading,
    VueSimpleAlert
  },
  data: () => ({
    i18n: window.i18n,
    mem : "",
    accountCheck : {"check_yn" : "N","memo" : "","file_name" : ""},
    bankStdRow : "",
    juminCheck : {"check_yn" : "N","memo" : "","file_name" : ""},
    visaCheck : {"check_yn" : "N","memo" : "","file_name" : ""},
    taxCheck : {"check_yn" : "N","memo" : "","file_name" : ""},
    loading : false,
    bank_key_list : "",
    bank :"",
    account  : "",
    depositor : "",
    jumin_no1 : "",
    jumin_no2 : "",
    jumin_no3 : "",

    forigner_jumin_no : "",
    bankkatacode : "",
    bankhiracode : "",
    isForigner : false,
  }),
  beforeCreate() {
    axios.post(window.Laravel.back_url + '/api/getBankList',{ bz_cd : window.Laravel.bz_cd }).then(response => {
      this.bank_key_list = response.data;
    });

    axios.post(window.Laravel.back_url + '/api/getjoininfoDocu',{ bz_cd : window.Laravel.bz_cd }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
      this.accountCheck = response.data.doc_pp;
      this.juminCheck = response.data.doc_dd;
      this.visaCheck = response.data.doc_vi;
      this.taxCheck = response.data.doc_tx;

    });

    axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
      this.mem = response.data;
      this.isForigner = response.data.nt_cd == "999" ? true : false;
      if(this.isForigner == false){
        this.jumin_no1 = response.data.jumin1;
        this.jumin_no2 = response.data.jumin2;
        this.jumin_no3 = response.data.jumin3;
        this.pin_types = "NRIC";
      }else{
        this.forigner_jumin_no = response.data.jumin_no;
        this.pin_types = "Passport";
      }


      this.depositor = response.data.depositor != "null" ? response.data.depositor : "";
      this.account = response.data.account != "null" ? response.data.account : "";
      this.bank = response.data.bank_cd != "null" ? response.data.bank_cd : "";
      this.bankkatacode = response.data.bankkatacode != 'null' ? response.data.bankkatacode : '';
      this.bankhiracode = response.data.bankhiracode != 'null' ?response.data.bankhiracode : '' ;

    });

  },
  methods : {
    submitForm: function (event) {
      let formData = new FormData();
      formData.append('bz_cd', window.Laravel.bz_cd);

      var PPFile = document.getElementById("PPFile");
      var DDFile = document.getElementById("DDFile");
      var TXFile = document.getElementById("TXFile");
      var VIFile = document.getElementById("VIFile");

      if(this.accountCheck.check_yn == "N" && this.accountCheck.memo != ""){
        formData.append('bank', this.bank);
        formData.append('depositor', this.depositor);
        formData.append('account', this.account);
      }

      if(this.juminCheck.check_yn == "N" && this.juminCheck.memo != ""){
        if(this.isForigner == false){
          formData.append('jumin_no', this.jumin_no1+"-"+this.jumin_no2+"-"+this.jumin_no3);

        }else{
          formData.append('jumin_no', this.forigner_jumin_no);
        }

      }
      if(this.isForigner == true){
        if(this.taxCheck.check_yn == "N" ){
          formData.append('bankkatacode', this.bankkatacode);
        }
        if(this.visaCheck.check_yn == "N" ){
          formData.append('bankhiracode', this.bankhiracode);
        }
      }

      if(PPFile.files[0] !== undefined){
        formData.append('PPFile',PPFile.files[0] );
      }
      if( DDFile.files[0] !== undefined){
        formData.append('DDFile',DDFile.files[0] );
      }
      if(this.isForigner == true){

        if( TXFile.files[0] !== undefined){
          formData.append('TXFile',TXFile.files[0] );
        }

        if( VIFile.files[0] !== undefined){
          formData.append('VIFile',VIFile.files[0] );
        }
      }

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + window.Laravel.access_token
        }
      }

      this.loading = true;
      axios.post(window.Laravel.back_url+'/api/forienUpload',
        formData
        , config).then(response => {
        VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
          if(result){
            location.replace("/MyforinerUpload");
          }
        });
        this.loading = false;
      });

    },
    readURL: function (event) {
      var file = event.target.files[0];
      var reader = new FileReader();

      reader.onload = (e) => {
        if(event.target.id == "DDFile") this.juminCheck.fileurl = e.target.result;
        if(event.target.id == "PPFile") this.accountCheck.fileurl = e.target.result;
        if(event.target.id == "TXFile") this.taxCheck.fileurl = e.target.result;
        if(event.target.id == "VIFile") this.visaCheck.fileurl = e.target.result;
      }
      reader.readAsDataURL(file);
    }
  }
}
</script>

<style >
.jumin_check_y { pointer-events:none;background-color:#BDBDBD }
.upload-box{display: flex; width:100%; justify-content: space-evenly;  -webkit-justify-content: space-evenly; flex-wrap: wrap;}
.upload-box .upload-list{font-size:1em; padding:30px 15px; word-break: break-word; width:calc(95% / 2); margin:10px; border-radius: 10px; min-height: 150px; box-shadow: 0px 0px 2px 2px #e7e7e7; /*border:1px solid #ccc;*/ }
.upload-box .upload-list h5{font-size:1.3em; font-weight:500; margin-top:10px; margin-bottom:20px; text-align: center;}
.upload-box .upload-list table{border:1px solid #eee; vertical-align: middle; width:100%;}
.upload-box .upload-list table tr{border-bottom:1px solid #fff;}
.upload-box .upload-list table tr td{padding: 10px; border-bottom:1px solid #ddd; word-break: break-all;}
.upload-box .upload-list table tr td:nth-child(1){width:30%; text-align: center; border-right:1px solid #ddd; background:#f9f9f9; word-break: keep-all;}
.upload-box .upload-list table input{width:100%;}
.upload-box .upload-list input[type=text], .upload-box .upload-list input[type=password]{height: 35px; border: 1px solid #BBB; width: 100%; font-size: 15px; }
.upload-box .upload-list dl{margin-top:20px;}
.upload-box .upload-list dl dt .fix_box_inner:before{content: "Please upload the file"; display: inline-block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%; text-align: center; color: #999;}
.res-depending{text-align: center; margin:15px 0;}
.res-admit{text-align: center; margin:15px 0;}
.res-memo{text-align: center; margin:15px 0; color:#ff5722;} /*보류 박스*/
.res-memo2{position:absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255,0.7);} /*보류 박스*/
.upload-state1{border:3px double red; padding:10px 15px; display: inline-block; color:red;} /*대기*/
.upload-state2{border:3px double blue; padding:10px 15px; display: inline-block; color:blue;} /*승인*/
.upload-state3{text-align: center; color: #ff5722; position: absolute; top: 50%; left: 50%; transform:translate(-50%, -50%) rotate(348deg); border: 7px double #ff5722; padding: 5px 8px; font-size:35px;} /*보류*/
.upload_btn_box{margin-top:10px;}

@media all and (max-width:1023px){
  .upload-box{display: flex; width:100%;}
  .upload-box .upload-list{width:100%; margin:0px; margin-bottom:20px;}
  .upload-state3{font-size:20px; border: 3px double #ff5722;} /*보류*/
}
</style>
