<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>

  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-4 sm:px-6 lg:max-w-7xl lg:px-8 bg-white">
        <MypageTop :laravel="laravel" :main="1"></MypageTop>
        <div class="w-full pt-10 lg:flex lg:justify-start">
          <div class="hidden lg:block xl:block">
            <SideMenu></SideMenu>
          </div>
          <div class="w-full">
            <div class="max-w-8xl mx-auto px-4 sm:py-6 sm:pl-6 lg:max-w-7xl lg:pl-8 lg:pr-0 lg:py-8">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start space-y-2 flex-col">
                  <div class="flex justify-between">
                    <h1 class="text-lg dark:text-white lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800 mb-2">View Order/Delivery</h1>
                    <a href="/Mypage/Order">
                      <span class="text-sm leading-4 mt-4">See All
                        <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-4 w-4 mt-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <div class="w-full border-2 border-black rounded bg-white">
                    <div class="w-full py-1 flex justify-between divide-x flex-col lg:flex-row">
                      <div class="px-2 flex justify-between w-full lg:w-1/4">
                        <span class="text-sm leading-8">Waiting for payment</span>
                        <span class="text-xl leading-8" :class="this.cnt_1 == 0 ? 'text-gray-300' : ''">{{ this.cnt_1 }}</span>
                      </div>
                      <div class="px-2 flex justify-between w-full lg:w-1/4">
                        <span class="text-sm leading-8">{{ i18n.myoffice.paymentComplete }}</span>
                        <span class="text-xl leading-8" :class="this.cnt_2 == 0 ? 'text-gray-300' : ''">{{ this.cnt_2 }}</span>
                      </div>
                      <div class="px-2 flex justify-between w-full lg:w-1/4">
                        <span class="text-sm leading-8">Currently In Transit</span>
                        <span class="text-xl leading-8" :class="this.cnt_3 == 0 ? 'text-gray-300' : ''">{{ this.cnt_3 }}</span>
                      </div>
                      <div class="px-2 flex justify-between w-full lg:w-1/4">
                        <span class="text-sm leading-8">{{ i18n.shop.etc.Delivered }}</span>
                        <span class="text-xl leading-8" :class="this.cnt_4 == 0 ? 'text-gray-300' : ''">{{ this.cnt_4 }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full pb-10">
                <div class="flex justify-start item-start space-y-2 flex-col">
                  <div class="flex justify-between">
                    <h1 class="text-lg dark:text-white lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800  mb-2 lg:mb-6">{{ i18n.shop.mypage.Recentlyviewedproducts }}</h1>
                    <a href="/product/list/all">
                      <span class="text-sm leading-4 mt-4">{{ i18n.shop.board.Seeall }}
                          <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-4 w-4 mt-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                          </svg>
                      </span>
                    </a>
                  </div>
                  <div>
                    <span class="text-sm" v-html="no_search_messsage"></span>
                    <div v-if="products.length" class="mt-6 grid grid-cols-2 gap-x-1 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                      <div v-for="product in products" :key="product.id" class="group relative bg-white border-black m-1.5 rounded-lg p-2 border-slate-900">
                        <div class="w-full bg-gray-100 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none">
                          <img :src="product.imageSrc" :alt="product.imageAlt" class="w-full object-center object-cover max-w-none	" />
                          <div class="sold-out-box2" v-if="product.status == 2">
                            <img src="/images/badge/us/pc_temp_sold_out.png" alt="sold out" />
                          </div>
                          <div class="sold-out-box2" v-if="product.status == 3">
                            <img src="/images/badge/us/pc_sold_out.png" alt="sold out" />
                          </div>
                          <div class="coming_box" v-if="product.status == 4">
                            <img src="/images/badge/us/pc_coming_soon.png" alt="sold out" />
                          </div>
                          <div class="badge_box" v-if="product.badge != ''">
                            <img :src="product.badge" alt="sold out" />
                          </div>
                        </div>

                        <div class="mt-4 justify-between ">
                          <div class="h-12 lg:h-13 md:h-13 sm:h-12 ">
                            <h3 class="text-gray-700 mb-2.5 text-sm lg:text-base sm:text-sm text-ellipsis overflow-hidden line-clamp-2 lg:h-13 md:h-13 h-11 font-light">
                              <a :href="product.href">
                                <span aria-hidden="true" class="absolute inset-0" style="bottom: 100px;" />
                                {{ product.name }}
                              </a>
                            </h3>
                          </div>
                          <div v-if=" isLoggedIn " class="w-full mb-5 font-semibold">
                            <p class="font-medium text-gray-900 lg:text-lg text-md">{{ product.price }}</p>
                            <p class="mt-1 text-gray-500 lg:text-lg text-cyan-400 text-md">{{ product.bv }}</p>
                          </div>
                        </div>
                        <div v-if=" isLoggedIn && main != true" class="w-full mb-5 font-semibold">
                          <div class="inline-flex items-center font-semibold font-heading text-gray-500 border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md">
                            <button @click="decrement(product)" class="py-2 hover:text-gray-700 px-1 lg:px-3 md:px-2">
                              <svg class="text-blue-400" width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.35"><rect x="12" width="2" height="12" transform="rotate(90 12 0)" fill="currentColor"></rect></g></svg>
                            </button>
                            <input type="number" name="qty" v-model="product.qty" @input="changeQty(product)" class="w-8 m-0 px-0 py-2 text-center border-0 focus:ring-transparent focus:outline-none rounded-md lg:px-2 lg:py-2 lg:w-12" id="custom-input-number">
                            <button @click="increment(product)" class="py-2 hover:text-gray-700 px-1 lg:px-3 md:px-2">
                              <svg class="text-blue-400" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.35"><rect x="5" width="2" height="12" fill="currentColor"></rect><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="currentColor"></rect></g></svg>
                            </button>
                          </div>
                          <div v-if="product.status == 1 || product.status == 2"
                               class="inline-flex items-end align-top pl-1.5 float-right border-solid border border-gray-300 rounded-md flex items-center justify-between bg-white pl-0 w-10 lg:w-12"
                               style="height: 42px;">
                            <a class="block text-center py-2 uppercase transition duration-200 flex w-full justify-evenly p-0 cursor-pointer"  v-on:click.prevent="addCart(product)">
                              <ShoppingCartIcon class="h-6 sm:h-8 w-5 lg:w-6 text-zinc-600" aria-hidden="true" />
                            </a>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from './MypageTop.vue'
import SideMenu from './SideMenu.vue'
import Loading from 'vue3-loading-overlay';



export default {
  name: "mypage",
  components : {
    VueSimpleAlert,
      ShoppingCartIcon,
      MypageTop,
      SideMenu,
      Loading,
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    limit: 4,
    products: [],
    no_search_messsage: '',
    isLoggedIn: false,
    max_qty : 99,
    loading : false,
    cnt_1 : 0,
    cnt_2 : 0,
    cnt_3 : 0,
    cnt_4 : 0,
      bv : 0
  }),
  created() {
    this.getPdtMember();
    this.getOrderStatusCnt();
  },
  mounted() {
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
    }
  },
  methods:{
    getBestList(){
      // 베스트
      axios.post(process.env.MIX_BACK_URL + '/api/product/best', {
        bz_cd: window.Laravel.bz_cd,
        limit: this.limit
      }).then(response => {
        this.no_search_messsage = "You haven't seen any recent products yet.<p>Check out the best products of GCOOP that other members frequently purchase.</p>";
        this.getProductList(response.data);
      });
    },
    getProductList($list) {
      if($list.length < 1){
        this.getBestList();
      }
      $list.forEach((value, index) => {
        numeral.defaultFormat('$0,0[.]00');
        // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        this.products.push({
          id: value.seq_pd,
          name: value.pdt_name,
          href: '/product/item/' + value.seq_pd,
          imageSrc: 'https://cdn.gcoop.me/public' + value.images[1]?.path + '/' + value.images[1]?.filename,
          imageAlt: value.cate_name + '>' + value.pdt_name,
          // price: numeral(value.prices.amt).format('$0,0.00'),
          price: 'RM ' + numeral(value.prices.amt).format('0,0.00'),
          bv: numeral(value.prices.pv).format('0,0.0') + ' BV',
          qty : 1,
          status : value.status,
          badge : value.badge != "" ? "https://cdn.gcoop.me/"+value.badges.image_url : "",
        });
      });
    },
    getPdtMember() {
      this.loading = true;
      axios.post(window.Laravel.back_url+'/api/getPdtMember', {
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        this.getProductList(response.data);
      });
    },
    list_url(link){
      location.href="/product/list/"+link;
    },
    addCart(product){
      axios.post(process.env.MIX_BACK_URL+'/api/add_cart',{
        item: product.id,
        qty: product.qty,
        client_ip: window.Laravel.clientIp,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          if(response.data.msg){
            if(response.data.result == 'Y') {

              VueSimpleAlert.alert("", "", "", { background:'transparent', timer:1000, imageHeight: 300, imageUrl: '/images/putin_cart_icon_us.png', showCancelButton:false,  showCloseButton: false, showConfirmButton : false}).then((result) => {
                if (result) {
                  //location.replace("/MyforinerUpload");
                }
              });
            }else{
              VueSimpleAlert.alert(response.data.msg, "", "", { }).then((result) => {
                if (result) {
                  //location.replace("/MyforinerUpload");
                }
              });

              //alert(response.data.msg);
            }
          }
          if(response.data.result == 'Y'){
            //window.location.reload()
          }
        });
    },
    decrement(product){
      product.qty = this.formatPrice(product.qty,0);

      if(product.qty > 1) {
        product.qty--;
      }
    },
    increment(product){
      product.qty = this.formatPrice(product.qty,0);
      if(product.qty < this.max_qty) {
        product.qty++;
      }
    },
    changeQty(product){
      product.qty = this.formatPrice(product.qty,0);
      if(product.qty < 1) {
        this.count = 1;
      }
      if(product.qty > this.max_qty) {
        product.qty = this.max_qty;
      }
    },
    formatPrice(value,digit) {
      let val = (value/1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    getOrderStatusCnt(){
      this.loading = true;
      axios.post(window.Laravel.back_url+'/api/getOrderStatusCnt',{
        //bz_cd : window.Laravel.bz_cd
      },{headers: { Authorization: 'Bearer ' + this.laravel.access_token }}).then(response => {
        this.cnt_1 = response.data.cnt_1;
        this.cnt_2 = response.data.cnt_2;
        this.cnt_3 = response.data.cnt_3;
        this.cnt_4 = response.data.cnt_4;
        this.loading = false;
      });

    },
  }
}
</script>

<style scoped>

</style>
