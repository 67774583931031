<template>
    <section class="container max-w-8xl lg:max-w-7xl mx-auto py-6 px-4">
        <div class="mt-10">
            <ul class="flex border border-gray-300 shadow-lg shadow-gray-300/40">
                <li class="w-1/4 border-r border-gray-300  flex hover:duration-300 hover:bg-slate-400 " :class="{'bg-slate-400/20' : menucd == '019'}">
                    <a href="msTerms" class="flex items-center justify-center p-2.5 block text-center lg:text-base text-sm hover:text-white w-full">Terms &amp; Conditions</a></li>
                <li class="w-1/4 border-r border-gray-300 flex hover:duration-300 hover:bg-slate-400 " :class="{'bg-slate-400/20' : menucd == '013'}">
                    <a href="msPrivacyPolicy" class="flex items-center justify-center p-2.5 block text-center lg:text-base text-sm hover:text-white w-full">Privacy Policy</a></li>
                <li class="w-1/4 border-r border-gray-300 flex hover:duration-300 hover:bg-slate-400" :class="{'bg-slate-400/20' : menucd == '014'}">
                    <a href="msShippingPolicy" class="flex items-center justify-center p-2.5 block text-center lg:text-base text-sm hover:text-white w-full">Shipping Policy</a></li>
                <li class="w-1/4 border-r border-gray-300 flex hover:duration-300 hover:bg-slate-400" :class="{'bg-slate-400/20' : menucd == '018'}">
                    <a href="msReturnPolicy" class="flex items-center justify-center p-2.5 block text-center lg:text-base text-sm hover:text-white w-full">Return Policy</a></li>
                <li class="w-1/4 hover:duration-300 flex hover:bg-slate-400" :class="{'bg-slate-400/20' : menucd == 'academy'}">
                  <a href="msAcademyInfo" class="flex items-center justify-center p-2.5 block text-center lg:text-base text-sm hover:text-white w-full">Academy directory</a></li>
            </ul>
        </div>
        <!-- 말레이시아 약관 콘텐츠 -->
        <div class="mt-12 mb-10">
            <h2 v-if="menucd == '019'" class="mb-5 text-xl lg:text-2xl">Terms &amp; Conditions</h2>
            <h2 v-if="menucd == '013'" class="mb-5 text-xl lg:text-2xl">Privacy Policy</h2>
            <h2 v-if="menucd == '014'" class="mb-5 text-xl lg:text-2xl">Shipping Policy</h2>
            <h2 v-if="menucd == '018'" class="mb-5 text-xl lg:text-2xl">Return Policy</h2>
            <h2 v-if="menucd == 'academy'" class="mb-5 text-xl lg:text-2xl">Academy directory</h2>

            <div class="break-words text-sm lg:text-base" v-html="data">

            </div>

            <div v-if="this.academy_list">
              <div v-for="(Value, index) in this.academy_list" class="my-3">
                <div class="font-semibold text-base">{{ Value.cnt_name }}</div>
                <div class="font-light text-sm">Name : {{ Value.mng_name }}</div>
                <div class="font-light text-sm">Mobile : {{ Value.mng_mobile }}</div>
                <div class="font-light text-sm">Address : {{ Value.addr2 }} {{ Value.addr1 }}</div>
              </div>
            </div>
        </div>
    </section>
</template>

<script>
import numeral from "numeral";

export default {
    name: "Term",
    props : ["menucd"],
    data: () => ({
        data: "",
        academy_list : {}
    }),
    created() {
      if(this.menucd == 'academy'){
        axios.post(window.Laravel.back_url+'/api/getAcademy', {
          bz_cd: window.Laravel.bz_cd,
        }).then(response => {
          this.academy_list = response.data;
        });
      }else{
        axios.post(window.Laravel.back_url+'/api/getTerm', {
          menucd: this.menucd,
          bz_cd: window.Laravel.bz_cd,
        }).then(response => {
          this.data = response.data;
        });
      }
    },
}
</script>

<style scoped>

</style>
